import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TableContainer,
  Box,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  fetchHourlyRates,
  deleteHourlyRate,
  addHourlyRate,
  updateHourlyRate,
} from "./PayrollAPI";

const MySwal = withReactContent(Swal);

const HourlyRate = () => {
  const getInstituteCode = () => sessionStorage.getItem("institutecode");
  const [hourlyRates, setHourlyRates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedHourlyRate, setSelectedHourlyRate] = useState(null);
  const [hourlyRateData, setHourlyRateData] = useState({ hourlyRate: "" });

  useEffect(() => {
    loadHourlyRates();
  }, []);

  const loadHourlyRates = async () => {
    try {
      const data = await fetchHourlyRates(getInstituteCode());
      setHourlyRates(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenAddDialog = () => {
    setHourlyRateData({ hourlyRate: "" });
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => setOpenAddDialog(false);

  // const handleOpenEditDialog = (hourlyRate) => {
  //   setSelectedHourlyRate(hourlyRate);
  //   setOpenEditDialog(true);
  //   setHourlyRateData(hourlyRate);
  // };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedHourlyRate(null);
  };

  const handleDeleteHourlyRate = (hourlyRate) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `Do you want to delete this hourly rate: ${hourlyRate.hourlyRate}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteHourlyRate(hourlyRate.id)
          .then(() => {
            MySwal.fire(
              "Deleted!",
              "The hourly rate has been deleted.",
              "success"
            );
            loadHourlyRates();
          })
          .catch(() => {
            MySwal.fire(
              "Error!",
              "There was an error deleting the hourly rate.",
              "error"
            );
          });
      }
    });
  };

  const handleAddHourlyRate = async (e) => {
    e.preventDefault();
    if (!hourlyRateData.hourlyRate) {
      MySwal.fire("Error!", "Please fill out the necessary fields.", "error");
      return;
    }

    try {
      await addHourlyRate(hourlyRateData, getInstituteCode());
      MySwal.fire("Success!", "The hourly rate has been added.", "success");
      handleCloseAddDialog();
      loadHourlyRates();
    } catch (error) {
      MySwal.fire(
        "Error!",
        "There was an error adding the hourly rate.",
        "error"
      );
    }
  };

  // const handleUpdateHourlyRate = async (e) => {
  //   e.preventDefault();
  //   if (!hourlyRateData.hourlyRate) {
  //     MySwal.fire("Error!", "Please fill out the necessary fields.", "error");
  //     return;
  //   }

  //   try {
  //     await updateHourlyRate(selectedHourlyRate.id, hourlyRateData);
  //     MySwal.fire("Success!", "The hourly rate has been updated.", "success");
  //     handleCloseEditDialog();
  //     loadHourlyRates();
  //   } catch (error) {
  //     MySwal.fire(
  //       "Error!",
  //       "There was an error updating the hourly rate.",
  //       "error"
  //     );
  //   }
  // };

  const filteredHourlyRates = hourlyRates.filter((rate) =>
    rate.hourlyRate.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: 3 }}
        className="textField-root"
      >
        <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
          Total Rates: {filteredHourlyRates.length}
        </Typography>
        <Grid item xs={12} sm={2.5}>
          <TextField
            label="Search Rate"
            variant="outlined"
            fullWidth
            placeholder="Search Hourly Rate"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
            sx={{ marginTop: 1 }}
          >
            Add Hourly Rate
          </Button>
        </Grid>
      </Grid>
      <Box mt={2}>
        <TableContainer>
          <Table className="table-root">
            <TableHead>
              <TableRow>
                <TableCell>Rate ID</TableCell>
                <TableCell>Hourly Rate</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredHourlyRates.map((hourlyRate) => (
                <TableRow key={hourlyRate.id}>
                  <TableCell>{hourlyRate.id}</TableCell>
                  <TableCell>{hourlyRate.hourlyRate}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDeleteHourlyRate(hourlyRate)}
                      size="small"
                      sx={{ marginLeft: 1 }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add Hourly Rate</DialogTitle>
        <DialogContent>
          <TextField
            className="textField-root"
            autoFocus
            margin="dense"
            label="Hourly Rate"
            fullWidth
            variant="outlined"
            value={hourlyRateData.hourlyRate}
            onChange={(e) => setHourlyRateData({ hourlyRate: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddHourlyRate} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HourlyRate;
