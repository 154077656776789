import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  Dialog,
  Snackbar,
  Slide,
  IconButton,
  TextField,
  FormControl,
  MenuItem,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import Axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { Delete, Edit } from "@mui/icons-material";
import AddMonthlySalary from "./AddMonthlySalary";
import axios from "axios";
import PrintIcon from "@mui/icons-material/Print";
import html2pdf from "html2pdf.js"; // Importing html2pdf.js
import InstallmentById from "./InstallmentById"; // Import the new component
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Slide Transition Component
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// Month mapping array
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function MonthlySalary() {
  const getInstituteCode = () => sessionStorage.getItem("institutecode");
  // Add state to manage installment dialog visibility and selected salary ID
  const [openInstallmentDialog, setOpenInstallmentDialog] = useState(false);
  const [selectedInstallmentSalaryId, setSelectedInstallmentSalaryId] =
    useState(null);
  const [salaries, setSalaries] = useState([]);
  const [filteredSalaries, setFilteredSalaries] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [currentSalary, setCurrentSalary] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(""); // New state for payment method
  const [selectedSalary, setSelectedSalary] = useState(false);
  const [openReceipt, setOpenReceipt] = useState(false);

  const [selectedYear, setSelectedYear] = useState(""); // New state for selected year
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 15 }, (_, i) => currentYear - 7 + i); // Generate past 7 years and next 7 years

  const [employeeDetails, setEmployeeDetails] = useState(null);
  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        if (!getInstituteCode()) {
          console.error("No institutecode found in sessionStorage");
          return;
        }

        const response = await axios.get(
          `https://pjsofttech.in:20443/findInstitutesby/Institutecode?institutecode=${getInstituteCode()}`
        );
        setEmployeeDetails(response.data);
      } catch (error) {
        console.error("Error fetching employee details:", error);
      }
    };

    fetchEmployeeDetails();
  }, [getInstituteCode()]);

  useEffect(() => {
    fetchAllSalaries();
    fetchTeachers();
  }, []);

  const fetchAllSalaries = async () => {
    try {
      const response = await Axios.get(
        ` https://pjsofttech.in:19443/getAllMonthlySalaries?institutecode=${getInstituteCode()}`
      );
      setSalaries(response.data);
      setFilteredSalaries(response.data); // Initialize filtered salaries
    } catch (error) {
      console.error("Error fetching salaries:", error);
    }
  };

  const fetchTeachers = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:19443/getAllTeachers?institutecode=${getInstituteCode()}`
      );
      setTeachers(response.data);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  useEffect(() => {
    filterSalaries();
  }, [selectedTeacher, selectedMonth, selectedPaymentMethod, selectedYear]); // Add selectedYear to dependencies// Add selectedPaymentMethod to dependencies

  const filterSalaries = () => {
    let filtered = [...salaries];

    // Filter by selectedTeacher if selected
    if (selectedTeacher) {
      filtered = filtered.filter(
        (salary) => salary.teacherId === selectedTeacher
      );
      console.log(`Filtered by Teacher ID (${selectedTeacher}): `, filtered);
    }

    // Filter by month number based on selected month name
    if (selectedMonth) {
      const monthIndex = monthNames.indexOf(selectedMonth) + 1;
      filtered = filtered.filter(
        (salary) => Number(salary.month) === monthIndex
      );
      console.log(
        `Filtered by Month (${selectedMonth}, Index ${monthIndex}): `,
        filtered
      );
    }

    // Filter by payment method if selected
    if (selectedPaymentMethod) {
      filtered = filtered.filter(
        (salary) => salary.paymentMethod === selectedPaymentMethod
      );
      console.log(
        `Filtered by Payment Method (${selectedPaymentMethod}): `,
        filtered
      );
    }

    if (selectedYear) {
      filtered = filtered.filter(
        (salary) => salary.year === Number(selectedYear)
      );
      console.log(`Filtered by Year (${selectedYear}): `, filtered);
    }

    setFilteredSalaries(filtered);
  };

  const handleEditClick = (salary) => {
    if (salary.paymentMethod === "Installments") {
      alert("Edit option is available for particular Installment inside the Installment box");
      return; // Prevent further action
    }
    setEditMode(true);
    setCurrentSalary(salary);
    setOpenDialog(true);
  };

  const handleClickOpen = () => {
    setEditMode(false);
    setCurrentSalary(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteClick = async (salaryId) => {
    if (window.confirm("Are you sure you want to delete this salary?")) {
      try {
        await Axios.delete(
          ` https://pjsofttech.in:19443/deleteMonthlySalary/${salaryId}`
        );
        setSnackbarMessage(`Salary with ID: ${salaryId} deleted successfully.`);
        setOpenSnackbar(true);
        fetchAllSalaries(); // Refresh the salaries list after deletion
      } catch (error) {
        console.error("Error deleting salary:", error);
      }
    }
  };

  // const handleGenerate = (salaryData) => {
  //   setSelectedSalary(salaryData);
  //   setOpenReceipt(true);
  // };

  const handleGenerate = (salaryData) => {
    if (salaryData.paymentMethod === "Pending") {
      alert("Payment is Still Pending");
      setOpenReceipt(false); // Ensure receipt is not opened
      return; // Exit the function early
    }

    if (salaryData.paymentMethod === "Installments") {
      alert("Print option is available for particular installments");
      // Optionally, you can choose to not open the receipt here
      return;
    }

    setSelectedSalary(salaryData);
    setOpenReceipt(true);
  };

  const downloadReceipt = () => {
    const receiptElement = document.getElementById("receipt");

    // Ensure that images are fully loaded before creating the PDF
    html2pdf()
      .from(receiptElement)
      .set({
        margin: 0.2,
        filename: "receipt.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true, // Set this to true to get logs about image loading
          useCORS: true, // Enables cross-origin loading for images
        },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      })
      .save();
  };

  const numberToWords = (num) => {
    const belowTwenty = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const thousands = ["", "Thousand"];

    if (num < 20) return belowTwenty[num];
    if (num < 100)
      return (
        tens[Math.floor(num / 10)] +
        (num % 10 ? " " + belowTwenty[num % 10] : "")
      );
    if (num < 1000)
      return (
        belowTwenty[Math.floor(num / 100)] +
        " Hundred" +
        (num % 100 ? " " + numberToWords(num % 100) : "")
      );

    for (let i = 0; i < thousands.length; i++) {
      const divisor = Math.pow(1000, i);
      if (num < divisor * 1000) {
        return (
          numberToWords(Math.floor(num / divisor)) +
          " " +
          thousands[i] +
          (num % divisor ? " " + numberToWords(num % divisor) : "")
        );
      }
    }
    return "";
  };

  return (
    <div style={{ padding: "10px" }}>
      <Grid
        container
        spacing={3}
        style={{ marginBottom: "20px", alignItems: "center" }}
        className="textField-root"
      >
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <TextField
              select
              label="Select Teacher"
              value={selectedTeacher}
              onChange={(e) => setSelectedTeacher(e.target.value)}
            >
              <MenuItem value="">
                <em>All Teachers</em>
              </MenuItem>
              {teachers.map((teacher) => (
                <MenuItem key={teacher.id} value={teacher.id}>
                  {teacher.teacherName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <TextField
              select
              label="Select Year"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <MenuItem value="">
                <em>All Years</em>
              </MenuItem>
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <TextField
              select
              label="Select Month"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <MenuItem value="">
                <em>All Months</em>
              </MenuItem>
              {monthNames.map((month, index) => (
                <MenuItem key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <TextField
              select
              label="Select Payment Method"
              value={selectedPaymentMethod}
              onChange={(e) => setSelectedPaymentMethod(e.target.value)}
            >
              <MenuItem value="">
                <em>All Payment Methods</em>
              </MenuItem>
              {["Complete", "Installments", "Pending"].map((method) => (
                <MenuItem key={method} value={method}>
                  {method}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="h6" gutterBottom>
            Total Salaries -{" "}
            {filteredSalaries.length > 0
              ? filteredSalaries.length
              : salaries.length}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Add New
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table size="small">
          <TableHead
            style={{
              backgroundColor: "#f2f2f2",
              justifyContent: "center",
            }}
          >
            <TableRow>
              {[
                "ID",
                "Month",
                "Year",
                "Teacher",
                "Hours Worked",
                "Total Salary",
                "Paid Amount",
                "Pending Amount",
                "Payment Mode",
                "Payment Date",
                "Due Date",
                "Payment Method",
                "Actions",
              ].map((header) => (
                <TableCell
                  key={header}
                  style={{ fontWeight: "bold", textAlign: "center" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSalaries.length > 0 ? (
              filteredSalaries.map((salary) => (
                <TableRow key={salary.id} hover>
                  <TableCell align="center">{salary.invoiceNo}</TableCell>
                  <TableCell align="center">
                    {monthNames[salary.month - 1]}
                  </TableCell>
                  <TableCell align="center">{salary.year}</TableCell>
                  <TableCell align="center">{salary.teacherName}</TableCell>
                  <TableCell align="center">{salary.hoursWorked}</TableCell>
                  <TableCell align="center">₹{salary.monthSalary}</TableCell>

                  <TableCell align="center">
                    {salary.payingAmount ? ` ₹${salary.payingAmount}` : "NA"}
                  </TableCell>
                  <TableCell align="center">
                    {salary.pendingAmount ? `₹${salary.pendingAmount}` : "NA"}
                  </TableCell>
                  <TableCell align="center">{salary.paidBy || "NA"}</TableCell>

                  <TableCell align="center">
                    {salary.payingDate || "NA"}
                  </TableCell>
                  <TableCell align="center">{salary.duedate || "NA"}</TableCell>
                  <TableCell align="center">
                    <span
                      onClick={() => {
                        if (salary.paymentMethod === "Installments") {
                          setSelectedInstallmentSalaryId(salary.id);
                          setOpenInstallmentDialog(true);
                        }
                      }}
                      style={{
                        cursor:
                          salary.paymentMethod === "Installments"
                            ? "pointer"
                            : "default",
                        color:
                          salary.paymentMethod === "Pending"
                            ? "red"
                            : salary.paymentMethod === "Complete"
                            ? "green"
                            : salary.paymentMethod === "Partial"
                            ? "purple"
                            : salary.paymentMethod === "Installments"
                            ? "orange"
                            : "inherit",
                        border:
                          salary.paymentMethod === "Installments"
                            ? "1px solid orange"
                            : "none",
                        padding:
                          salary.paymentMethod === "Installments"
                            ? "4px 8px"
                            : "inherit",
                        borderRadius:
                          salary.paymentMethod === "Installments"
                            ? "4px"
                            : "inherit",
                        display: "inline-block",
                      }}
                    >
                      <strong>{salary.paymentMethod || "NA"}</strong>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <div style={{ display: "flex" }}>
                      <IconButton
                        onClick={() => handleEditClick(salary)}
                        color="success"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteClick(salary.id)}
                        color="error"
                      >
                        <Delete />
                      </IconButton>
                      <IconButton
                        onClick={() => handleGenerate(salary)}
                        color="inherit"
                      >
                        <PrintIcon />
                      </IconButton>
                      <IconButton color="success">
                        <WhatsAppIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}

            {/* Totals row */}
            {filteredSalaries.length > 0 && (
              <TableRow>
                <TableCell
                  colSpan={2}
                  align="right"
                  style={{ fontWeight: "bold" }}
                >
                  Totals
                </TableCell>
                <TableCell/>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  {/* Empty cell for formatting */}
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  {filteredSalaries.reduce(
                    (sum, salary) => sum + parseFloat(salary.hoursWorked || 0),
                    0
                  )}
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center", display:'flex' }}>
                  ₹{" "}
                  {filteredSalaries.reduce(
                    (sum, salary) => sum + parseFloat(salary.monthSalary || 0),
                    0
                  )}
                </TableCell>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* // Add the Installment dialog in the return statement */}
      <Dialog
        open={openInstallmentDialog}
        onClose={() => setOpenInstallmentDialog(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {selectedInstallmentSalaryId && (
            <InstallmentById
              salaryId={selectedInstallmentSalaryId}
              onClose={() => setOpenInstallmentDialog(false)}
            />
          )}
        </DialogContent>
      </Dialog>
      {/* Add/Edit Salary Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
        TransitionComponent={Transition}
        style={{
          borderRadius: "13px",
        }}
      >
        <AddMonthlySalary
          onClose={handleCloseDialog}
          editMode={editMode}
          currentSalary={currentSalary}
          fetchSalaries={fetchAllSalaries}
          setSnackbarMessage={setSnackbarMessage}
          setOpenSnackbar={setOpenSnackbar}
        />
      </Dialog>

      {/* print receipt  */}
      <Dialog
        open={openReceipt}
        onClose={() => setOpenReceipt(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{ p: 1 }}>
          {selectedSalary ? (

            <Box id="receipt" sx={{ p: 3 }}>
               {/* Heading */}
               <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mb: 1,
                    alignItems: "center",
                  }}
                >
                  {employeeDetails.instituteimage && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <img
                        src={employeeDetails.instituteimage}
                        alt="Institute Logo"
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          borderRadius: "50%",
                          padding: "1%",
                        }}
                      />
                    </Box>
                  )}
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "30px", color: "purple" }}
                  >
                    {employeeDetails.institutename || "Guest"}
                  </Typography>

                  
                </Box>

                <div style={{ display: "flex", justifyContent:'space-between' }}>
                  <div
                    style={{
                      alignItems: "left",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography fontWeight={"bold"}>Teacher</Typography>
                    {selectedSalary.teacherName && (
                      <Typography variant="body2">{selectedSalary.teacherName}</Typography>
                    )}
                    {selectedSalary.mobile && (
                      <Typography variant="body2">{selectedSalary.mobile}</Typography>
                    )}
                    {selectedSalary.email && (
                      <Typography variant="body2">{selectedSalary.email}</Typography>
                    )}
                  </div>

                  <div
                    style={{
                      alignItems: "right",
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography fontWeight={"bold"}>Organization</Typography>
                    <Typography variant="h6">
                      {employeeDetails.emailaddress && (
                        <Typography variant="body2">
                          {employeeDetails.emailaddress}
                        </Typography>
                      )}
                      {employeeDetails.address && (
                        <Typography variant="body2">
                          {employeeDetails.address}
                        </Typography>
                      )}
                      {employeeDetails.phonenumber && (
                        <Typography variant="body2">
                          {employeeDetails.phonenumber}
                        </Typography>
                      )}
                    </Typography>
                  </div>
                </div>


              <Typography
                mt={2}
                variant="body2"
                sx={{
                  borderTop: "8px solid purple",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#f3e5f5",
                }}
              >
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  {selectedSalary.billType} No - {selectedSalary.invoiceNo}
                </Typography>

                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  {selectedSalary.billType} for {selectedSalary.teacherName} of{" "}
                  {monthNames[selectedSalary.month - 1]} {selectedSalary.year}
                </Typography>

                {/* Enquiry Receipt centered */}
                <Typography
                  component="span"
                  sx={{ fontWeight: "bold", textAlign: "center" }}
                >
                  {selectedSalary.billType} Date - {selectedSalary.payingDate}
                </Typography>
              </Typography>

              {/* Table with Data */}
              <Table
                size="small"
                sx={{
                  marginTop: "10px",
                  textAlign: "center",
                  justifyContent: "space-between",
                }}
              >
                <TableBody
                  sx={{
                    borderTop: "3px solid purple",
                    borderBottom: "3px solid purple",
                    textAlign: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {/* {selectedSalary.teacherId && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Teacher Id:
                      </TableCell>
                      <TableCell>{selectedSalary.teacherId}</TableCell>
                    </TableRow>
                  )} */}
                  {selectedSalary.teacherName && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Teacher Name:
                      </TableCell>
                      <TableCell>{selectedSalary.teacherName}</TableCell>
                    </TableRow>
                  )}
                  {selectedSalary.hoursWorked && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Hours Worked:
                      </TableCell>
                      <TableCell>{selectedSalary.hoursWorked}</TableCell>
                    </TableRow>
                  )}
                  {selectedSalary.monthSalary && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Total Salary:
                      </TableCell>
                      <TableCell>₹ {selectedSalary.monthSalary}</TableCell>
                    </TableRow>
                  )}
                  {selectedSalary.paymentMethod && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Payment Method:
                      </TableCell>
                      <TableCell>{selectedSalary.paymentMethod}</TableCell>
                    </TableRow>
                  )}
                  {selectedSalary.payingAmount && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Paying Amt:
                      </TableCell>
                      <TableCell>₹ {selectedSalary.payingAmount}</TableCell>
                    </TableRow>
                  )}
                  {selectedSalary.payingDate && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Payment Date:
                      </TableCell>
                      <TableCell>{selectedSalary.payingDate}</TableCell>
                    </TableRow>
                  )}
                  {selectedSalary.paidBy && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Paid By:
                      </TableCell>
                      <TableCell>{selectedSalary.paidBy}</TableCell>
                    </TableRow>
                  )}
                  {selectedSalary.transactionId && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Transaction Id:
                      </TableCell>
                      <TableCell>{selectedSalary.transactionId}</TableCell>
                    </TableRow>
                  )}
                  {selectedSalary.remark && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Remark:</TableCell>
                      <TableCell>{selectedSalary.remark}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "3px solid purple",
                  padding: "10px",
                }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  Total Amount in Words:
                </Typography>
                <Typography style={{ fontWeight: "bold" }}>
                  Rupees {numberToWords(selectedSalary.monthSalary)} only
                </Typography>
              </div>

              <Typography
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "13px",
                  marginTop: "50px",
                  textAlign: "center",
                }}
              >
                ** This Receipt is System Generated **
              </Typography>
            </Box>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => downloadReceipt(selectedSalary)}
          >
            <FileDownloadIcon />
            Download PDF
          </Button>
          <Button onClick={() => setOpenReceipt(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
