import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteSentBys, getSentBys, addSentBy } from "./API/ShipmentAPI";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box
} from "@mui/material";
import Swal from 'sweetalert2'; 

export default function SentBy() {
  const { id } = useParams();
  const [sentBys, setSentBys] = useState([]);
  const [filteredSentBys, setFilteredSentBys] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [sentBy, setSentBy] = useState({ sentBy: "" , link:""});


  const institutecode = sessionStorage.getItem("institutecode");

  useEffect(() => {
    loadSentBys();
  }, [institutecode]);

  useEffect(() => {
    filterSentBys();
  }, [searchTerm, sentBys]);

  const loadSentBys = async () => {
    try {
      const data = await getSentBys();
      setSentBys(data);
      setFilteredSentBys(data);
    } catch (error) {
      console.error("Error fetching sent bys:", error);
    }
  };

  const filterSentBys = () => {
    const filtered = sentBys.filter(sentence => 
      sentence.sentBy.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSentBys(filtered);
  };

  const handleDeleteSentBy = (sentByObj) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete sent by: ${sentByObj.sentBy}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSentByHandler(sentByObj.id);
      }
    });
  };

  const deleteSentByHandler = async (sentById) => {
    try {
      await deleteSentBys(sentById);
      Swal.fire("Deleted!", "The Sent By has been deleted successfully.", "success");
      loadSentBys();
    } catch (error) {
      console.error("Error deleting sent by:", error);
      Swal.fire("Error!", "There was an error deleting the sent by.", "error");
    }
  };

  const handleOpenAddDialog = () => {
    setSentBy({ sentBy: "" , link:""});
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const onAddSentBySubmit = async (e) => {
    e.preventDefault();
    console.log('Adding sent by:', sentBy); // Add this line
  
    if (!sentBy.sentBy) {
      Swal.fire("Error!", "Please fill out the necessary fields.", "error");
      return;
    }
  
    try {
      await addSentBy(sentBy.sentBy, sentBy.link);
      Swal.fire("Success!", "The Sent By has been added successfully.", "success");
      handleCloseAddDialog();
      loadSentBys();
    } catch (error) {
      console.error("There was an error adding the sent by!", error);
      Swal.fire("Error!", "There was an error adding the sent by.", "error");
    }
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        <Typography
          variant="h6" gutterBottom sx={{ marginTop: 3, whiteSpace: "nowrap" }}>
          Total Sent Bys: {filteredSentBys.length}
        </Typography>

        <Grid item xs={12} sm={1.6} className="textField-root">
          <TextField
            label="Search Sent By"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
            style={{ marginTop: 1 }}
          >
            Add Sent By
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <TableContainer>
          <Table className="table-root" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Sent By ID</TableCell>
                <TableCell>Sent By</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSentBys.map((sentByItem) => (
                <TableRow key={sentByItem.id}>
                  <TableCell>{sentByItem.id}</TableCell>
                  <TableCell>{sentByItem.sentBy}</TableCell>
                  <TableCell>{sentByItem.link || 'N/A'}</TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      onClick={() => handleDeleteSentBy(sentByItem)}
                      size="small"
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Add Sent By Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog} className="textField-root">
        <DialogTitle color={'black'}>Add Sent By</DialogTitle>
        <DialogContent>
          <form onSubmit={onAddSentBySubmit}>
            <TextField
              autoFocus
              margin="dense"
              label="Sent By"
              fullWidth
              variant="outlined"
              value={sentBy.sentBy}
              onChange={(e) =>
                setSentBy({ ...sentBy, sentBy: e.target.value })
              }
            />
            <TextField
              autoFocus
              margin="dense"
              label="Link"
              fullWidth
              variant="outlined"
              value={sentBy.link}
              onChange={(e) =>
                setSentBy({ ...sentBy, link: e.target.value })
              }
            />
            <DialogActions>
              <Button onClick={handleCloseAddDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
