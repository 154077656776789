import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  Typography,
  Box,
  Button, 
} from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import axios from "axios";

const AllDayHourlySalaryCountRevenue = () => {
  const getInstituteCode = () =>
    sessionStorage.getItem("institutecode") || "IST019";

  const [data, setData] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [view, setView] = useState("count");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const fetchData = async (selectedMonth, selectedYear) => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:19443/daily-stats?month=${selectedMonth}&year=${selectedYear}&institutecode=${getInstituteCode()}`
      );
      const responseData = response.data;

      const daysInMonth = getDaysInMonth(selectedMonth, selectedYear);
      const fullMonthData = Array.from({ length: daysInMonth }, (_, i) => {
        const day = (i + 1).toString().padStart(2, "0");
        const date = `${selectedYear}-${selectedMonth
          .toString()
          .padStart(2, "0")}-${day}`;
        const entry = responseData.find(
          (item) => item.date.split("T")[0] === date
        );
        return {
          date,
          count: entry?.count || 0,
          revenue: entry?.revenue || 0,
        };
      });

      setData(fullMonthData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(month, year);
  }, [month, year]);

  const years = Array.from(
    { length: 15 },
    (_, i) => new Date().getFullYear() - 7 + i
  );

  const chartData = data.map((item) => ({
    date: item.date.split("-")[2],
    [view]: item[view],
  }));

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
        className="textField-root"
      >
        <Typography variant="h7" fontWeight="bold">
          Daily {view === "revenue" ? "Revenue" : "Count"}
        </Typography>
        <div style={{ display: "flex", gap: "16px" }}>
          <FormControl variant="outlined" size="small">
            <TextField
              size="small"
              select
              label="Month"
              value={month}
              onChange={(e) => setMonth(Number(e.target.value))}
            >
              {months.map((monthName, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {monthName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <FormControl variant="outlined" size="small">
            <TextField
              size="small"
              select
              label="Year"
              value={year}
              onChange={(e) => setYear(Number(e.target.value))}
            >
              {years.map((yr) => (
                <MenuItem key={yr} value={yr}>
                  {yr}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setView(view === "count" ? "revenue" : "count")}
            style={{
              backgroundColor: "#3498DB",
              color: "#fff",
              textTransform: "none",
            }}
          >
            {view === "count" ? "Revenue" : "Count"}
          </Button>
        </div>
      </div>

      <div style={{ width: "90%" }}>
        <Box height={330}>
          <ResponsiveBar
            data={chartData}
            keys={[view]}
            indexBy="date"
            margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
            padding={0.1}
            colors={view === "count" ? ["#3498DB"] : ["#FF6F61"]}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0, 
              legend: "Day", 
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: view === "revenue" ? "Revenue" : "Count",
              legendPosition: "middle",
              legendOffset: -50,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            tooltip={({ id, value, indexValue }) => (
              <div
                style={{
                  padding: "5px",
                  background: "white",
                  border: "1px solid #ccc",
                }}
              >
                <strong>{indexValue}</strong>
                <br />
                {id}: {value}
              </div>
            )}
            animate={true}
            enableGridY={true}
          />
        </Box>
      </div>
    </div>
  );
};

export default AllDayHourlySalaryCountRevenue;
