// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   CircularProgress,
// } from "@mui/material";
// import Swal from "sweetalert2";
// import axios from "axios";

// const SlideShowCreator = () => {
//   const [openCreate, setOpenCreate] = useState(false);
//   const [openUpdate, setOpenUpdate] = useState(false);
//   const [openDelete, setOpenDelete] = useState(false);
//   const [slideBarColor, setSlideBarColor] = useState("");
//   const [selectedImageId, setSelectedImageId] = useState(null);
//   const [slideImages, setSlideImages] = useState(null);
//   const [slideBars, setSlideBars] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const institutecode = () => sessionStorage.getItem("institutecode");

//   // Fetch slide bars on component load
//   useEffect(() => {
//     fetchSlideBars();
//   }, []);

//   const fetchSlideBars = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get("https://pjsofttech.in:28443/getAllSlideBars", {
//         params: { institutecode: institutecode() },
//       });
//       setSlideBars(response.data ? [response.data] : []);
//     } catch (error) {
//       console.error("Failed to fetch slide bars", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleCreateSlideBar = async () => {
//     const formData = new FormData();
//     formData.append("slideBarColor", slideBarColor);
//     formData.append("institutecode", institutecode());
//     if (slideImages) {
//       Array.from(slideImages).forEach((file) => {
//         formData.append("slideImages", file);
//       });
//     }

//     try {
//       const response = await axios.post("https://pjsofttech.in:28443/createSlideBar", formData);
//       Swal.fire("Success", "SlideBar created successfully!", "success");
//       setOpenCreate(false);
//       fetchSlideBars();
//     } catch (error) {
//       Swal.fire("Error", "Failed to create SlideBar.", "error");
//     }
//   };

//   const handleUpdateSlideBar = async () => {
//     if (!selectedImageId) {
//       Swal.fire("Error", "Please select an image to update.", "error");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("imageUrlId", selectedImageId);
//     formData.append("institutecode", institutecode());
//     if (slideBarColor) formData.append("slideBarColor", slideBarColor);
//     if (slideImages) {
//       Array.from(slideImages).forEach((file) => {
//         formData.append("slideImages", file);
//       });
//     }

//     try {
//       const response = await axios.put("https://pjsofttech.in:28443/updateByImageUrlIdAndInstitutecode", formData);
//       Swal.fire("Success", "SlideBar updated successfully!", "success");
//       setOpenUpdate(false);
//       fetchSlideBars();
//     } catch (error) {
//       Swal.fire("Error", "Failed to update SlideBar.", "error");
//     }
//   };

//   const handleDeleteSlideBar = async () => {
//     if (!selectedImageId) {
//       Swal.fire("Error", "Please select an image to delete.", "error");
//       return;
//     }

//     try {
//       await axios.delete('https://pjsofttech.in:28443/deleteSlideBar', {
//         params: {
//           imageUrlId: selectedImageId,
//           institutecode: institutecode(),
//         },
//       });
//       Swal.fire("Success", "SlideBar deleted successfully!", "success");
//       setOpenDelete(false);
//       fetchSlideBars();
//     } catch (error) {
//       Swal.fire("Error", "Failed to delete SlideBar.", "error");
//     }
//   };

//   return (
//     <div style={{ padding: "20px" }}>
//       {/* Create SlideBar Button */}
//       <Button variant="contained" color="primary" onClick={() => setOpenCreate(true)}>
//         Create SlideBar
//       </Button>

//       {/* Update SlideBar Button */}
//       <Button
//         variant="contained"
//         color="secondary"
//         onClick={() => setOpenUpdate(true)}
//         style={{ marginLeft: "10px" }}
//       >
//         Update SlideBar
//       </Button>

//       {/* Delete SlideBar Button */}
//       <Button
//         variant="contained"
//         color="error"
//         onClick={() => setOpenDelete(true)}
//         style={{ marginLeft: "10px" }}
//       >
//         Delete SlideBar
//       </Button>

//       {/* Dialog for creating a SlideBar */}
//       <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
//         <DialogTitle color={'black'}>Create SlideBar</DialogTitle>
//         <DialogContent>
//           <input
//             type="color"
//             value={slideBarColor}
//             onChange={(e) => setSlideBarColor(e.target.value)}
//             style={{ margin: "20px 0", display: "block" }}
//           />
//           <input
//             type="file"
//             multiple
//             onChange={(e) => setSlideImages(e.target.files)}
//             style={{ margin: "20px 0" }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenCreate(false)} color="secondary">
//             Cancel
//           </Button>
//           <Button
//             onClick={handleCreateSlideBar}
//             color="primary"
//             variant="contained"
//           >
//             Submit
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* Dialog for updating a SlideBar */}
//       <Dialog open={openUpdate} onClose={() => setOpenUpdate(false)}>
//         <DialogTitle>Update SlideBar</DialogTitle>
//         <DialogContent>
//           <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
//             {slideBars.map((slideBar) =>
//               slideBar.slideImages.map((image, index) => (
//                 <img
//                   key={index}
//                   src={image}
//                   alt="Slide"
//                   onClick={() => setSelectedImageId(slideBar.imageUrlIds[index])}
//                   style={{
//                     width: "100px",
//                     height: "100px",
//                     objectFit: "cover",
//                     cursor: "pointer",
//                     border: selectedImageId === slideBar.imageUrlIds[index] ? "2px solid blue" : "none",
//                   }}
//                 />
//               ))
//             )}
//           </div>
//           <input
//             type="color"
//             value={slideBarColor}
//             onChange={(e) => setSlideBarColor(e.target.value)}
//             style={{ margin: "20px 0", display: "block" }}
//           />
//           <input
//             type="file"
//             multiple
//             onChange={(e) => setSlideImages(e.target.files)}
//             style={{ margin: "20px 0" }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenUpdate(false)} color="secondary">
//             Cancel
//           </Button>
//           <Button
//             onClick={handleUpdateSlideBar}
//             color="primary"
//             variant="contained"
//           >
//             Update
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* Dialog for deleting a SlideBar */}
//       <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
//         <DialogTitle>Delete SlideBar</DialogTitle>
//         <DialogContent>
//           <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
//             {slideBars.map((slideBar) =>
//               slideBar.slideImages.map((image, index) => (
//                 <img
//                   key={index}
//                   src={image}
//                   alt="Slide"
//                   onClick={() => setSelectedImageId(slideBar.imageUrlIds[index])}
//                   style={{
//                     width: "100px",
//                     height: "100px",
//                     objectFit: "cover",
//                     cursor: "pointer",
//                     border: selectedImageId === slideBar.imageUrlIds[index] ? "2px solid red" : "none",
//                   }}
//                 />
//               ))
//             )}
//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDelete(false)} color="secondary">
//             Cancel
//           </Button>
//           <Button
//             onClick={handleDeleteSlideBar}
//             color="error"
//             variant="contained"
//           >
//             Delete
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* Slideshow */}
//       {loading ? (
//         <CircularProgress style={{ display: "block", margin: "20px auto" }} />
//       ) : (
//         slideBars.map((slideBar) => (
//           <div
//             key={slideBar.id}
//             style={{
//               width: "100%",
//               height: "400px",
//               overflow: "hidden",
//               marginTop: "20px",
//             }}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 animation: "slide 6s infinite",
//                 width: `${slideBar.slideImages.length * 100}%`,
//               }}
//             >
//               {slideBar.slideImages.map((image, index) => (
//                 <img
//                   key={index}
//                   src={image}
//                   alt={`Slide ${index}`}
//                   style={{ width: "100%", height: "400px", objectFit: "cover" }}
//                 />
//               ))}
//             </div>
//             <style>{`
//               @keyframes slide {
//                 0% { transform: translateX(0); }
//                 33% { transform: translateX(-100%); }
//                 66% { transform: translateX(-200%); }
//               }
//             `}</style>
//           </div>
//         ))
//       )}
//     </div>
//   );
// };

// export default SlideShowCreator;


import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";

const SlideShowCreator = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [slideBarColor, setSlideBarColor] = useState("");
  const [slideImages, setSlideImages] = useState(null);
  const [slideBars, setSlideBars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0); // ✅ Track current slide
  const [slideKey, setSlideKey] = useState(0); // ✅ Track animation restart

  const institutecode = () => sessionStorage.getItem("institutecode");

  useEffect(() => {
    fetchSlideBars();
  }, []);

  useEffect(() => {
    // ✅ Auto slide effect: Change slide every 3 seconds
    if (slideBars.length > 0 && slideBars[0]?.slideImages.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex + 1 < slideBars[0].slideImages.length ? prevIndex + 1 : 0
        );
        setSlideKey((prevKey) => prevKey + 1); // ✅ Force reanimation
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [slideBars]);

  const fetchSlideBars = async () => {
    try {
      setLoading(true);
      const response = await axios.get("https://pjsofttech.in:28443/getAllSlideBars", {
        params: { institutecode: institutecode() },
      });
      setSlideBars(response.data ? [response.data] : []);
    } catch (error) {
      console.error("Failed to fetch slide bars", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateSlideBar = async () => {
    const formData = new FormData();
    formData.append("slideBarColor", slideBarColor);
    formData.append("institutecode", institutecode());
    if (slideImages) {
      Array.from(slideImages).forEach((file) => {
        formData.append("slideImages", file);
      });
    }

    try {
      await axios.post("https://pjsofttech.in:28443/createSlideBar", formData);
      Swal.fire("Success", "SlideBar created successfully!", "success");
      setOpenCreate(false);
      fetchSlideBars();
    } catch (error) {
      Swal.fire("Error", "Failed to create SlideBar.", "error");
    }
  };

  const handleUpdateSlideBar = async () => {
    if (!selectedImageId) {
      Swal.fire("Error", "Please select an image to update.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("imageUrlId", selectedImageId);
    formData.append("institutecode", institutecode());
    if (slideBarColor) formData.append("slideBarColor", slideBarColor);
    if (slideImages) {
      Array.from(slideImages).forEach((file) => {
        formData.append("slideImages", file);
      });
    }

    try {
      const response = await axios.put("https://pjsofttech.in:28443/updateByImageUrlIdAndInstitutecode", formData);
      Swal.fire("Success", "SlideBar updated successfully!", "success");
      setOpenUpdate(false);
      fetchSlideBars();
    } catch (error) {
      Swal.fire("Error", "Failed to update SlideBar.", "error");
    }
  };

  const handleDeleteSlideBar = async () => {
    if (!selectedImageId) {
      Swal.fire("Error", "Please select an image to delete.", "error");
      return;
    }

    try {
      await axios.delete('https://pjsofttech.in:28443/deleteSlideBar', {
        params: {
          imageUrlId: selectedImageId,
          institutecode: institutecode(),
        },
      });
      Swal.fire("Success", "SlideBar deleted successfully!", "success");
      setOpenDelete(false);
      fetchSlideBars();
    } catch (error) {
      Swal.fire("Error", "Failed to delete SlideBar.", "error");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Create SlideBar Button */}
      <Button variant="contained" color="primary" onClick={() => setOpenCreate(true)}>
        Create SlideBar
      </Button>

            {/* Update SlideBar Button */}
            <Button
        variant="contained"
        color="secondary"
        onClick={() => setOpenUpdate(true)}
        style={{ marginLeft: "10px" }}
      >
        Update SlideBar
      </Button>

      {/* Delete SlideBar Button */}
      <Button
        variant="contained"
        color="error"
        onClick={() => setOpenDelete(true)}
        style={{ marginLeft: "10px" }}
      >
        Delete SlideBar
      </Button>

      {/* Dialog for creating a SlideBar */}
      <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
        <DialogTitle color="black">Create SlideBar</DialogTitle>
        <DialogContent>
          <input
            type="color"
            value={slideBarColor}
            onChange={(e) => setSlideBarColor(e.target.value)}
            style={{ margin: "20px 0", display: "block" }}
          />
          <input
            type="file"
            multiple
            onChange={(e) => setSlideImages(e.target.files)}
            style={{ margin: "20px 0" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreate(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateSlideBar} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

            {/* Dialog for updating a SlideBar */}
            <Dialog open={openUpdate} onClose={() => setOpenUpdate(false)}>
        <DialogTitle>Update SlideBar</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {slideBars.map((slideBar) =>
              slideBar.slideImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt="Slide"
                  onClick={() => setSelectedImageId(slideBar.imageUrlIds[index])}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    cursor: "pointer",
                    border: selectedImageId === slideBar.imageUrlIds[index] ? "2px solid blue" : "none",
                  }}
                />
              ))
            )}
          </div>
          <input
            type="color"
            value={slideBarColor}
            onChange={(e) => setSlideBarColor(e.target.value)}
            style={{ margin: "20px 0", display: "block" }}
          />
          <input
            type="file"
            multiple
            onChange={(e) => setSlideImages(e.target.files)}
            style={{ margin: "20px 0" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUpdate(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdateSlideBar}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for deleting a SlideBar */}
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>Delete SlideBar</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {slideBars.map((slideBar) =>
              slideBar.slideImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt="Slide"
                  onClick={() => setSelectedImageId(slideBar.imageUrlIds[index])}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    cursor: "pointer",
                    border: selectedImageId === slideBar.imageUrlIds[index] ? "2px solid red" : "none",
                  }}
                />
              ))
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteSlideBar}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Slideshow with Slide Animation */}
      {loading ? (
        <CircularProgress style={{ display: "block", margin: "20px auto" }} />
      ) : slideBars.length > 0 && slideBars[0]?.slideImages.length > 0 ? (
        <div
          style={{
            width: "100%",
            height: "400px",
            overflow: "hidden",
            position: "relative",
            marginTop: "20px",
            backgroundColor: slideBars[0]?.slideBarColor || "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* ✅ Sliding Image Animation */}
          <div
            key={slideKey} // ✅ Forces animation restart when changing slides
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              animation: "slide-in 1s ease-in-out",
            }}
          >
            <img
              src={slideBars[0].slideImages[currentIndex]} // ✅ Only one image at a time
              alt={`Slide ${currentIndex}`}
              style={{
                width: "100%",
                height: "400px",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      ) : (
        <p style={{ textAlign: "center", marginTop: "20px" }}>No slides available</p>
      )}

      {/* ✅ CSS Slide Animation */}
      <style>{`
        @keyframes slide-in {
          0% { transform: translateX(100%); opacity: 0; }
          100% { transform: translateX(0); opacity: 1; }
        }
      `}</style>
    </div>
  );
};

export default SlideShowCreator;
