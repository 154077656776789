import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Grid,
  Paper,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import axios from "axios";
import ComparisonGraph from "../Addmission/AdmissionComparisonGraph";
import { styled } from "@mui/system";
import IncomeCombineDash from "./IncomeCombineDash";
import YearlyGraph from "../Enquiry/YearlyGraph";
import EmpDash from "../layouts/EmpDash";
import {
  getPresentEmployeeCount,
  getAbsentEmployeeCount,
  getTotalEmployeeCount,
} from "../Employee_System/Attendance/attendanceService";
import Birthdays from "./Birthdays";
import WhatsNew from "./WhatsNew";

export default function CombineDashboard() {
  //admission
  //const { user } = useContext(UserContext);
  const [data, setData] = useState({
    todayCount: 0,
    totalCount: 0,
    todayRevenue: 0,
    totalRevenue: 0,
  });
  const currentYear = new Date().getFullYear();
  const [chartData, setChartData] = useState([["Year", "Admissions"]]);
  const [loading, setLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [todaysBirthdays, setTodaysBirthdays] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const institutecode = sessionStorage.getItem("institutecode") || "";
  const [systemValues, setSystemValues] = useState(null);
  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);

  useEffect(() => {
    fetchEmployeeCounts();
  }, []);

  const fetchEmployeeCounts = async () => {
    try {
      const presentCount = await getPresentEmployeeCount();
      const absentCount = await getAbsentEmployeeCount();
      const totalEmployeeCount = await getTotalEmployeeCount();
      setTotalEmployeeCount(totalEmployeeCount);
      setPresentCount(presentCount);
      setAbsentCount(absentCount);
    } catch (error) {
      console.error("Error fetching employee counts:", error);
    }
  };
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get(
          `https://pjsofttech.in:10443/getNonDeleted?institutecode=${institutecode}`
        );
        const employees = response.data;

        const today = new Date();
        const currentDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );
        const oneWeekAhead = new Date();
        oneWeekAhead.setDate(today.getDate() + 6);

        const upcomingBirthdays = employees
          .map((employee) => {
            const dob = new Date(employee.dob);
            const nextBirthday = new Date(
              today.getFullYear(),
              dob.getMonth(),
              dob.getDate()
            );
            if (nextBirthday < currentDate) {
              nextBirthday.setFullYear(today.getFullYear() + 1);
            }
            return {
              fullName: employee.fullName,
              department: employee.department,
              dob: employee.dob,
              age: today.getFullYear() - dob.getFullYear(),
              nextBirthday,
            };
          })
          .filter(
            (employee) =>
              employee.nextBirthday >= currentDate &&
              employee.nextBirthday <= oneWeekAhead
          )
          .sort((a, b) => a.nextBirthday - b.nextBirthday);

        const todays = upcomingBirthdays.filter(
          (employee) =>
            employee.nextBirthday.getTime() === currentDate.getTime()
        );
        const upcoming = upcomingBirthdays.filter(
          (employee) =>
            employee.nextBirthday.getTime() !== currentDate.getTime()
        );

        setTodaysBirthdays(todays);
        setBirthdays(upcoming);
      } catch (error) {
        console.error("Error fetching employee data", error);
      }
    };

    fetchEmployeeData();
  }, [institutecode]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          { data: todayCount },
          { data: totalCount },
          { data: todayRevenue },
          { data: totalRevenue },
        ] = await Promise.all([
          axios.get(
            `https://pjsofttech.in:13443/getAdmissionsByTodayCount?institutecode=${institutecode}`
          ),
          axios.get(
            `https://pjsofttech.in:13443/TotalAdmission?institutecode=${institutecode}`
          ),
          axios.get(
            `https://pjsofttech.in:13443/AdmissionInToDaysRevenue?institutecode=${institutecode}`
          ),
          axios.get(
            `https://pjsofttech.in:13443/TotalAdmissionRevenue?institutecode=${institutecode}`
          ),
        ]);

        setData({
          todayCount,
          totalCount,
          todayRevenue,
          totalRevenue,
        });
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchYearlyData = async () => {
      setLoading(true);
      try {
        const institutecode = sessionStorage.getItem("institutecode"); // Get institute code from local storage
        const res = await axios.get(
          `https://pjsofttech.in:13443/count/yearly?institutecode=${institutecode}&year=${selectedYear}`
        );
        const data = res.data;
        const formattedData = [
          [selectedYear.toString(), data[selectedYear.toString()]],
        ];

        setChartData([["Year", "Admissions"], ...formattedData]);
      } catch (error) {
        console.error("Error fetching yearly data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchYearlyData();
  }, [selectedYear]);

  // enquiry dashboard
  const [totalApplications, setTotalApplications] = useState(0);
  const [selectedApi, setSelectedApi] = useState("All");
  const [numberFromApi, setNumberFromApi] = useState(0);

  const defaultYearlyData = [
    ["Year", "Admissions"],
    ["No Data", 0],
  ];

  // income dashboard

  // Generate the last 10 years for the year dropdown
  const years = Array.from(
    new Array(10),
    (val, index) => new Date().getFullYear() - index
  );

  // enquiry dashboard
  const fetchTotalEnquiries = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:14443/get/getALLEnquiryByInstitutecode?institutecode=${institutecode}`
      );
      setNumberFromApi(response.data.totalEnquiries);
    } catch (error) {
      console.error("Error fetching total enquiries:", error);
    }
  };

  useEffect(() => {
    if (selectedApi === "All") {
      fetchTotalEnquiries();
    } else {
      fetch(apiUrls[selectedApi])
        .then((response) => response.json())
        .then((data) => {
          console.log("Data from selected API:", data);
          setNumberFromApi(data);
        })
        .catch((error) => {
          console.error("Error fetching data from selected API:", error);
        });
    }
  }, [selectedApi]);

  const apiUrls = {
    "7Days": `https://pjsofttech.in:14443/numberOfEnquiry7days?institutecode=${institutecode}`,
    "30Days": `https://pjsofttech.in:14443/numberOfEnquiry30days?institutecode=${institutecode}`,
    "365Days": `https://pjsofttech.in:14443/numberOfEnquiry365days?institutecode=${institutecode}`,
  };

  useEffect(() => {
    const apiUrl = `https://pjsofttech.in:14443/getenquiryCount?institutecode=${institutecode}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log("Data from getAll:", data);
        setTotalApplications(data);
      })
      .catch((error) => {
        console.error("Error fetching data from getEnquiryCount:", error);
      });
  }, [institutecode]); // Added adminemail to the dependency array

  const [sevenDaysApplication, setSevenDaysApplication] = useState(0);
  useEffect(() => {
    const apiUrl = `https://pjsofttech.in:14443/numberOfEnquiry7days?institutecode=${institutecode}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log("Data from get7days:", data);
        setSevenDaysApplication(data);
      })
      .catch((error) => {
        console.error("Error fetching data from getAllFees:", error);
      });
  }, [institutecode]);

  const [thirtyDaysApplication, setThirtyDaysApplication] = useState(0);

  useEffect(() => {
    const apiUrl = `https://pjsofttech.in:14443/numberOfEnquiry30days?institutecode=${institutecode}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log("Data from get30days:", data);
        setThirtyDaysApplication(data);
      })
      .catch((error) => {
        console.error("Error fetching data from getAllFees:", error);
      });
  }, [institutecode]);

  const [threeSixtyFiveDaysApplication, setThreeSixtyFiveDaysApplication] =
    useState(0);

  useEffect(() => {
    const apiUrl = `https://pjsofttech.in:14443/numberOfEnquiry365days?institutecode=${institutecode}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log("Data from get365days:", data);
        setThreeSixtyFiveDaysApplication(data);
      })
      .catch((error) => {
        console.error("Error fetching data from getAllFees:", error);
      });
  }, [institutecode]);



  const [todaysApplications, setTodaysApplications] = useState(0);
  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        // Fetch today's enquiry count directly
        const todaysResponse = await axios.get(
          `https://pjsofttech.in:14443/getenquiryCount/today?institutecode=${institutecode}`
        );
        setTodaysApplications(todaysResponse.data);
        console.log("Data from todays:", todaysResponse.data);
        // Fetch all enquiries to calculate exam and source counts
        const allEnquiriesResponse = await axios.get(
          `https://pjsofttech.in:14443/get/getALLEnquiryByInstitutecode?institutecode=${institutecode}`
        );
        const allEnquiriesData = allEnquiriesResponse.data;
        setTotalApplications(allEnquiriesData.length); // Assuming you want the total count of all enquiries

        // Count exams

        // Count sources
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchEnquiries();
  }, [institutecode]);

  // useEffect(() => {
  //   const fetchSystemValues = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://pjsofttech.in:20443/getSystemValueByInstitutecode?institutecode=${institutecode}`
  //       );
  //       setSystemValues(response.data);
  //     } catch (error) {
  //       console.error("Error fetching system values", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchSystemValues();
  // }, [institutecode]);

  if (loading) {
    return <div>Loading...</div>;
  }

  // if (!systemValues) {
  //   return <div>Error fetching system values</div>;
  // }

  // const {
  //   feesmanagementsystem,
  //   enquirymanagementsystem,
  //   employeemanagementsystem,
  //   studentmanagementsystem,
  //   incomeandexpense,
  //   admissionmanagementsystem,
  // } = systemValues;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Birthdays />
        </Grid>
        <Grid item xs={12} md={6}>
          <WhatsNew />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                height: "3px",
                backgroundColor: "#0D47A1",
              }}
            />

            <Typography
              variant="h6"
              sx={{ margin: "0 10px", marginBottom: "10px" }}
            >
              <b>Attendance</b>
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                height: "3px",
                backgroundColor: "#0D47A1",
              }}
            />
          </Box>
          <Grid container spacing={2} justifyContent="space-between">
            {/* Total Employees Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  backgroundColor: "#F9E79F",
                  borderRadius: "10px",
                  fontWeight: "bold",
                }}
              >
                <CardContent>
                  <Typography variant="h7" component="div">
                    Total Employees
                  </Typography>
                  <Typography variant="h5" component="div">
                    {totalEmployeeCount}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Present Employees Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  backgroundColor: "#FF6F61",
                  borderRadius: "10px",
                  fontWeight: "bold",
                }}
              >
                <CardContent>
                  <Typography variant="h7" component="div">
                    Present Employees
                  </Typography>
                  <Typography variant="h5" component="div">
                    {presentCount}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Absent Employees Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  backgroundColor: "#3498DB",
                  borderRadius: "10px",
                  fontWeight: "bold",
                }}
              >
                <CardContent>
                  <Typography variant="h7" component="div">
                    Absent Employees
                  </Typography>
                  <Typography variant="h5" component="div">
                    {absentCount}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center">
        {/* Enquiry Dashboard */}
        {/* {enquirymanagementsystem && ( */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  height: "3px",
                  backgroundColor: "#0D47A1",
                }}
              />

              <Typography
                variant="h6"
                sx={{ margin: "0 10px", marginBottom: "10px" }}
              >
                <b>Enquiry</b>
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  height: "3px",
                  backgroundColor: "#0D47A1",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                padding: 2,
                border: "1px solid lightgray",
                borderRadius: 2,
              }}
            >
              <Grid container spacing={2} justifyContent="center" mb={4.5}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      backgroundColor: "#F9E79F",
                      padding: 2,
                      borderRadius: "10px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography variant="h7">Today's Enquiry</Typography>
                    <Typography variant="h5">{todaysApplications}</Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#F9E79F",
                      padding: 2,
                      borderRadius: "10px",
                      mt: 2,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography variant="h7">Last 7 Day's Enquiry</Typography>
                    <Typography variant="h5">{sevenDaysApplication}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      backgroundColor: "#3498DB",
                      padding: 2,
                      borderRadius: "10px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography variant="h7">Last 30 Day's Enquiry</Typography>
                    <Typography variant="h5">
                      {thirtyDaysApplication}
                    </Typography>
                  </Box>
                  <Paper
                    sx={{
                      backgroundColor: "#3498DB",
                      padding: 2,
                      borderRadius: "10px",
                      mt: 2,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography variant="h7">Total Enquiry</Typography>
                    <Typography variant="h5">{totalApplications}</Typography>
                  </Paper>
                </Grid>
              </Grid>

              <YearlyGraph />
            </Box>
          </Grid>
        {/* )} */}

        {/* Admission Dashboard */}
        {/* {admissionmanagementsystem && ( */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  height: "3px",
                  backgroundColor: "#0D47A1",
                }}
              />
              <Typography
                variant="h6"
                sx={{ margin: "0 10px", marginBottom: "10px" }}
              >
                <b>Admission</b>
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  height: "3px",
                  backgroundColor: "#0D47A1",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                padding: 2,
                border: "1px solid lightgray",
                borderRadius: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      backgroundColor: "#F9E79F",
                      padding: 2,
                      borderRadius: "10px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography variant="h7">Today's Admissions</Typography>
                    <Typography variant="h5">{data.todayCount}</Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#F9E79F",
                      padding: 2,
                      borderRadius: "10px",
                      mt: 2,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography variant="h7">Today's Revenue</Typography>
                    <Typography variant="h6">₹{data.todayRevenue}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      backgroundColor: "#3498DB",
                      padding: 2,
                      borderRadius: "10px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography variant="h7">Total Admissions</Typography>
                    <Typography variant="h5">{data.totalCount}</Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#3498DB",
                      padding: 2,
                      borderRadius: "10px",
                      mt: 2,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography variant="h7">Total Revenue</Typography>
                    <Typography variant="h6">₹{data.totalRevenue}</Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ marginTop: 5 }}>
                <ComparisonGraph />
              </Box>
            </Box>
          </Grid>
        {/* )} */}
      </Grid>
      {/* {employeemanagementsystem &&  */}
      <EmpDash />
      {/* } */}
      {/* {incomeandexpense && */}
       <IncomeCombineDash />
       {/* } */}
    </div>
  );
}
