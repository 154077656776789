import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, CircularProgress, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import axios from 'axios';

export default function OrdersCountByStatus() {
  const [statuses, setStatuses] = useState([]);
  const [orderCounts, setOrderCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const institutecode = sessionStorage.getItem('institutecode');

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axios.get(`https://pjsofttech.in:17443/getAllStatuses?institutecode=${institutecode}`);
        setStatuses(response.data.map(item => item.status));
      } catch (error) {
        console.error('Error fetching statuses:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatuses();
  }, [institutecode]);

  useEffect(() => {
    const fetchOrderCounts = async () => {
      if (statuses.length === 0) return;
      const counts = {};

      for (const status of statuses) {
        try {
          const response = await axios.get(`https://pjsofttech.in:17443/getOrdersStatusCount?status=${encodeURIComponent(status)}&institutecode=${institutecode}`);
          counts[status] = response.data;
        } catch (error) {
          console.error(`Error fetching count for status ${status}:`, error);
          counts[status] = 0;
        }
      }

      setOrderCounts(counts);
    };

    fetchOrderCounts();
  }, [statuses, institutecode]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div padding="0" style={{ padding: '16px', display: 'flex', justifyContent: "space-between" }}>
      {/* Render status cards without marquee effect */}
      {statuses.slice(0, 5).map((status, index) => (
        <div key={status + index} style={{ flexShrink: 0, marginRight: '16px' }}>
          <Card style={{ backgroundColor: '#FEF9C3', borderRadius: '10px', fontWeight: 'bold', width: '170px', height: '70px', color: 'brown' }}>
            <CardContent>
              <Typography component="div">{status}</Typography>
              <Typography variant="h7" color="text.secondary">
                {orderCounts[status] || 0}
              </Typography>
            </CardContent>
          </Card>
        </div>
      ))}
      
      {statuses.length > 5 && (
        <Button variant="outlined" onClick={handleDialogOpen}>
          Read More
        </Button>
      )}

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>All Order Statuses</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {statuses.map((status, index) => (
              <Card key={status + index} style={{ backgroundColor: '#FEF9C3', borderRadius: '10px', fontWeight: 'bold', width: '150px', height: '70px', color: 'brown' }}>
                <CardContent>
                  <Typography component="div">{status}</Typography>
                  <Typography variant="h7" color="text.secondary">
                    {orderCounts[status] || 0}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
