import React, { useState } from "react";
import { Box, Paper, Typography, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ClassIcon from "@mui/icons-material/Class";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import GroupsIcon from "@mui/icons-material/Groups";
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import AddField from "../Student/AddField";
import AddClass from "../Student/AddClass";
import Stream from "./Stream";
import Group from "./Group";
import ClassTeacher from "./ClassTeacher";
import StudentQR from "./StudentQR";

const menuItems = [
  { id: "AddField", label: "Add Field", icon: <AddCircleIcon /> },
  { id: "ClassTeacher", label: "Add Teacher", icon: <CatchingPokemonIcon />},
  { id: "AddClass", label: "Add Class", icon: <ClassIcon /> },
  { id: "Stream", label: "Add Stream", icon: <HistoryEduIcon /> },
  { id: "Group", label: "Add Group", icon: <GroupsIcon /> },
  { id: "StudentQR", label: "Student QR", icon:<QrCodeScannerIcon />},
];

const StudentSetting = () => {
  const [selectedComponent, setSelectedComponent] = useState("AddField");
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      {/* Sidebar */}
      <Paper
        elevation={3}
        sx={{
          width: isCollapsed ? "60px" : "220px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
           height: "280px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "30px",
          backgroundColor: "#2980B9",
          transition: "width 0.7s ease",
          position: "relative",
          overflow: "hidden",
        }}
        onMouseEnter={() => setIsCollapsed(false)}
        onMouseLeave={() => setIsCollapsed(true)}
      >
        {menuItems.map((item) => (
          <Tooltip
            key={item.id}
            title={isCollapsed ? item.label : ""}
            placement="right"
          >
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
                padding: "10px",
                color: selectedComponent === item.id ? "black" : "white",
                fontWeight: selectedComponent === item.id ? "bold" : "normal",
                borderRadius: "30px",
                backgroundColor: selectedComponent === item.id ? "white" : "",
                fontSize: "13px",
                transition: "all 0.3s ease",
                whiteSpace: "nowrap",
                boxShadow:
                  selectedComponent === item.id
                    ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onClick={() => setSelectedComponent(item.id)}
            >
              {item.icon}
              {!isCollapsed && item.label}
            </Typography>
          </Tooltip>
        ))}
      </Paper>

      {/* Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          padding: "20px",
          marginLeft: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        {selectedComponent === "AddField" && <AddField />}
        {selectedComponent === "AddClass" && <AddClass />}
        {selectedComponent === "Stream" && <Stream />}
        {selectedComponent === "Group" && <Group />}
        {selectedComponent === "ClassTeacher" && <ClassTeacher />}
        {selectedComponent === "StudentQR" && <StudentQR />}
      </Box>
    </Box>
  );
};

export default StudentSetting;
