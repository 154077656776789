import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography, Container, Card, CardContent, MenuItem, Snackbar, SnackbarContent } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';

const StandardForm = () => {
  const [formData, setFormData] = useState({
    tuitionFee: '',
    admissionFee: '',
    practicalFee: '',
    standard: '',
    medium: '',
    division: '',
    computerClassFee: '',
    examFees: '',
    uniformFee: '',
    transportBusFee: '',
    hostelFee: '',
    buildingFundFee: '',
    libraryFees: '',
    sportFees: '',
    gst: '',
    totalamount: ''
  });
  const [mediums, setMediums] = useState([]);
  const [standards, setStandards] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const institutecode = () => sessionStorage.getItem("institutecode");

  useEffect(() => {
    fetchMediums();
    fetchStandards();
    fetchDivisions();
  }, []);

  const fetchMediums = async () => {
    try {
      const response = await axios.get(`https://pjsofttech.in:9443/getall?institutecode=${institutecode()}`);
      setMediums(response.data); // Assuming response.data is an array of mediums
    } catch (error) {
      console.error('Error fetching mediums:', error);
    }
  };


  const fetchStandards = async () => {
    try {
      const response = await axios.get(`https://pjsofttech.in:9443/all?institutecode=${institutecode()}`);
      setStandards(response.data); // Assuming response.data is an array of standards
    } catch (error) {
      console.error('Error fetching standards:', error);
    }
  };

  const fetchDivisions = async () => {
    try {
      const response = await axios.get(`https://pjsofttech.in:9443/getallDivision?institutecode=${institutecode()}`);
      setDivisions(response.data); // Assuming response.data is an array of divisions
    } catch (error) {
      console.error('Error fetching divisions:', error);
    }
  };

  // const calculateTotalFees = (updatedData) => {
  //   return (
  //     parseFloat(updatedData.tuitionFee || 0) +
  //     parseFloat(updatedData.admissionFee || 0) +
  //     parseFloat(updatedData.practicalFee || 0) +
  //     parseFloat(updatedData.computerClassFee || 0) +
  //     parseFloat(updatedData.examFees || 0) +
  //     parseFloat(updatedData.uniformFee || 0) +
  //     parseFloat(updatedData.transportBusFee || 0) +
  //     parseFloat(updatedData.hostelFee || 0) +
  //     parseFloat(updatedData.buildingFundFee || 0) +
  //     parseFloat(updatedData.libraryFees || 0) +
  //     parseFloat(updatedData.sportFees || 0) +
  //     parseFloat(updatedData.gst || 0)
  //   ).toFixed(2);
  // };

  const calculateTotalFees = (updatedData) => {
    const subtotal = (
      parseFloat(updatedData.tuitionFee || 0) +
      parseFloat(updatedData.admissionFee || 0) +
      parseFloat(updatedData.practicalFee || 0) +
      parseFloat(updatedData.computerClassFee || 0) +
      parseFloat(updatedData.examFees || 0) +
      parseFloat(updatedData.uniformFee || 0) +
      parseFloat(updatedData.transportBusFee || 0) +
      parseFloat(updatedData.hostelFee || 0) +
      parseFloat(updatedData.buildingFundFee || 0) +
      parseFloat(updatedData.libraryFees || 0) +
      parseFloat(updatedData.sportFees || 0)
    );
  
    // Apply GST as a percentage of the subtotal
    const gstAmount = (subtotal * parseFloat(updatedData.gst || 0)) / 100;
    const totalAmount = subtotal + gstAmount;
  
    return totalAmount.toFixed(2);
  };
  

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === 'checkbox' ? checked : value
      };
      return {
        ...updatedData,
        totalamount: calculateTotalFees(updatedData)
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Retrieve the standardId from formData
      const standardId = formData.standard;
  
      // Make sure standardId is defined before making the request
      if (!standardId) {
        Swal.fire("Error", "Please select a standard", "error");
        return;
      }
  
      // Make the API call with the standardId
      const response = await axios.post(`https://pjsofttech.in:9443/addFeesbyStandardid/${standardId}?institutecode=${institutecode()}`, formData);
      console.log('Form submitted successfully:', response.data);
      Swal.fire("Success", "Form Submitted successfully", "success");
  
      // Optionally, reset the form
      setFormData({
        tuitionFee: '',
        admissionFee: '',
        practicalFee: '',
        standard: '',
        medium: '',
        division: '',
        computerClassFee: '',
        examFees: '',
        uniformFee: '',
        transportBusFee: '',
        hostelFee: '',
        buildingFundFee: '',
        libraryFees: '',
        sportFees: '',
        gst: '',
        totalamount: ''
      });
    } catch (error) {
      console.error('Error submitting the form:', error);
      Swal.fire("Error", "Error submitting the form", "error");
    }
  };
  

  return (
    <div>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} className="textField-root">
            <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  select
                  label="Standard"
                  name="standard"
                  value={formData.standard}
                  onChange={handleChange}
                  variant="outlined"
                  required
                >
                  {standards.map((standard) => (
                    <MenuItem key={standard.standardId} value={standard.id}>
                      {standard.standard}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  select
                  label="Medium"
                  name="medium"
                  value={formData.medium}
                  onChange={handleChange}
                  variant="outlined"
                  required
                >
                  {mediums.map((medium) => (
                    <MenuItem key={medium.id} value={medium.mediumname}>
                      {medium.mediumname}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  select
                  label="Division"
                  name="division"
                  value={formData.division}
                  onChange={handleChange}
                  variant="outlined"
                  required
                >
                  {divisions.map((division) => (
                    <MenuItem key={division.id} value={division.division}>
                      {division.division}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Tuition Fee"
                  name="tuitionFee"
                  type="number"
                  value={formData.tuitionFee}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Admission Fee"
                  name="admissionFee"
                  type="number"
                  value={formData.admissionFee}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Practical Fee"
                  name="practicalFee"
                  type="number"
                  value={formData.practicalFee}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Computer Class Fee"
                  name="computerClassFee"
                  type="number"
                  value={formData.computerClassFee}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Exam Fees"
                  name="examFees"
                  type="number"
                  value={formData.examFees}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Uniform Fee"
                  name="uniformFee"
                  type="number"
                  value={formData.uniformFee}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Transport Bus Fee"
                  name="transportBusFee"
                  type="number"
                  value={formData.transportBusFee}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Hostel Fee"
                  name="hostelFee"
                  type="number"
                  value={formData.hostelFee}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Building Fund Fee"
                  name="buildingFundFee"
                  type="number"
                  value={formData.buildingFundFee}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Library Fees"
                  name="libraryFees"
                  type="number"
                  value={formData.libraryFees}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Sport Fees"
                  name="sportFees"
                  type="number"
                  value={formData.sportFees}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="GST"
                  type="number"
                  name="gst"
                  value={formData.gst}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Total Fees Amount"
                  type="number"
                  name="totalamount"
                  value={formData.totalamount}
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default StandardForm;
