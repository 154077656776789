// import axios from "axios";
// import React, { useState, useEffect } from "react";
// import { Card, CardContent, Typography, Avatar,Grid } from "@mui/material";

// export default function EmpIDCard({ id, onClose }) {
//   const institutecode = sessionStorage.getItem("institutecode");

//   const [employee, setEmployee] = useState({});
//   const [employeeDetails, setEmployeeDetails] = useState({});

//   useEffect(() => {
//     const fetchEmployeeDetails = async () => {
//       try {
//         if (!institutecode) {
//           console.log("No Institutecode found in local storage");
//           return;
//         }

//         const response = await axios.get(
//           `https://pjsofttech.in:20443/findInstitutesby/Institutecode?institutecode=${institutecode}`
//         );
//         setEmployeeDetails(response.data);
//       } catch (error) {
//         console.error("Error fetching the institute details");
//       }
//     };
//     fetchEmployeeDetails();
//   }, [institutecode]);

//   useEffect(() => {
//     const loadEmployee = async () => {
//       if (id) {
//         try {
//           console.log("Selected employee ID:", id);
//           const result = await axios.get(
//             `https://pjsofttech.in:10443/empById/${id}`
//           );
//           setEmployee(result.data);
//         } catch (error) {
//           console.error("Error fetching employee", error);
//         }
//       }
//     };
//     loadEmployee();
//   }, [id]);

//   return (
//     <Card sx={{
//         maxWidth: 300,
//         margin: 'auto',
//         boxShadow: 3,
//         borderRadius: 5,
//         padding: '20px',
//         backgroundColor: '#fff',
//         height: 530
//       }}>
//         <Typography style={{
//             textAlign: 'center',
//             background: 'linear-gradient(to right, #FAD126, #FF564E)',
//             color: 'white',
//             paddingTop: '40px',
//             paddingBottom: '10px',
//             marginBottom: '15px',
//             marginTop:'-50px', width:'150%',borderRadius:'50%',
//             marginLeft:'-60px',
//           }}>
//             <img
//               src={employeeDetails.instituteimage}
//               alt="Institute Logo"
//               style={{ width: '10%',height:'10%', marginRight: '10px', verticalAlign: 'middle', marginBottom:'10px', borderRadius:'50%', fontSize:'20px' }}
//             />
//             {employeeDetails.institutename}<br/>
//             <Typography style={{textAlign:'center', fontSize:'12px',}}>{employeeDetails.emailaddress}</Typography>
//             <Typography style={{textAlign:'center', fontSize:'12px'}}>{employeeDetails.phonenumber}</Typography>
//           </Typography>
//         <CardContent>
//           {/* Employee Image */}
//           <Avatar
//             src={employee.employeePhoto}
//             alt="Employee Image"
//             sx={{
//               width: 120,
//               height: 120,
//               margin: 'auto',
//             }}
//           />

//           {/* Employee Name and Contact */}
//           <Typography variant="h6" align="center" gutterBottom style={{ marginTop: '10px' }}>
//             <span style={{  fontWeight: 'bold' }}>{employee.fullName}</span>
//             <Typography style={{ fontSize: '13px', color: '#757575' }}>
//               {employee.mobileNo}
//             </Typography>
//             <Typography style={{ fontSize: '13px', color: '#757575' }}>
//               {employee.email}
//             </Typography>
//           </Typography>

//           {/* Additional Employee Details */}
//           <div style={{ padding: '2px' }}>
//   <Grid container spacing={1} alignItems="center">
//     {/* Department */}
//     <Grid item xs={4} style={{ textAlign: 'right', whiteSpace: 'nowrap',marginLeft:"-20px" }}>
//       <Typography variant="body2" sx={{ fontSize: '14px' }}><strong>Department</strong></Typography>
//     </Grid>
//     <Grid item xs={1} style={{  whiteSpace: 'nowrap',marginLeft:"20px" }}>
//       <Typography variant="body2" sx={{ fontSize: '14px' }}>:</Typography>
//     </Grid>
//     <Grid item xs={7} style={{ whiteSpace: 'nowrap' }}>
//       <Typography variant="body2" sx={{ fontSize: '14px' }}>{employee.department}</Typography>
//     </Grid>

//     {/* Joining Date */}
//     <Grid item xs={4} style={{ textAlign: 'right', whiteSpace: 'nowrap' ,marginLeft:"-20px"}}>
//       <Typography variant="body2" sx={{ fontSize: '14px' }}><strong>Joining Date</strong></Typography>
//     </Grid>
//     <Grid item xs={1} style={{ textAlign: 'center', whiteSpace: 'nowrap',marginLeft:"20px" }}>
//       <Typography variant="body2" sx={{ fontSize: '14px' }}>:</Typography>
//     </Grid>
//     <Grid item xs={7} style={{ whiteSpace: 'nowrap' }}>
//       <Typography variant="body2" sx={{ fontSize: '14px' }}>{new Date(employee.joiningDate).toLocaleDateString()}</Typography>
//     </Grid>

//     {/* Work Location */}
//     <Grid item xs={4} style={{ textAlign: 'right', whiteSpace: 'nowrap',marginLeft:"-20px" }}>
//       <Typography variant="body2" sx={{ fontSize: '14px' }}><strong>Work Location</strong></Typography>
//     </Grid>
//     <Grid item xs={1} style={{ textAlign: 'center', whiteSpace: 'nowrap',marginLeft:"20px" }}>
//       <Typography variant="body2" sx={{ fontSize: '14px' }}>:</Typography>
//     </Grid>
//     <Grid item xs={7} style={{ whiteSpace: 'nowrap' }}>
//       <Typography variant="body2" sx={{ fontSize: '14px' }}>{employee.workLocation}</Typography>
//     </Grid>

//     {/* Shift */}
//     <Grid item xs={4} style={{ textAlign: 'start', whiteSpace: 'nowrap',marginLeft:"-20px" }}>
//       <Typography variant="body2" sx={{ fontSize: '14px' }}><strong>Shift</strong></Typography>
//     </Grid>
//     <Grid item xs={1} style={{ textAlign: 'center', whiteSpace: 'nowrap',marginLeft:"20px" }}>
//       <Typography variant="body2" sx={{ fontSize: '14px' }}>:</Typography>
//     </Grid>
//     <Grid item xs={7} style={{ whiteSpace: 'nowrap' }}>
//       <Typography variant="body2" sx={{ fontSize: '14px' }}>{employee.shift} ({employee.shiftStartTime} - {employee.shiftEndTime})</Typography>
//     </Grid>
//   </Grid>
// </div>
//         </CardContent>
    
//         {/* Placeholder for future use */}
//         <Typography style={{
//             textAlign: 'center',
//             background: 'linear-gradient(to right, #FAD126, #FF564E)',
//             color: 'white',
//             paddingTop: '40px',
//             width:'350px', borderRadius:'50%', marginLeft:'-40px',
//              height:200,
//           }}>
//         </Typography>
//       </Card>
//   );
// }


import axios from "axios";
import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Avatar,Grid, Button, Box } from "@mui/material";
import html2pdf from 'html2pdf.js';

export default function EmpIDCard({ id, onClose }) {
  const institutecode = sessionStorage.getItem("institutecode");

  const [employee, setEmployee] = useState({});
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [selectedColor, setSelectedColor] = useState('#FF564E');

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        if (!institutecode) {
          console.log("No Institutecode found in local storage");
          return;
        }

        const response = await axios.get(
          `https://pjsofttech.in:20443/findInstitutesby/Institutecode?institutecode=${institutecode}`
        );
        setEmployeeDetails(response.data);
      } catch (error) {
        console.error("Error fetching the institute details");
      }
    };
    fetchEmployeeDetails();
  }, [institutecode]);

  useEffect(() => {
    const loadEmployee = async () => {
      if (id) {
        try {
          console.log("Selected employee ID:", id);
          const result = await axios.get(
            `https://pjsofttech.in:10443/empById/${id}`
          );
          setEmployee(result.data);
        } catch (error) {
          console.error("Error fetching employee", error);
        }
      }
    };
    loadEmployee();
  }, [id]);

  const downloadPdf = () => {
    const element = document.getElementById("pdf-container"); // Select pdf-container

    html2pdf()
      .set({
        filename: `ID_Card_${employee.name}.pdf`,
        html2canvas: {
          scale: 3, // Increases resolution for clearer images
          useCORS: true, // Allows cross-origin images to be captured
        },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
      })
      .from(element)
      .save();
  };

  const colorOptions = [
    "#FF564E", // Gradient color 1
    "#3F51B5", // Solid color
    "#009688", // Solid color
    "#FF9800", // Gradient color 2
    "#000080", // Solid color (light pink, replacing brown)
    "#800080", // Solid color (purple, replacing green)
    "#2196F3", // Solid color (blue)
    "#9C27B0", // Solid color (purple)
    "#A9A9A9", // Solid color (amber)
    "#E91E63", // Solid color (pink)
];

  return (
<>
    <Box display="flex" justifyContent="center" mb={2}>
    {colorOptions.map((color, index) => (
      <Button
        key={index}
        onClick={() => setSelectedColor(color)}
        style={{
          backgroundColor: color,
          width: 30,
          height: 30,
          minWidth: 0,
          borderRadius: '50%',
          margin: '0 5px',
          border: color === selectedColor ? '2px solid black' : 'none'
        }}
      />
    ))}
  </Box>
    <div id="pdf-container" style={{ paddingTop: '20px' }}>
         {/* Adjust padding as needed */}
        <div id="id-card">
    <Card sx={{
        maxWidth: 300,
        margin: 'auto',
        boxShadow: 3,
        borderRadius: 5,
        padding: '20px',
        backgroundColor: '#fff',
        height: 530
      }}>
        <Typography style={{
            textAlign: 'center',
            background:
                  selectedColor === "#FF564E" || selectedColor === "#FF9800"
                    ? `linear-gradient(to right, #FAD126, ${selectedColor})`
                    : selectedColor, // Directly apply the solid color for other options
                color: "white",
            paddingTop: '40px',
            paddingBottom: '10px',
            marginBottom: '15px',
            marginTop:'-50px', width:'150%',borderRadius:'50%',
            marginLeft:'-60px',
          }}>
            <img
              src={employeeDetails.instituteimage}
              alt="Institute Logo"
              style={{ width: '10%',height:'10%', marginRight: '10px', verticalAlign: 'middle', marginBottom:'10px', borderRadius:'50%', fontSize:'20px' }}
            />
            {employeeDetails.institutename}<br/>
            <Typography style={{textAlign:'center', fontSize:'12px',}}>{employeeDetails.emailaddress}</Typography>
            <Typography style={{textAlign:'center', fontSize:'12px'}}>{employeeDetails.phonenumber}</Typography>
          </Typography>
        <CardContent>
          {/* Employee Image */}
          <Avatar
            src={employee.employeePhoto}
            alt="Employee Image"
            sx={{
              width: 120,
              height: 120,
              margin: 'auto',
            }}
          />

          {/* Employee Name and Contact */}
          <Typography variant="h6" align="center" gutterBottom style={{ marginTop: '10px' }}>
            <span style={{  fontWeight: 'bold' }}>{employee.fullName}</span>
            <Typography style={{ fontSize: '13px', color: '#757575' }}>
              {employee.mobileNo}
            </Typography>
            <Typography style={{ fontSize: '13px', color: '#757575' }}>
              {employee.email}
            </Typography>
          </Typography>

          {/* Additional Employee Details */}
          <div style={{ padding: '2px' }}>
  <Grid container spacing={1} alignItems="center">
    {/* Department */}
    <Grid item xs={4} style={{ textAlign: 'right', whiteSpace: 'nowrap',marginLeft:"-20px" }}>
      <Typography variant="body2" sx={{ fontSize: '14px' }}><strong>Department</strong></Typography>
    </Grid>
    <Grid item xs={1} style={{  whiteSpace: 'nowrap',marginLeft:"20px" }}>
      <Typography variant="body2" sx={{ fontSize: '14px' }}>:</Typography>
    </Grid>
    <Grid item xs={7} style={{ whiteSpace: 'nowrap' }}>
      <Typography variant="body2" sx={{ fontSize: '14px' }}>{employee.department}</Typography>
    </Grid>

    {/* Joining Date */}
    <Grid item xs={4} style={{ textAlign: 'right', whiteSpace: 'nowrap' ,marginLeft:"-20px"}}>
      <Typography variant="body2" sx={{ fontSize: '14px' }}><strong>Joining Date</strong></Typography>
    </Grid>
    <Grid item xs={1} style={{ textAlign: 'center', whiteSpace: 'nowrap',marginLeft:"20px" }}>
      <Typography variant="body2" sx={{ fontSize: '14px' }}>:</Typography>
    </Grid>
    <Grid item xs={7} style={{ whiteSpace: 'nowrap' }}>
      <Typography variant="body2" sx={{ fontSize: '14px' }}>{new Date(employee.joiningDate).toLocaleDateString()}</Typography>
    </Grid>

    {/* Work Location */}
    <Grid item xs={4} style={{ textAlign: 'right', whiteSpace: 'nowrap',marginLeft:"-20px" }}>
      <Typography variant="body2" sx={{ fontSize: '14px' }}><strong>Work Location</strong></Typography>
    </Grid>
    <Grid item xs={1} style={{ textAlign: 'center', whiteSpace: 'nowrap',marginLeft:"20px" }}>
      <Typography variant="body2" sx={{ fontSize: '14px' }}>:</Typography>
    </Grid>
    <Grid item xs={7} style={{ whiteSpace: 'nowrap' }}>
      <Typography variant="body2" sx={{ fontSize: '14px' }}>{employee.workLocation}</Typography>
    </Grid>

    {/* Shift */}
    <Grid item xs={4} style={{ textAlign: 'start', whiteSpace: 'nowrap',marginLeft:"-20px" }}>
      <Typography variant="body2" sx={{ fontSize: '14px' }}><strong>Shift</strong></Typography>
    </Grid>
    <Grid item xs={1} style={{ textAlign: 'center', whiteSpace: 'nowrap',marginLeft:"20px" }}>
      <Typography variant="body2" sx={{ fontSize: '14px' }}>:</Typography>
    </Grid>
    <Grid item xs={7} style={{ whiteSpace: 'nowrap' }}>
      <Typography variant="body2" sx={{ fontSize: '14px' }}>{employee.shift} ({employee.shiftStartTime} - {employee.shiftEndTime})</Typography>
    </Grid>
  </Grid>
</div>
        </CardContent>
    
        {/* Placeholder for future use */}
        <Typography style={{
            textAlign: 'center',
            background:
                  selectedColor === "#FF564E" || selectedColor === "#FF9800"
                    ? `linear-gradient(to right, #FAD126, ${selectedColor})`
                    : selectedColor, // Directly apply the solid color for other options
                color: "white",
            paddingTop: '40px',
            width:'350px', borderRadius:'50%', marginLeft:'-40px',
             height:200,
          }}>
        </Typography>
      </Card>
      </div>
       {/* Hidden button for triggering download */}
       <Button id="download-button" onClick={downloadPdf} style={{ display: 'none' }}>Download as PDF</Button>
      </div>
      </>
  );
}
