// import React, { useState, useEffect } from 'react';
// import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid } from '@mui/material';
// import axios from 'axios';
// import ShowFooter from './ShowFooter';

// const Footer = () => {
//   const [open, setOpen] = useState(false);
//   const [footerData, setFooterData] = useState({
//     title: '',
//     footerColor: '',
//     instagramLink: '',
//     facebookLink: '',
//     twitterLink: '',
//     youtubeLink: '',
//     whatsappLink: '',
//     email: '',
//     mobileNumber: '',
//     address: ''
//   });
//   const [isUpdate, setIsUpdate] = useState(false);

//   const institutecode = () => sessionStorage.getItem("institutecode");

//   useEffect(() => {
//     if (open) {
//       fetchFooterData();
//     }
//   }, [open]);

//   const fetchFooterData = async () => {
//     try {
//       const response = await axios.get('https://pjsofttech.in:28443/getFooterByInstitutecode', {
//         params: { institutecode: institutecode() }
//       });
//       if (response.status === 200 && response.data) {
//         setFooterData(response.data);
//         setIsUpdate(true);
//       } else {
//         setFooterData({
//           title: '',
//           footerColor: '',
//           instagramLink: '',
//           facebookLink: '',
//           twitterLink: '',
//           youtubeLink: '',
//           whatsappLink: '',
//           email: '',
//           mobileNumber: '',
//           address: ''
//         });
//         setIsUpdate(false);
//       }
//     } catch (error) {
//       console.error('Error fetching footer data:', error);
//       setFooterData({
//         title: '',
//         footerColor: '',
//         instagramLink: '',
//         facebookLink: '',
//         twitterLink: '',
//         youtubeLink: '',
//         whatsappLink: '',
//         email: '',
//         mobileNumber: '',
//         address: ''
//       });
//       setIsUpdate(false);
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFooterData({ ...footerData, [name]: value });
//   };

//   const handleSubmit = async () => {
//     try {
//       if (isUpdate) {
//         await axios.put('https://pjsofttech.in:28443/updateFooter', footerData);
//       } else {
//         await axios.post('https://pjsofttech.in:28443/createFooter', footerData);
//       }
//       setOpen(false);
//     } catch (error) {
//       console.error('Error submitting footer data:', error);
//     }
//   };

//   return (
//     <div>
//       <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
//         Manage Footer
//       </Button>
//       <Dialog open={open} onClose={() => setOpen(false)}>
//         <DialogTitle color={'black'}>{isUpdate ? 'Update Footer' : 'Create Footer'}</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2} mt={0.1} className="textField-root">
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="Title"
//                 name="title"
//                 value={footerData.title}
//                 onChange={handleInputChange}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="Footer Color"
//                 name="footerColor"
//                 value={footerData.footerColor}
//                 onChange={handleInputChange}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="Instagram Link"
//                 name="instagramLink"
//                 value={footerData.instagramLink}
//                 onChange={handleInputChange}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="Facebook Link"
//                 name="facebookLink"
//                 value={footerData.facebookLink}
//                 onChange={handleInputChange}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="Twitter Link"
//                 name="twitterLink"
//                 value={footerData.twitterLink}
//                 onChange={handleInputChange}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="YouTube Link"
//                 name="youtubeLink"
//                 value={footerData.youtubeLink}
//                 onChange={handleInputChange}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="WhatsApp Link"
//                 name="whatsappLink"
//                 value={footerData.whatsappLink}
//                 onChange={handleInputChange}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="Email"
//                 name="email"
//                 value={footerData.email}
//                 onChange={handleInputChange}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="Mobile Number"
//                 name="mobileNumber"
//                 value={footerData.mobileNumber}
//                 onChange={handleInputChange}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="Address"
//                 name="address"
//                 value={footerData.address}
//                 onChange={handleInputChange}
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpen(false)} color="secondary">
//             Cancel
//           </Button>
//           <Button onClick={handleSubmit} color="primary" variant="contained">
//             {isUpdate ? 'Update' : 'Create'}
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <ShowFooter />
//     </div>
//   );
// };

// export default Footer;

import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid } from '@mui/material';
import axios from 'axios';
import ShowFooter from './ShowFooter';

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [footerData, setFooterData] = useState({
    title: '',
    footerColor: '',
    instagramLink: '',
    facebookLink: '',
    twitterLink: '',
    youtubeLink: '',
    whatsappLink: '',
    email: '',
    mobileNumber: '',
    address: ''
  });
  const [isUpdate, setIsUpdate] = useState(false);

  const institutecode = () => sessionStorage.getItem("institutecode");

  useEffect(() => {
    if (open) {
      fetchFooterData();
    }
  }, [open]);

  const fetchFooterData = async () => {
    try {
      const response = await axios.get('https://pjsofttech.in:28443/getFooterByInstitutecode', {
        params: { institutecode: institutecode() }
      });
      if (response.status === 200 && response.data) {
        setFooterData(response.data);
        setIsUpdate(true);
      } else {
        resetForm();
      }
    } catch (error) {
      console.error('Error fetching footer data:', error);
      resetForm();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFooterData({ ...footerData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      
      // Append only relevant footer data (excluding institutecode)
      Object.entries(footerData).forEach(([key, value]) => {
        if (key !== "institutecode") { // Exclude institutecode if it already exists
          formData.append(key, value || '');
        }
      });
  
      // Append institutecode only once
      formData.append("institutecode", institutecode());
  
      if (isUpdate) {
        await axios.put('https://pjsofttech.in:28443/updateFooter', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        await axios.post('https://pjsofttech.in:28443/createFooter', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }
  
      setOpen(false);
    } catch (error) {
      console.error('Error submitting footer data:', error);
    }
  };
  

  const resetForm = () => {
    setFooterData({
      title: '',
      footerColor: '',
      instagramLink: '',
      facebookLink: '',
      twitterLink: '',
      youtubeLink: '',
      whatsappLink: '',
      email: '',
      mobileNumber: '',
      address: ''
    });
    setIsUpdate(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Manage Footer
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle color={'black'}>{isUpdate ? 'Update Footer' : 'Create Footer'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={0.1} className="textField-root">
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={footerData.title}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Footer Color"
                name="footerColor"
                value={footerData.footerColor}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Instagram Link"
                name="instagramLink"
                value={footerData.instagramLink}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Facebook Link"
                name="facebookLink"
                value={footerData.facebookLink}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Twitter Link"
                name="twitterLink"
                value={footerData.twitterLink}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="YouTube Link"
                name="youtubeLink"
                value={footerData.youtubeLink}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="WhatsApp Link"
                name="whatsappLink"
                value={footerData.whatsappLink}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={footerData.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mobile Number"
                name="mobileNumber"
                value={footerData.mobileNumber}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={footerData.address}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      <ShowFooter />
    </div>
  );
};

export default Footer;
