import React, { useState } from "react";
import { TextField, Button, Box, InputAdornment } from "@mui/material";
import { addDynamicUrl } from "./Api/AllApi";
import Swal from "sweetalert2";

const DynamicApiForm = () => {
  const [dynamicPart, setDynamicPart] = useState("");
  const [payload, setPayload] = useState({}); // For additional form fields
  const [loading, setLoading] = useState(false);
  const institutecode = () => sessionStorage.getItem("institutecode");
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "dynamicPart") {
      setDynamicPart(value);
    } else {
      setPayload((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await addDynamicUrl(dynamicPart, institutecode());
        Swal.fire("Success", response.message, "success");
        setDynamicPart("");

    } catch (error) {
        Swal.fire(
            "Error",
            error.response?.data?.message || "Failed to create URL mapping",
            "error"
        );
    }
};

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: "flex", flexDirection: "column", gap: 2, maxWidth: 400, margin: "auto" }}
      className="textField-root"
    >
      <TextField
        label="Dynamic Part"
        variant="outlined"
        size="small"
        name="dynamicPart"
        value={dynamicPart}
        onChange={handleChange}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              https://pjsofttech.in/
            </InputAdornment>
          ),
        }}
      />
      
      <Button type="submit" variant="contained" color="primary" disabled={loading}>
        {loading ? "Submitting..." : "Submit"}
      </Button>
    </Box>
  );
};

export default DynamicApiForm;
