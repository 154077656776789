import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, Paper } from '@mui/material';

const institutecode = sessionStorage.getItem('institutecode')

const urls = [
  `https://pjsofttech.in:19443/installments-revenue-and-count?institutecode=${institutecode}&timeframe=today`,
  `https://pjsofttech.in:19443/installments-revenue-and-count?institutecode=${institutecode}&timeframe=7`,
  `https://pjsofttech.in:19443/installments-revenue-and-count?institutecode=${institutecode}&timeframe=30`,
  `https://pjsofttech.in:19443/installments-revenue-and-count?institutecode=${institutecode}&timeframe=365`,
  `https://pjsofttech.in:19443/installments-revenue-and-count?institutecode=${institutecode}&timeframe=total`,
];

const cardColors = [
  '#F9E79F', // Light red
  '#FF6F61', // Light green
  '#3498DB', // Light blue
  '#F9E79F', // Light yellow
  '#FF6F61', // Light magenta
];

const labels = [
  "Today's Install 1",
  '7 Days Install 1',
  '30 Days Install 1',
  '365 Days Install 1',
  'Total Install 1',
];

export default function Installment1CountRevenue() {
  const [data, setData] = useState([[], [], [], [], []]); // For holding the fetched data

  useEffect(() => {
    const fetchData = async () => {
      const results = await Promise.all(urls.map(url => fetch(url).then(res => res.json())));
      const formattedData = results.map(item => item[0]); // Get first value from each response
      setData(formattedData);
    };

    fetchData();
  }, []);

  return (
    <div>
      <Grid container sx={{justifyContent:'space-between'}} mt={-3}>
        {data.map((value, index) => (
          <Grid item xs={12} sm={6} md={2.4} key={`second-value-card-${index}`}>
              <CardContent>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    backgroundColor: cardColors[index % cardColors.length],
                    borderRadius: 2,
                    fontWeight: "bold",
                    boxShadow: 3,
                  }}
                >
                  <Typography variant="h7" component="div">
                    {labels[index]}
                  </Typography>
                  <Typography variant="h7">
                  {value[1] || 0}
                  </Typography>
                </Paper>
              </CardContent>
          </Grid>
        ))}
      </Grid>
      <Grid container sx={{justifyContent:'space-between'}} mt={-3}>
        {data.map((value, index) => (
          <Grid  item xs={12} sm={6} md={2.4} key={`first-value-card-${index}`}>
              <CardContent>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    backgroundColor: cardColors[index % cardColors.length],
                    borderRadius: 2,
                    fontWeight: "bold",
                    boxShadow: 3,
                  }}
                >
                <Typography variant="h7" component="div">
                  {labels[index]}
                </Typography>
                <Typography variant="h7">
                ₹{value[0] || 0}
                </Typography>
                </Paper>
              </CardContent>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
