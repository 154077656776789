import React, { useEffect, useState } from "react";
import {
  FormControl,
  MenuItem,
  Typography,
  TextField,
  Box,
  Button,
} from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import axios from "axios";

export default function DailyFormsByMonthYear() {
  const today = new Date(); // Get today's date
  const [month, setMonth] = useState(today.getMonth() + 1); // Current month (0-based index)
  const [year, setYear] = useState(today.getFullYear()); // Current year
  const [data, setData] = useState([]);
  const [view, setView] = useState("count"); // Track whether to show 'count' or 'revenue'

  const institutecode = sessionStorage.getItem("institutecode");

  // To generate months and years
  const months = Array.from({ length: 12 }, (v, k) => k + 1);
  const currentYear = today.getFullYear();
  const years = Array.from({ length: 15 }, (v, k) => currentYear - 7 + k);

  // Function to fetch data from API
  const fetchData = async () => {
    const response = await axios.get(
      `https://pjsofttech.in:17443/daily-stats?month=${month}&year=${year}&institutecode=${institutecode}`
    );
    setData(response.data);
  };

  useEffect(() => {
    fetchData();
  }, [month, year]); // Re-fetch data when month or year changes

  // Prepare data for Nivo Bar charts
  const chartData = Array.from({ length: 31 }, (v, k) => {
    const day = k + 1;
    const entry = data.find((d) => d.date === day);
    return {
      date: day,
      count: entry ? entry.count : 0,
      revenue: entry ? entry.revenue : 0,
    };
  });

  return (
    <div>
      {/* Dropdowns and Button in a Horizontal Layout */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {/* Title */}
        <Typography variant="h7" fontWeight="bold">
          {view === "count" ? "Daily Counts" : "Daily Revenue"}
        </Typography>

        <div style={{ marginBottom: "16px", display: "flex" }}>
          {/* Month Selector */}
          <FormControl
            variant="outlined"
            style={{ marginRight: "16px" }}
            className="textField-root"
            size="small"
          >
            <TextField
              size="small"
              select
              label="Month"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              {months.map((m) => (
                <MenuItem key={m} value={m}>
                  {new Date(0, m - 1).toLocaleString("default", {
                    month: "long",
                  })}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          {/* Year Selector */}
          <FormControl
            variant="outlined"
            style={{ marginRight: "16px" }}
            className="textField-root"
            size="small"
          >
            <TextField
              size="small"
              select
              label="Year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          {/* Toggle Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setView(view === "count" ? "revenue" : "count")}
            style={{
              backgroundColor: "#3498DB",
              color: "#fff",
              textTransform: "none",
            }}
          >
            {view === "count" ? "Revenue" : "Count"}
          </Button>
        </div>
      </div>

      {/* Bar Chart */}
      <div style={{ width: "90%", marginTop: "16px" }}>
        <Box height={330}>
          <ResponsiveBar
            data={chartData}
            keys={[view]} // Dynamically choose either 'count' or 'revenue' based on the selection
            indexBy="date"
            margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
            padding={0.2}
            colors={view === "count" ? ["#3498DB"] : ["#FF6F61"]}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Date",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: view === "count" ? "Count" : "Revenue",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            role="application"
            ariaLabel={`Daily ${view} bar chart`}
            isInteractive={true}
          />
        </Box>
      </div>
    </div>
  );
}
