import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  IconButton
} from "@mui/material";
import Swal from 'sweetalert2';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from "@mui/icons-material/Info"; // ✅ Import InfoIcon

const AddClass = () => {
  const [formData, setFormData] = useState({
    standardId: "",
    mediumId: "",
    divisionId: "",
    year: "",
    teacherIds: [],
  });
  const [classRooms, setClassRooms] = useState([]);
  const [standards, setStandards] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [year, setYear] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]); // State for teachers dropdown
  const [openInfoDialog, setOpenInfoDialog] = useState(false); //  New dialog for class students
  const [infoStudents, setInfoStudents] = useState([]); //  Store students from Info API
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentClassroomId, setCurrentClassroomId] = useState(null);
  const [error, setError] = useState("");

  const institutecode = sessionStorage.getItem("institutecode");

  useEffect(() => {
    fetchAllClassRooms();
    fetchDropdownOptions();
    fetchTeachers(); // Fetch teachers
  }, []);

  useEffect(() => {
    const generateYears = () => {
      const currentYear = new Date().getFullYear();
      const range = 10;
      const years = [];
      for (let i = currentYear - range; i <= currentYear; i++) {
        years.push(`${i}-${i + 1}`);
      }
      setYear(years);
    };
    generateYears();
  }, []);

  // Fetch all classrooms
  const fetchAllClassRooms = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:9443/getallclassrooms?institutecode=${institutecode}`
      );
      setClassRooms(response.data);
    } catch (error) {
      setError("Failed to fetch classrooms");
    }
  };

  // Fetch options for dropdowns
  const fetchDropdownOptions = async () => {
    try {
      const [standardsRes, mediumsRes, divisionsRes] = await Promise.all([
        axios.get(`https://pjsofttech.in:9443/all?institutecode=${institutecode}`),
        axios.get(`https://pjsofttech.in:9443/getall?institutecode=${institutecode}`),
        axios.get(`https://pjsofttech.in:9443/getallDivision?institutecode=${institutecode}`),
      ]);

      setStandards(standardsRes.data);
      setMediums(mediumsRes.data);
      setDivisions(divisionsRes.data);
    } catch (error) {
      setError("Failed to fetch dropdown options");
    }
  };

  const fetchTeachers = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:9443/GetAllTeacher?institutecode=${institutecode}`
      );
      setTeachers(response.data);
    } catch (error) {
      setError("Failed to fetch teachers");
    }
  };

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "teacherIds") {
      setFormData({
        ...formData,
        teacherIds: typeof value === "string" ? value.split(",") : value, // Convert string to array
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const { standardId, mediumId, divisionId, year, teacherIds } = formData;

    if (!standardId || !mediumId || !divisionId || !year || teacherIds.lenght === 0) {
      setError("All fields are required");
      return;
    }

    try {
      await axios.post("https://pjsofttech.in:9443/addclassroom", null, {
        params: {
          standardId,
          mediumId,
          divisionId,
          year,
          teacherIds: teacherIds.join(","),
          institutecode,
        },
      });
      Swal.fire("Success", "Classroom added successfully", "success");
      setFormData({
        standardId: "",
        mediumId: "",
        divisionId: "",
        year: "",
        teacherIds:[],
      });
      fetchAllClassRooms();
    } catch (error) {
      Swal.fire("Error", "Failed to add classroom", "error");
    }
  };

  const handleOpenDialog = async (classroomId) => {
    setCurrentClassroomId(classroomId);
  
    // Find the selected classroom details
    const selectedClassroom = classRooms.find(
      (classRoom) => classRoom.id === classroomId
    );
  
    if (!selectedClassroom) {
      Swal.fire("Error", "Classroom not found", "error");
      return;
    }
  
    const { standard, medium, year } = selectedClassroom;
  
    try {
      const response = await axios.get(
        `https://pjsofttech.in:9443/Studentfilter`, 
        {
          params: {
            standard: standard?.standard || "",
            mediumname: medium?.mediumname || "",
            year: year || "",
            institutecode,
          },
        }
      );
  
        setStudents(response.data || []);
        setOpenDialog(true);
    } catch (error) {
      Swal.fire("Error", "Failed to fetch students", "error");
      setStudents([]);
    }
  };

    // ✅ New function to fetch and display class students
    const handleOpenInfoDialog = async (classroomId) => {
      try {
        const response = await axios.get(
          `https://pjsofttech.in:9443/getclassrooms/students`,
          {
            params: {
              institutecode,
              classRoomId: classroomId,
            },
          }
        );
  
        setInfoStudents(response.data || []); // ✅ Ensure it's an array
        setOpenInfoDialog(true);
      } catch (error) {
        Swal.fire("Error", "Failed to fetch class students", "error");
        setInfoStudents([]); // ✅ Reset in case of error
      }
    };

  const handleStudentSelect = (studentId) => {
    setSelectedStudents((prevSelected) =>
      prevSelected.includes(studentId)
        ? prevSelected.filter((id) => id !== studentId)
        : [...prevSelected, studentId]
    );
  };

  const handleAddStudent = async () => {
    if (selectedStudents.length === 0) {
      Swal.fire("Error", "Please select at least one student", "error");
      return;
    }

    try {
      await axios.post(`https://pjsofttech.in:9443/addStudent`, null, {
        params: {
          classRoomId: currentClassroomId,
          studentIds: selectedStudents.join(","), // Send as comma-separated values
        },
      });

      Swal.fire("Success", "Students added successfully", "success");
      setOpenDialog(false);
      setSelectedStudents([]);
      fetchAllClassRooms();
    } catch (error) {
      Swal.fire("Error", "Failed to add students", "error");
    }
  };

  return (
    <div>
      <Box p={2}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Grid container spacing={2} component="form" onSubmit={handleSubmit}  className="textField-root">
            {/* Standard Dropdown */}
            <Grid item xs={12} md={3}>
              <TextField
                select
                label="Standard"
                name="standardId"
                value={formData.standardId}
                onChange={handleChange}
                fullWidth
              >
                {standards.map((standard) => (
                  <MenuItem key={standard.sid} value={standard.sid}>
                    {standard.standard}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Medium Dropdown */}
            <Grid item xs={12} md={3}>
              <TextField
                select
                label="Medium"
                name="mediumId"
                value={formData.mediumId}
                onChange={handleChange}
                fullWidth
              >
                {mediums.map((medium) => (
                  <MenuItem key={medium.mediumid} value={medium.mediumid}>
                    {medium.mediumname}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Division Dropdown */}
            <Grid item xs={12} md={3}>
              <TextField
                select
                label="Division"
                name="divisionId"
                value={formData.divisionId}
                onChange={handleChange}
                fullWidth
              >
                {divisions.map((division) => (
                  <MenuItem key={division.did} value={division.did}>
                    {division.division}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                select
                label="Year"
                name="year"
                value={formData.year}
                onChange={handleChange}
                fullWidth
              >
                {year.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={3}>
                <TextField
                  select
                  label="Teachers"
                  name="teacherIds"
                  value={formData.teacherIds}
                  onChange={handleChange}
                  fullWidth
                  SelectProps={{ multiple: true }}
                >
                  {teachers.map((teacher) => (
                    <MenuItem key={teacher.tid} value={teacher.tid}>
                      {teacher.teacherName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button variant="contained" color="primary" type="submit">
                  Add Classroom
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        {/* Classroom Table */}
        <Paper elevation={3} style={{ padding: "20px", marginTop: "30px" }}>
          <TableContainer component={Paper} className="table-root">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Classroom ID</TableCell>
                  <TableCell>Standard</TableCell>
                  <TableCell>Medium</TableCell>
                  <TableCell>Division</TableCell>
                  <TableCell>Year</TableCell>
                  <TableCell>Teachers</TableCell> 
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classRooms.length > 0 ? (
                  classRooms.map((classRoom) => (
                    <TableRow key={classRoom.id}>
                      <TableCell>{classRoom.id}</TableCell>
                      <TableCell>{classRoom.standard?.standard || "N/A"}</TableCell>
                      <TableCell>{classRoom.medium?.mediumname || "N/A"}</TableCell>
                      <TableCell>{classRoom.division?.division || "N/A"}</TableCell>
                      <TableCell>{classRoom.year || "N/A"}</TableCell>
                       <TableCell>
                        {classRoom.studentTeachers?.length > 0
                          ? classRoom.studentTeachers.map(t => t.teacherName).join(", ")
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          variant="contained"
                          color="success"
                          onClick={() => handleOpenDialog(classRoom.id)}
                        >
                          <AddCircleIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={() => handleOpenInfoDialog(classRoom.id)}>
                          <InfoIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Classrooms Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* Student Dialog */}
        <Dialog open={openDialog} fullWidth onClose={() => setOpenDialog(false)} maxWidth="lg" className="table-root">
          <DialogTitle color={'black'}>Select Student</DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell>ID</TableCell>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Standard</TableCell>
                    <TableCell>Medium</TableCell>
                    <TableCell>Add Student</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(students || []).map((student) => (
                    <TableRow key={student.stdid}>
                       <TableCell>{student.stdid}</TableCell>
                      <TableCell>{student.full_name}</TableCell>
                      <TableCell>{student.emailAddress}</TableCell>
                      <TableCell>{student.phoneNumber}</TableCell>
                      <TableCell>{student.standard}</TableCell>
                      <TableCell>{student.mediumname}</TableCell>
                      <TableCell>
                      <Checkbox
                          checked={selectedStudents.includes(student.stdid)}
                          onChange={() => handleStudentSelect(student.stdid)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleAddStudent} color="primary">
              Add Students
            </Button>
            <Button onClick={() => setOpenDialog(false)} color="secondary" >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

          {/* Info Dialog */}
          <Dialog open={openInfoDialog} fullWidth onClose={() => setOpenInfoDialog(false)} maxWidth="lg" className="table-root">
          <DialogTitle color={'black'}>Classroom Students</DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table>
              <TableHead>
                  <TableRow>
                  <TableCell>Roll No</TableCell>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Standard</TableCell>
                    <TableCell>Medium</TableCell>
                    <TableCell>Year</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(infoStudents || []).map((student) => ( // Prevent error
                    <TableRow key={student.stdid}>
                      <TableCell>{student.rollNo}</TableCell>
                      <TableCell>{student.full_name}</TableCell>
                      <TableCell>{student.emailAddress}</TableCell>
                      <TableCell>{student.phoneNumber}</TableCell>
                      <TableCell>{student.standard}</TableCell>
                      <TableCell>{student.mediumname}</TableCell>
                      <TableCell>{student.year}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      </Box>
    </div>
  );
};

export default AddClass;
