// import React, { useState,useContext } from "react";
// import {
//   Container,
//   Grid,
//   Paper,
//   TextField,
//   Button,
//   Typography,
//   Link,
//   Box,
//   IconButton,
//   InputAdornment,
// } from "@mui/material";
// import styled from "@emotion/styled";
// import { keyframes } from "@emotion/react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import logo from "../img/logo.jpg";
// import HomeIcon from "@mui/icons-material/Home";
// import PersonIcon from "@mui/icons-material/Person";
// import SettingsIcon from "@mui/icons-material/Settings";
// import SchoolIcon from "@mui/icons-material/School";
// import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
// import BarChartIcon from "@mui/icons-material/BarChart";
// import MenuBookIcon from "@mui/icons-material/MenuBook";
// import WorkIcon from "@mui/icons-material/Work";
// import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
// import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
// import HowToRegIcon from "@mui/icons-material/HowToReg";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import BadgeIcon from '@mui/icons-material/Badge';
// import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
// import AnalyticsIcon from '@mui/icons-material/Analytics';
// import DriveEtaIcon from '@mui/icons-material/DriveEta';
// import AutoStoriesIcon from '@mui/icons-material/AutoStories';
// import BusinessIcon from '@mui/icons-material/Business';
// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;

// const BackgroundContainer = styled("div")`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//     background-color: hsl(218, 41%, 15%);
//   background-image: radial-gradient(650px circle at 0% 0%,
//       hsl(218, 41%, 35%) 15%,
//       hsl(218, 41%, 30%) 35%,
//       hsl(218, 41%, 20%) 75%,
//       hsl(218, 41%, 19%) 80%,
//       transparent 100%),
//     radial-gradient(1250px circle at 100% 100%,
//       hsl(218, 41%, 45%) 15%,
//       hsl(218, 41%, 30%) 35%,
//       hsl(218, 41%, 20%) 75%,
//       hsl(218, 41%, 19%) 80%,
//       transparent 100%);
      
//   padding: 0;

// `;
// const GridContainer = styled(Grid)`
//   flex: 1;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
// `;

// const ContentWrapper = styled("div")`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   max-width: 1300px;
//   padding: 10px;
// `;

// const randomDelay = () => `${Math.random() * 1.5}s`;

// const GifContainer = styled("div")`
//   flex: 1;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const GifImage = styled("img")`
//   width: 100%;
//   max-width: 700px;
//   height: auto;
//   animation: ${fadeIn} 1s ease-in-out;
// `;

// const LoginContainer = styled("div")`
//   flex: 1;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   marginTop: 20px;
// `;

// const LoginPaper = styled(Paper)`
//   padding: 32px;
//   width: 100%;
//   max-width: 400px;
//  background-color: #f2f2f2;
  
//   animation: ${fadeIn} 1s ease-in-out;
// `;

// const Logo = styled("img")`
//   display: block;
//   margin: 0 auto 20px;
//   width: 100px;
//   height: 80px;
//   animation: ${fadeIn} 1s ease-in-out;
// `;

// const LoginHeader = styled(Typography)`
//   text-align: center;
//   margin-bottom: 24px;
//   animation: ${fadeIn} 1s ease-in-out;
//   word-spacing: 0.3em; /* Adjust the value as needed */
//   font-weight: bold;
// `;

// const CustomForm = styled("form")`
//   display: flex;
//   flex-direction: column;
  
// `;

// const FormGroup = styled("div")`
//   margin-bottom: 16px;
// `;

// const FormControl = styled(TextField)`
//   width: 100%;
//   .MuiInputBase-root {

//   }
//   .MuiOutlinedInput-notchedOutline {

//   }
//   .MuiInputLabel-root {

//   }
// `;

// const ErrorMessage = styled(Typography)`
//   color: red;
//   margin-bottom: 16px;
// `;

// const BtnPrimary = styled(Button)`
//   margin-bottom: 16px;
// `;

// const ForgotPasswordLink = styled(Link)`
//   display: block;
//   text-align: center;
//   margin-top: 16px;

// `;

// function Login({ onLogin }) {
//   const [institutecode, setInstitutecode] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [error, setError] = useState("");
//   const navigate = useNavigate();
//   const handlePasswordVisibility = () => {
//     setShowPassword((prev) => !prev);
//   };
//   //const { setUser } = useContext(UserContext);

//   const loginUser = async (institutecode, email, password) => {
//     return axios.post(
//       `https://pjsofttech.in:20443/institutelogin?institutecode=${institutecode}&emailaddress=${email}&password=${password}`
//     );
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setError(""); // Clear previous error messages
//     try {
//       const response = await loginUser(institutecode, email, password);

//       if (response.status === 200) {
//         console.log("Login successful!", response.data);
//        // setUser({ isAuthenticated: true, institutecode, email });
//          sessionStorage.setItem("institutecode", institutecode);
//          sessionStorage.setItem("email", email);
//         onLogin();
//         navigate("/layout/combineDash");
//       } else if (response.status === 401) {
//         if (response.data === "Incorrect password") {
//           setError("Incorrect password");
//         } else if (response.data === "Account expired") {
//           setError("Account expired");
//         } else {
//           setError("Unauthorized access");
//         }
//       } else if (response.status === 404) {
//         if (response.data === "Incorrect institutecode") {
//           setError("Incorrect Organization Key");
//         } else if (response.data === "Admin not found") {
//           setError("Admin not found");
//         }
//       } else {
//         throw new Error("Unexpected response from the server");
//       }
//     } catch (error) {
//       console.error(
//         "Error logging in:",
//         error.response ? error.response.data : error.message
//       );
//       setError("An error occurred during login. Please try again later.");
//     }
//   };

//   const handleForgotPassword = () => {
//     navigate("/forgot-password");
//   };
//   const handleCreateAccount = () => {
//     navigate("/create-account");
//   };

  


//   const StyledPaper = styled(Paper)(({ delay }) => ({
//     width: "100px", // Reduced width
//     height: "100px", // Reduced height
//     borderRadius: "50%",
//     textAlign: "center",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     backgroundColor: "#f7f7f7",
//     transition: "transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease",
//     animation: `popIn 1s ease ${delay}s`,
//     animationFillMode: "forwards",
//     opacity: 0,
//     transform: "scale(0)",
//        padding: "10px", // Adjusted padding
  
//     "&:hover": {
//       transform: "scale(1.05)",
//       boxShadow: "0 8px 25px rgba(0, 0, 0, 0.3)",
//       backgroundImage: "linear-gradient(88.8deg, rgb(239, 171, 245) 13.4%, rgb(196, 181, 249) 76.3%)",
//     },
  
//     "@keyframes popIn": {
//       "0%": { opacity: 0, transform: "scale(0)" },
//       "100%": { opacity: 1, transform: "scale(1)" },
//     },
//   }));
  
//   return (
//     <>
//       <Grid item>
//       <Typography
//       variant="h3"
//       component="div"
//       align="center"
//       gutterBottom
//       sx={{
//         fontSize: "40px",
//         fontWeight: "bold",
//         color: "white",
//         marginTop: "10px",
//         marginBottom: "-60px",
//         background: "white",
//         borderRadius: "10px",
//         boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
//         textTransform: "uppercase",
//         letterSpacing: "2px",
//         wordSpacing: "0.3em",
//       }}
//     >
//       <span className="typing-effect">PJSOFTTECH Software</span>
//     </Typography>


//       </Grid>
//       <BackgroundContainer>
//         <ContentWrapper >
//         <GridContainer container  alignItems="center" style={{marginTop:"90px"}}>
//         {circles.map(({ icon, link, label }, index) => (
//           <Grid item lg={3} md={4} sm={6} xs={12} key={index} marginBottom={7} >
//             <StyledPaper delay={Math.random() * 0.5 + index * 0.1}  onClick={() => window.open(link, "_blank")}>
//               <div>{icon}</div>
//               <Typography
//                 variant="h6"
//                 sx={{
               
//                   fontSize: "14px",
//                   fontWeight: "bold",
//                   color: "black",
//                   textAlign: "center",
//                 }}
//               >
//                 {label}
//               </Typography>
//             </StyledPaper>
//           </Grid>
//         ))}
//       </GridContainer>

//           <LoginContainer style={{marginTop:"40px"}}>
//             <LoginPaper elevation={6}>
//               <Logo src={logo} alt="Logo" />
//               <LoginHeader variant="h5" whiteSpace={"nowrap"} >
//                 PJSOFTTECH LOGIN
//               </LoginHeader>
//               <CustomForm onSubmit={handleSubmit} className="textField-root">
//                 <FormGroup>
//                   <FormControl
//                     label="Organization ID"
//                     type="text"
//                     value={institutecode}
//                     onChange={(e) => setInstitutecode(e.target.value)}
//                     placeholder="Enter Organization Key"
//                     variant="outlined"
//                     fullWidth
//                   />
//                 </FormGroup>                                              
//                 <FormGroup>
//                   <FormControl
//                     label="Email address"
//                     type="email"                                  
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     placeholder="Enter email"
//                     variant="outlined"
//                     fullWidth
//                   />                                 
//                 </FormGroup>
//                 <FormGroup>
//                 <FormControl
//             label="Password"
//             type={showPassword ? "text" : "password"} // Toggle between "text" and "password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder="Password"
//             variant="outlined"
//             fullWidth
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <IconButton
                  
//                     onClick={handlePasswordVisibility}
//                     edge="end"
//                     aria-label="toggle password visibility"
//                   >
//                     {showPassword ? <VisibilityOff /> : <Visibility />}
//                   </IconButton>
//                 </InputAdornment>
//               ),
//             }}
//           />
//                 </FormGroup>
//                 {error && <ErrorMessage>{error}</ErrorMessage>}
//                 <BtnPrimary variant="contained" color="primary" type="submit">
//                   Login
//                 </BtnPrimary>
//                 <Link
//                   href="#"
//                   onClick={handleCreateAccount}
//                   underline="hover"
//                   sx={{
//                     display: "block",
//                     textAlign: "center",
//                     color: "black",
//                     "&:hover": { color: "#0056B3" },
//                     marginBottom: "5px",
//                     variant: "h4",
//                   }}
//                 >
//                   Create Account
//                 </Link>
//                 <ForgotPasswordLink
//                   href="#"
//                   onClick={handleForgotPassword}
//                   underline="hover"
//                   sx={{ color: "black", "&:hover": { color: "#0056B3" } }}
//                 >
//                   Forgot Password?
//                 </ForgotPasswordLink>
//               </CustomForm>
//             </LoginPaper>
//           </LoginContainer>
//         </ContentWrapper>
//       </BackgroundContainer>
//     </>
//   );
// }

// export default Login;




import React, { useState, useEffect, useRef } from "react"; // Import useRef
import {
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  Link,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../img/logo.jpg";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import AdbIcon from '@mui/icons-material/Adb';
import SchoolIcon from "@mui/icons-material/School";
import BarChartIcon from "@mui/icons-material/BarChart";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import WorkIcon from "@mui/icons-material/Work";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BadgeIcon from '@mui/icons-material/Badge';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BusinessIcon from '@mui/icons-material/Business';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import AnalyticsIcon from '@mui/icons-material/Analytics';


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const BackgroundContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%, hsl(218, 41%, 35%) 15%, hsl(218, 41%, 30%) 35%, hsl(218, 41%, 20%) 75%, hsl(218, 41%, 19%) 80%, transparent 100%), radial-gradient(1250px circle at 100% 100%, hsl(218, 41%, 45%) 15%, hsl(218, 41%, 30%) 35%, hsl(218, 41%, 20%) 75%, hsl(218, 41%, 19%) 80%, transparent 100%);
  padding: 0;
`;

const GridContainer = styled(Grid)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  padding: 10px;
`;

const LoginContainer = styled("div")`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  marginTop: 20px;
`;

const LoginPaper = styled(Paper)`
  padding: 32px;
  width: 100%;
  max-width: 400px;
  background-color: #f2f2f2;
  animation: ${fadeIn} 1s ease-in-out;
`;

const Logo = styled("img")`
  display: block;
  margin: 0 auto 20px;
  width: 100px;
  height: 80px;
  animation: ${fadeIn} 1s ease-in-out;
`;

const LoginHeader = styled(Typography)`
  text-align: center;
  margin-bottom: 24px;
  animation: ${fadeIn} 1s ease-in-out;
  word-spacing: 0.3em;
  font-weight: bold;
`;

const CustomForm = styled("form")`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled("div")`
  margin-bottom: 16px;
`;

const FormControl = styled(TextField)`
  width: 100%;
`;

const ErrorMessage = styled(Typography)`
  color: red;
  margin-bottom: 16px;
`;

const BtnPrimary = styled(Button)`
  margin-bottom: 16px;
`;

const ForgotPasswordLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 16px;
`;

const StyledPaper = styled(Paper)(({ delay }) => ({
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#f7f7f7",
  transition: "transform 0.5s ease, box-shadow 0.3s ease, background-color 0.3s ease",
  animation: `popIn 2.5s ease ${delay}s`,
  animationFillMode: "forwards",
  opacity: 0,
  transform: "scale(0)",
  padding: "10px",

  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0 8px 25px rgba(0, 0, 0, 0.3)",
    backgroundImage: "linear-gradient(88.8deg, rgb(239, 171, 245) 13.4%, rgb(196, 181, 249) 76.3%)",
  },

  "@keyframes popIn": {
    "0%": { opacity: 0, transform: "scale(0)" },
    "100%": { opacity: 1, transform: "scale(1)" },
  },
}));

function Login({ onLogin }) {
  const [institutecode, setInstitutecode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const hasAnimated = useRef(false); // Ref to track if the animation has run

  const handlePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const loginUser = async (institutecode, email, password) => {
    return axios.post(
      `https://pjsofttech.in:20443/institutelogin?institutecode=${institutecode}&emailaddress=${email}&password=${password}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(""); // Clear previous error messages
    try {
      const response = await loginUser(institutecode, email, password);

      if (response.status === 200) {
        console.log("Login successful!", response.data);
        sessionStorage.setItem("institutecode", institutecode);
        sessionStorage.setItem("email", email);
        onLogin();
        navigate("/layout/combineDash");
      } else if (response.status === 401) {
        if (response.data === "Incorrect password") {
          setError("Incorrect password");
        } else if (response.data === "Account expired") {
          setError("Account expired");
        } else {
          setError("Unauthorized access");
        }
      } else if (response.status === 404) {
        if (response.data === "Incorrect institutecode") {
          setError("Incorrect Organization Key");
        } else if (response.data === "Admin not found") {
          setError("Admin not found");
        }
      } else {
        throw new Error("Unexpected response from the server");
      }
    } catch (error) {
      console.error(
        "Error logging in:",
        error.response ? error.response.data : error.message
      );
      setError("An error occurred during login. Please try again later.");
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleCreateAccount = () => {
    navigate("/create-account");
  };

  const circles = [
    { icon: <AdbIcon />, link: "https://pjsofttech.in/employee-app/", label: "Employee App" },
    { icon: <HomeIcon />, link: "http://pjsofttech.in", label: "Admin" },
    { icon: <PersonIcon />, link: "http://pjsofttech.in/employee", label: "Employee" },
    { icon: <SchoolIcon />, link: "http://pjsofttech.in/student", label: "Student" },
    { icon: <SupervisorAccountIcon />, link: "/loginPage", label: "Principal" },
    { icon: <SupervisorAccountIcon />, link: "/loginPage", label: "HOD" },
    { icon: <AdbIcon />, link: "https://pjsofttech.in/Academy-Teacher-App/", label: "Teacher App" },
    { icon: <HowToRegIcon />, link: "/loginPage", label: "Parent" },
    { icon: <AutoStoriesIcon />, link: "https://mahastudy.in", label: "EBook" },
    { icon: <BarChartIcon />, link: "https://pjsofttech.in/esales", label: "Sales" },
    { icon: <MenuBookIcon />, link: "/loginPage", label: "Accountant" },
    { icon: <DriveEtaIcon />, link: "/loginPage", label: "Driver" },
    { icon: <WorkIcon />, link: "https://pjsofttech.in/manager", label: "Manager" },
    { icon: <BadgeIcon />, link: "https://pjsofttech.in/jobapp", label: "Job App" },
    { icon: <BusinessIcon />, link: "https://pjsofttech.in/projectuser", label: "Project" },
    { icon: <AnalyticsIcon />, link: "https://pjsofttech.in/project", label: "Project Admin" },
  ];

  useEffect(() => {
    // Mark the animation as run
    hasAnimated.current = true;
  }, []);

  return (
    <>
      <Grid item>
        <Typography
          variant="h3"
          component="div"
          align="center"
          gutterBottom
          sx={{
            fontSize: "40px",
            fontWeight: "bold",
            color: "white",
            marginTop: "10px",
            marginBottom: "-60px",
            background: "white",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            textTransform: "uppercase",
            letterSpacing: "2px",
            wordSpacing: "0.3em",
          }}
        >
          <span className="typing-effect">PJSOFTTECH Software</span>
        </Typography>
      </Grid>
      <BackgroundContainer>
        <ContentWrapper>
          <GridContainer container alignItems="center" style={{ marginTop: "90px" }}>
            {circles.map(({ icon, link, label }, index) => (
              <Grid item lg={3} md={4} sm={6} xs={12} key={index} marginBottom={7}>
                <StyledPaper
                  delay={hasAnimated.current ? Math.random() * 0.5 + index * 0.1 : 0}
                  onClick={() => window.open(link, "_blank")}
                >
                  <div>{icon}</div>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    {label}
                  </Typography>
                </StyledPaper>
              </Grid>
            ))}
          </GridContainer>

          <LoginContainer style={{ marginTop: "40px" }}>
            <LoginPaper elevation={6}>
              <Logo src={logo} alt="Logo" />
              <LoginHeader variant="h5" whiteSpace={"nowrap"} >
                PJSOFTTECH LOGIN
              </LoginHeader>
              <CustomForm onSubmit={handleSubmit} className="textField-root">
                <FormGroup>
                  <FormControl
                    label="Organization ID"
                    type="text"
                    value={institutecode}
                    onChange={(e) => setInstitutecode(e.target.value)}
                    placeholder="Enter Organization Key"
                    variant="outlined"
                    fullWidth
                  />
                </FormGroup>
                <FormGroup>
                  <FormControl
                    label="Email address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email"
                    variant="outlined"
                    fullWidth
                  />
                </FormGroup>
                <FormGroup>
                  <FormControl
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePasswordVisibility}
                            edge="end"
                            aria-label="toggle password visibility"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormGroup>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <BtnPrimary variant="contained" color="primary" type="submit">
                  Login
                </BtnPrimary>
                <Link
                  href="#"
                  onClick={handleCreateAccount}
                  underline="hover"
                  sx={{
                    display: "block",
                    textAlign: "center",
                    color: "black",
                    "&:hover": { color: "#0056B3" },
                    marginBottom: "5px",
                    variant: "h4",
                  }}
                >
                  Create Account
                </Link>
                <ForgotPasswordLink
                  href="#"
                  onClick={handleForgotPassword}
                  underline="hover"
                  sx={{ color: "black", "&:hover": { color: "#0056B3" } }}
                >
                  Forgot Password?
                </ForgotPasswordLink>
              </CustomForm>
            </LoginPaper>
          </LoginContainer>
        </ContentWrapper>
      </BackgroundContainer>
    </>
  );
}

export default Login;
