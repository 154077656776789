import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  IconButton,
  Grid,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";

function Category() {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [categoryName, setCategoryName] = useState("");

  const getInstituteCode = () => sessionStorage.getItem("institutecode");

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:14443/getAllCategories?institutecode=${getInstituteCode()}`
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to fetch categories. Please try again.",
      });
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleSubmit = async () => {
    try {
      if (currentCategory) {
        // Update category
        await axios.put(
          `https://pjsofttech.in:14443/updateCategory/${currentCategory.id}?institutecode=${getInstituteCode()}`,
          { category: categoryName }
        );
        Swal.fire("Updated!", "Category has been updated.", "success");
      } else {
        // Add new category
        await axios.post(
          `https://pjsofttech.in:14443/createCategory?institutecode=${getInstituteCode()}`,
          { category: categoryName }
        );
        Swal.fire("Added!", "Category has been added.", "success");
      }

      fetchCategories();
      handleClose();
    } catch (error) {
      console.error("Failed to save category:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to save category. Please try again.",
      });
    }
  };

  const handleDelete = async (id) => {
    const confirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirm.isConfirmed) {
      try {
        await axios.delete(
          `https://pjsofttech.in:14443/deleteCategory/${id}?institutecode=${getInstituteCode()}`
        );
        Swal.fire("Deleted!", "Category has been deleted.", "success");
        fetchCategories();
      } catch (error) {
        console.error("Failed to delete category:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to delete category. Please try again.",
        });
      }
    }
  };

  const handleEdit = (category) => {
    setCurrentCategory(category);
    setCategoryName(category.category);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentCategory(null);
    setCategoryName("");
  };

  return (
    <Grid container spacing={3} padding={2}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Add Category
        </Button>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper} className="table-root">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Category Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((category, index) => (
                <TableRow key={category.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{category.category}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(category)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(category.id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Add/Edit Dialog */}
      <Dialog open={open} onClose={handleClose} className="textField-root">
        <DialogTitle color={'black'}>
          {currentCategory ? "Edit Category" : "Add Category"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            fullWidth
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={!categoryName.trim()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default Category;
