import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Box
} from "@mui/material";
import { jsPDF } from "jspdf";

export default function BonafideCertificate({ open, onClose, studentId }) {
  const [institute, setInstitute] = useState(null);
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(true);
  const getInstituteCode = () => sessionStorage.getItem("institutecode");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!studentId) {
          console.error("No student ID provided.");
          return;
        }

        const instituteCode = getInstituteCode();
        if (!instituteCode) {
          console.error("No institutecode found in sessionStorage");
          return;
        }

        // Fetch Institute Details
        const instResponse = await axios.get(
          `https://pjsofttech.in:20443/findInstitutesby/Institutecode?institutecode=${instituteCode}`
        );
        setInstitute(instResponse.data);

        // Fetch the specific student by ID
        const studentResponse = await axios.get(
          `https://pjsofttech.in:9443/getStudentById/${studentId}`
        );
        setStudent(studentResponse.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (open && studentId) {
      fetchData();
    }
  }, [open, studentId]);

  const downloadPdf = () => {
    if (!institute || !student) {
      console.error("Missing data for PDF generation");
      return;
    }
  
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const marginX = 15; // Set left margin
  
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);
    doc.text(institute.institutename, pageWidth / 2, 20, { align: "center" });
  
    doc.setFontSize(12);
    doc.text(
      `Address: ${institute.address}, ${institute.city}, ${institute.state}`,
      pageWidth / 2,
      30,
      { align: "center" }
    );
    doc.text(
      `Phone: ${institute.phonenumber} | Email: ${institute.emailaddress}`,
      pageWidth / 2,
      40,
      { align: "center" }
    );
  
    doc.setLineWidth(0.5);
    doc.line(10, 45, pageWidth - 10, 45);
  
    doc.setFontSize(16);
    doc.text("BONAFIDE CERTIFICATE", pageWidth / 2, 60, { align: "center" });
  
    doc.setFontSize(12);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, pageWidth - 40, 70);
  
    doc.setFont("helvetica", "normal");
  
    // ✅ Properly formatted paragraph layout
    const paragraph = `This is to certify that ${student.firstName} ${student.middleName} ${student.surname}, son/daughter of ${student.fathersName} and ${student.motherName}, is a bonafide student of our institution ${institute.institutename}. She has been studying in ${student.standard} Standard/Class in ${student.mediumname} medium during the academic year ${student.year}.`;
  
    const wrappedText = doc.splitTextToSize(paragraph, pageWidth - 2 * marginX);
    doc.text(wrappedText, marginX, 85);
  
    const secondParagraph = `He/She has been enrolled in our institution since ${student.dateOfRegistration}, and her enrollment number is ${student.enrollmentNumber}.`;
    const wrappedText2 = doc.splitTextToSize(secondParagraph, pageWidth - 2 * marginX);
    doc.text(wrappedText2, marginX, 110);
  
    doc.text("This certificate is issued upon her request for official use.", marginX, 130);
  
    // ✅ Signature and Institution Name & Seal
    doc.text("Signature: ___________________", marginX, 160);
    doc.text("Principal", pageWidth - 40, 160);
    doc.text(`[${institute.institutename} & Seal]`, marginX, 180);
  
    doc.save(`Bonafide_Certificate_${student.firstName}.pdf`);
  };
  


  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle color={'black'}>Bonafide Certificate</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ p: 2 }}>
            <Box sx={{textAlign:"center"}}>
            <Typography variant="h5" fontWeight={'bold'}>{institute?.institutename}</Typography>
            <Typography variant="h7" fontWeight={'bold'}>Address: {institute.state}, {institute.city}, {institute.state}</Typography>
            <br />
            <Typography variant="h7" fontWeight={'bold'}>Phone: {institute.phonenumber} | Email: {institute.emailaddress}</Typography>
            <hr style={{border: "2px solid black", color:'black'}}/>
            <Typography variant="h6" fontWeight={'bold'}>BONAFIDE CERTIFICATE</Typography>
            </Box>
            <br />
            <Typography variant="h7" fontWeight={'bold'} marginLeft={80}>Date: {new Date().toLocaleDateString()}</Typography>
            <Typography variant="body1">
              This is to certify that {student?.firstName} {student.middleName} {student?.surname}, son/daughter of {student.fathersName} and {student.motherName},  is a bonafide student of our institution {institute.institutename}.
              He/She has been studying in {student.standard} Standard/Class in {student.mediumname} medium during the academic year {student.year}.
            </Typography>
            <br />
            <Typography variant="body1">He/She has been enrolled in our institution since {student.dateOfRegistration}, and her enrollment number is {student.enrollmentNumber}.</Typography>
            <br />
            <Typography variant="body1">This certificate is issued upon her request for official use.</Typography>
            <Box display={"flex"} sx={{ marginTop: 15 }}>
            <Typography variant="body1" >
              Signature: ___________________ {/* ✅ Signature Placeholder */}
            </Typography>
            <Typography variant="body1" marginLeft={40}>Principle:</Typography>
            </Box>
            <Typography variant="body2" sx={{ marginTop: 10, fontWeight: "bold" }}>
              [{institute?.institutename} & Seal] {/* ✅ Institution Name & Seal */}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={downloadPdf} variant="contained" color="primary">Download PDF</Button>
        <Button onClick={onClose} color="secondary">Close</Button>
      </DialogActions>
    </Dialog>
  );
}
