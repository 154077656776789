import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  Checkbox,
} from "@mui/material";
import { Edit, Delete, Info } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import axios from "axios";
import Swal from "sweetalert2"; // Import Swal

const ClassRoom = () => {
  const [classRooms, setClassRooms] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentClassRoom, setCurrentClassRoom] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false); // For Info Dialog
  const [infoData, setInfoData] = useState([]); // Info dialog data
  const [admissions, setAdmissions] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [filteredClassRooms, setFilteredClassRooms] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [formData, setFormData] = useState({
    batchName: "",
    batchStartTime: "",
    batchEndTime: "",
    academicYear: "",
    teacher: [],
    medium: "",
    course: "",
  });
  const [teachers, setTeachers] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [courses, setCourses] = useState([]);

  const [academicYears, setAcademicYears] = useState([]);
  const institutecode = sessionStorage.getItem("institutecode");

  // Fetch classrooms from the API
  const fetchClassRooms = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:13443/by-institutecode?institutecode=${institutecode}`
      );
      setFilteredClassRooms(response.data);
    } catch (error) {
      console.error("Error fetching classrooms:", error);
    }
  };

  // Fetch mediums and courses based on institutecode
  const fetchMediumsAndCourses = async () => {
    try {
      const mediumResponse = await axios.get(
        `https://pjsofttech.in:13443/MediumByInstitutecode?institutecode=${institutecode}`
      );
      setMediums(mediumResponse.data);

      const courseResponse = await axios.get(
        `https://pjsofttech.in:13443/getAllCourse?institutecode=${institutecode}`
      );
      setCourses(courseResponse.data);
      const teacherResponse = await axios.get(
        `https://pjsofttech.in:13443/GetAllTeacher?institutecode=${institutecode}`
      );
      setTeachers(teacherResponse.data);
    } catch (error) {
      console.error("Error fetching mediums and courses:", error);
    }
  };

  useEffect(() => {
    fetchClassRooms();
    fetchMediumsAndCourses();
  }, [institutecode]);

  useEffect(() => {
    const generateAcademicYears = () => {
      const currentYear = new Date().getFullYear();
      const range = 10; // Number of years forward and backward
      const years = [];

      for (let i = currentYear - range; i <= currentYear + range; i++) {
        years.push(`${i}-${i + 1}`);
      }

      setAcademicYears(years);
    };

    generateAcademicYears();
  }, []);

   // Handle search input change
   const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = classRooms.filter((room) =>
      room.batchName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredClassRooms(filtered);
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFetchAdmissions = async (classRoom) => {
    setSelectedClassId(classRoom.classId); // Set the correct classId
    const { course, medium, academicYear } = classRoom; // Get course, medium, academic year from selected row
    
    try {
      // Make API request with course and medium values (not IDs)
      const response = await axios.get(
        `https://pjsofttech.in:13443/filter`, 
        {
          params: {
            institutecode,
            course: course.cname, // Pass course name
            medium: medium.mediumName, // Pass medium name
            academicYear
          }
        }
      );
      setAdmissions(response.data);
      setDialogOpen(true); // Open the dialog
    } catch (error) {
      console.error("Error fetching admissions:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to Fetch Admissions",
        text: "Could not retrieve admissions data. Please try again later.",
      });
    }
  };
  
  

  const handleAddStudent = async (selectedIds) => {
    if (!selectedClassId || selectedIds.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Missing Information",
        text: "Please select a class and at least one student before adding.",
      });
      return;
    }
  
    // Format the IDs as "1,2,3"
    const formattedIds = selectedIds.join(",");
  
    try {
      await axios.post(
        `https://pjsofttech.in:13443/AssignadmissionToClassroom`,
        null, // No payload, passing parameters in the URL
        {
          params: {
            classId: selectedClassId,
            ids: formattedIds,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Students Added",
        text: "The selected students have been successfully added to the classroom.",
      });
      setDialogOpen(false); // Close the dialog
    } catch (error) {
      console.error("Error adding students:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to Add Students",
        text: "There was an issue while adding the students to the classroom. Please try again.",
      });
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedStudentIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((studentId) => studentId !== id)
        : [...prevSelected, id]
    );
  };
  
  // Open dialog for create or edit
 const handleOpen = (classRoom = null) => {
  setCurrentClassRoom(classRoom);

  // Prefill the form data when editing
  if (classRoom) {
    setFormData({
      batchName: classRoom.batchName || "",
      batchStartTime: classRoom.batchStartTime || "",
      batchEndTime: classRoom.batchEndTime || "",
      academicYear: classRoom.academicYear || "",
      medium: classRoom.medium?.mid || "", // Correctly map medium ID
      course: classRoom.course?.id || "", // Correctly map course ID
      teachers: classRoom.teacherDTOs?.map((teacher) => teacher.tid) || [], // Correctly map teacher IDs
    });
  } else {
    // Reset form data for creating a new classroom
    setFormData({
      batchName: "",
      batchStartTime: "",
      batchEndTime: "",
      academicYear: "",
      medium: "",
      course: "",
      teachers: [],
    });
  }

  setOpen(true); // Open the dialog
};


  // Close dialog
  const handleClose = () => {
    setOpen(false);
  };

  const handleInfoClose = () => setInfoOpen(false); // Close info dialog

  const handleCreateClassroom  = async () => {
    try {
      const teacherIds = formData.teachers.join(",");
      const payload = {
        batchName: formData.batchName,
        batchStartTime: formData.batchStartTime,
        batchEndTime: formData.batchEndTime,
        academicYear: formData.academicYear,
        medium: { mid: formData.medium }, // Pass only the medium id
        course: { id: formData.course }, // Pass only the course id
      };
  
      // API call to create a classroom
      await axios.post(
        `https://pjsofttech.in:13443/create?institutecode=${institutecode}&teacherIds=${teacherIds}`,
        payload
      );
  
      // Refresh classroom list and close dialog
      fetchClassRooms();
      handleClose();
  
      // Success notification
      Swal.fire({
        icon: "success",
        title: "ClassRoom Added",
        text: "The classroom was added successfully.",
      });
    } catch (error) {
      console.error("Error creating classroom:", error);
  
      // Error notification
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue while creating the classroom.",
      });
    }
  };

  const handleUpdateClassroom = async () => {
    try {
      const teacherIds = formData.teachers; // Ensure it's an array of IDs
      const payload = {
        updatedClassRoom: {
          batchName: formData.batchName,
          batchStartTime: formData.batchStartTime,
          batchEndTime: formData.batchEndTime,
          academicYear: formData.academicYear,
          institutecode,
          medium: { mid: formData.medium }, // Use `mid` for medium
          course: { id: formData.course }, // Use `id` for course
        },
        teacherIds // Pass as an array of IDs
      };
  
      await axios.put(
        `https://pjsofttech.in:13443/updateClassroom/${currentClassRoom.classId}`,
        payload
      );
  
      fetchClassRooms();
      handleClose();
      Swal.fire({
        icon: "success",
        title: "ClassRoom Updated",
        text: "The classroom was updated successfully.",
      });
    } catch (error) {
      console.error("Error updating classroom:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue while updating the classroom.",
      });
    }
  };
  
  // Updated handleSubmit to decide between create or update
const handleSubmit = () => {
  if (currentClassRoom) {
    handleUpdateClassroom();
  } else {
    handleCreateClassroom();
  }
};

  const handleMediumChange = (e) => {
    setFormData({ ...formData, medium: e.target.value });
  };

  const handleCourseChange = (e) => {
    setFormData({ ...formData, course: e.target.value });
  };

  // Handle teacher change
  const handleTeacherChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      teachers: typeof value === "string" ? value.split(",") : value, // Ensure array format
    });
  };

  // Handle delete
  const handleDelete = async (classId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`https://pjsofttech.in:13443/delete/${classId}`);
        fetchClassRooms();
        Swal.fire("Deleted!", "The classroom has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting classroom:", error);
        Swal.fire(
          "Error!",
          "There was an issue while deleting the classroom.",
          "error"
        );
      }
    }
  };
  // Handle info icon click
  const handleInfoClick = async (classRoomId) => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:13443/getclassrooms/admissions?classRoomId=${classRoomId}&institutecode=${institutecode}`
      );
      setInfoData(response.data);
      setInfoOpen(true);
    } catch (error) {
      console.error("Error fetching classroom admissions:", error);
    }
  };

  return (
    <div >
     <Paper elevation={3} sx={{padding: "0 20px 20px 20px", borderRadius: "8px"}}>
      <Grid container spacing={2} className="textField-root">
        <Typography
          variant="h6"
          gutterBottom
          sx={{ marginTop: 3, whiteSpace: "nowrap" }}
        >
          Total ClassRooms: {classRooms.length}
        </Typography>{" "}
        <Grid item xs={12} sm={3}>
          <TextField
            label="Search by Batch Name"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen()}
          >
            Add ClassRoom
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{ marginTop: 3 }}
        className="table-root"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Batch Name</TableCell>
              <TableCell>Batch Start Time</TableCell>
              <TableCell>Batch End Time</TableCell>
              <TableCell>Academic Year</TableCell>
              <TableCell>Medium</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Teacher</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredClassRooms.map((classRoom, index) => {
    const mediumName =
      mediums.find((medium) => medium.mid === classRoom.medium?.mid)
        ?.mediumName || "-";
    const courseName =
      courses.find((course) => course.id === classRoom.course?.id)?.cname || "-";
    const teacherName =
      classRoom.teacherDTOs && classRoom.teacherDTOs.length > 0
        ? classRoom.teacherDTOs
            .map((teacher) => teacher.teacherName)
            .join(", ")
        : "-";
              return (
                <TableRow key={classRoom.classId}>
                  <TableCell>{classRoom.classId}</TableCell>
                  <TableCell>{classRoom.batchName}</TableCell>
                  <TableCell>{classRoom.batchStartTime}</TableCell>
                  <TableCell>{classRoom.batchEndTime}</TableCell>
                  <TableCell>{classRoom.academicYear}</TableCell>
                  <TableCell>{mediumName}</TableCell>
                  <TableCell>{courseName}</TableCell>
                  <TableCell>{teacherName}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpen(classRoom)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(classRoom.classId)}
                    >
                      <Delete />
                    </IconButton>
                    <IconButton
                      color="success"
                      onClick={() => handleFetchAdmissions(classRoom)}
                    >
                      <AddCircleIcon />
                    </IconButton>

                    <IconButton
                      color="primary"
                      onClick={() => handleInfoClick(classRoom.classId)}
                    >
                      <Info />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      </Paper>
      {/* Dialog for create/edit */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        className="textField-root"
      >
        <DialogTitle color={"black"}>
          {currentClassRoom ? "Edit ClassRoom" : "Add ClassRoom"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Batch Name"
                name="batchName"
                value={formData.batchName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                fullWidth
                label="Academic Year"
                name="academicYear"
                value={formData.academicYear}
                onChange={handleChange}
              >
                {academicYears.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Batch Start Time"
                name="batchStartTime"
                value={formData.batchStartTime}
                onChange={handleChange}
                type="time"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Batch End Time"
                name="batchEndTime"
                value={formData.batchEndTime}
                onChange={handleChange}
                type="time"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                fullWidth
                label="Medium"
                name="medium"
                value={formData.medium}
                onChange={handleMediumChange}
              >
                {mediums.map((medium) => (
                  <MenuItem key={medium.mid} value={medium.mid}>
                    {medium.mediumName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                fullWidth
                label="Course"
                name="course"
                value={formData.course}
                onChange={handleCourseChange}
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.cname}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                fullWidth
                label="Teacher(s)"
                name="teachers"
                value={formData.teachers || []}
                onChange={handleTeacherChange}
                SelectProps={{
                  multiple: true, // Enable multi-selection
                }}
              >
                {teachers.map((teacher) => (
                  <MenuItem key={teacher.tid} value={teacher.tid}>
                    {teacher.teacherName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
      onClick={currentClassRoom ? handleUpdateClassroom : handleCreateClassroom}
      variant="contained"
      color="primary"
    >
      Save
    </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog to display table */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle color={"black"}>Admissions</DialogTitle>
        <DialogContent>
          <Table className="table-root">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Course</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Select</TableCell>
                {/* <TableCell>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {admissions.map((admission) => (
                <TableRow key={admission.id}>
                  <TableCell>{admission.id}</TableCell>
                  <TableCell>{admission.name}</TableCell>
                  <TableCell>{admission.mobile1}</TableCell>
                  <TableCell>{admission.courses}</TableCell>
                  <TableCell>{admission.duration}</TableCell>
                  <TableCell>{admission.status}</TableCell>
                  <TableCell>
  <Checkbox
    checked={selectedStudentIds.includes(admission.id)}
    onChange={() => handleCheckboxChange(admission.id)}
  />
</TableCell>
                  {/* <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddStudent(admission.id)}
                    >
                      Add Student
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Close
          </Button>
          <Button
  variant="contained"
  color="primary"
  onClick={() => handleAddStudent(selectedStudentIds)}
  disabled={selectedStudentIds.length === 0}
>
  Add Selected Students
</Button>

        </DialogActions>
      </Dialog>

      {/* Info Dialog */}
      <Dialog open={infoOpen} onClose={handleInfoClose} fullWidth maxWidth="md">
        <DialogTitle color={"black"}>Classroom Admissions</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} className="table-root">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Roll No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Courses</TableCell>
                  <TableCell>Total Fees</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {infoData.map((data) => (
                  <TableRow key={data.id}>
                    <TableCell>{data.rollNo}</TableCell>
                    <TableCell>{data.name}</TableCell>
                    <TableCell>{data.mobile1}</TableCell>
                    <TableCell>{data.email}</TableCell>
                    <TableCell>{data.courses}</TableCell>
                    <TableCell>{data.totalFees}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInfoClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClassRoom;
