import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable"; // Import autoTable for structured PDF tables

const PreviewForm = ({ open, onClose, formData, rulesData }) => {
  
  // Function to generate and download PDF
  const handleDownloadPDF = () => {
    const doc = new jsPDF("p", "mm", "a4");
    doc.setFontSize(12);
    doc.text("Admission Form Preview", 105, 15, { align: "center" });

    let yOffset = 30;

    // **Handling Student Photo in PDF**
    if (formData.studentPhoto instanceof File) {
      const img = URL.createObjectURL(formData.studentPhoto);
      doc.addImage(img, "JPEG", 85, yOffset, 40, 40);
      yOffset += 50; 
    }

    // **List of fields to display in order**
    const fieldsOrder = [
      "name", "email", "mobile1", "mobile2", "gender", "dob", "date", 
      "courses", "duration", "guideName", "sourceBy", "medium", "academicYear", 
      "currentAddress", "permanentAddress", "totalFees", "dueDate", "paymentMode", 
      "paidFees", "paymentMethod", "pendingFees", "transactionid", "remark"
    ];

    // **Filter out empty fields**
    const filteredFields = fieldsOrder
      .filter((key) => formData[key] && formData[key] !== "")
      .map((key) => [key.replace(/([A-Z])/g, ' $1').toUpperCase(), formData[key]]);

    // **Use autoTable for structured layout**
    doc.autoTable({
      startY: yOffset,
      head: [["Field", "Value"]],
      body: filteredFields,
      styles: { fontSize: 10 },
      columnStyles: { 0: { fontStyle: "bold" } },
    });

    // **Rules & Regulations Section**
    if (rulesData && rulesData.length > 0) {
      yOffset = doc.autoTable.previous.finalY + 10; // Move below table
      doc.text("Rules and Regulations:", 20, yOffset);
      yOffset += 5;
      rulesData.forEach((rule, index) => {
        doc.text(`${index + 1}. ${rule.rulesAndRegulations}`, 20, yOffset);
        yOffset += 5;
      });
    }

    doc.save("AdmissionFormPreview.pdf");
  };

  // **Fields to display in the preview**
  const fieldsOrder = [
    "name", "email", "mobile1", "mobile2", "gender", "dob", "date", 
    "courses", "duration", "guideName", "sourceBy", "medium", "academicYear", 
    "currentAddress", "permanentAddress", "totalFees", "dueDate", "paymentMode", 
    "paidFees", "paymentMethod", "pendingFees", "transactionid", "remark"
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
        Preview Admission Form
      </DialogTitle>
      <DialogContent>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px", justifyContent: "space-around" }}>
          
          {/* **Student Photo Preview** */}
          {formData.studentPhoto instanceof File && (
            <img
              src={URL.createObjectURL(formData.studentPhoto)}
              alt="Uploaded Preview"
              style={{
                width: "150px",
                height: "150px",
                objectFit: "cover",
                borderRadius: "50%",
                marginBottom: "20px",
              }}
            />
          )}
          <hr />

          {/* **Form Data Preview (excluding empty fields)** */}
          <Grid container spacing={2}>
            {fieldsOrder.map((key) =>
              formData[key] && formData[key] !== "" ? (
                <Grid item xs={12} sm={4} key={key}>
                  <Typography variant="body1">
                    <strong>{key.replace(/([A-Z])/g, ' $1').toUpperCase()}:</strong> {formData[key]}
                  </Typography>
                </Grid>
              ) : null
            )}
          </Grid>
          <hr />

          {/* **Rules & Regulations** */}
          {rulesData && rulesData.length > 0 && (
            <>
              <Typography variant="h6" style={{ marginTop: "20px", fontWeight: "bold" }}>
                Rules and Regulations
              </Typography>
              {rulesData.map((rule, index) => (
                <Typography key={rule.id}>{index + 1}. {rule.rulesAndRegulations}</Typography>
              ))}
            </>
          )}
        </Paper>

        {/* **Download PDF Button** */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadPDF}
          style={{ display: "block", margin: "10px auto" }}
        >
          Download PDF
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewForm;
