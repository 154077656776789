import React, { useState, useEffect } from "react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Add, Remove } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom"; 
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import AddHomeRoundedIcon from "@mui/icons-material/AddHomeRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WorkOffIcon from "@mui/icons-material/WorkOff";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LanguageIcon from "@mui/icons-material/Language";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HelpIcon from "@mui/icons-material/Help";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

const SidebarContainer = styled(Drawer)(({ open }) => ({
  width: open ? 255 : 57,
  flexShrink: 0,
  whiteSpace: "nowrap",
  backgroundColor: "#3498DB",

  "& .MuiDrawer-paper": {
    width: open ? 255 : 57,
    backgroundColor: "#3498DB",
    transition: "all 1s cubic-bezier(0.25,0.1,0.25,1)",
    overflowX: "hidden",
    marginTop: "60px",
  },
}));

const SidebarItem = styled(ListItem)(({ active }) => ({
  color: active ? "black" : "white",
  backgroundColor: active ? "white" : "transparent",
  borderRadius: "25px",
  margin: "0px 0px",
  fontSize: "12px",
  fontFamily: "'Poppins', sans-serif",
  "&:hover": {
    backgroundColor: active ? "white" : "#2980B9",
  },
}));

const ChildItem = styled(ListItem)(({ active }) => ({
  paddingLeft: "15px",
  fontSize: "11px",
  borderRadius: "25px",
  fontFamily: "'Poppins', sans-serif",
  margin: "0px 0px",
  backgroundColor: active ? "#f0f0f0" : "transparent",
  "&:hover": {
    backgroundColor: active ? "#e0e0e0" : "#2980B9",
  },
}));

export default function Sidebar() {
  const [systemValues, setSystemValues] = useState(null);
  const institutecode = () => sessionStorage.getItem("institutecode");
  const [open, setOpen] = useState(true);
  const [expanded, setExpanded] = useState({ employee: false, student: false });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://pjsofttech.in:20443/getSystemValueByInstitutecode?institutecode=${institutecode()}`
        );
        const data = await response.json();
        setSystemValues(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const toggleExpand = (key) => {
    setExpanded((prev) => {
      if (prev[key]) {
        return { ...prev, [key]: false };
      }
      return {
        employee: key === "employee" ? true : false,
        student: key === "student" ? true : false,
      };
    });
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setOpen(false); 
      setExpanded({ employee: false, student: false }); 
    }, 800);
  };

  useEffect(() => {
    if (open) {
      if (location.pathname.includes("/employee")) {
        setExpanded((prev) => ({ ...prev, employee: true }));
      } else if (location.pathname.includes("/student")) {
        setExpanded((prev) => ({ ...prev, student: true }));
      }
    }
  }, [open, location.pathname]);

  const NAVIGATION = [
    { title: "Main Dashboard", segment: "/layout/combineDash", icon: <DashboardIcon /> },
    { title: "Enquiry System",
      //  show: systemValues?.enquirymanagementsystem, 
       segment: "/layout/Enquiry-manager", icon: <PersonSearchRoundedIcon /> },
    { title: "Admission System", 
      // show: systemValues?.admissionmanagementsystem, 
      segment: "/layout/Admission-manager", icon: <AddHomeRoundedIcon /> },
    { title: "Income & Expense",
      //  show: systemValues?.incomeandexpense, 
       segment: "/layout/income-expense-manager", icon: <CurrencyRupeeRoundedIcon /> },
    {
      title: "Employee System",
      segment: "/employee",
      // show: systemValues?.employeemanagementsystem,
      icon: <PeopleIcon />,
      expandable: true,
      key: "employee",
      children: [
        { title: "Employee", segment: "/layout/employee-manager", icon: <PersonIcon /> },
        { title: "Attendance", segment: "/layout/attendance-manager", icon: <SupervisorAccountRoundedIcon /> },
        { title: "Leave", segment: "/layout/leave-manager", icon: <WorkOffIcon /> },
        { title: "Salary", segment: "/layout/employee-salary-manager", icon: <CurrencyRupeeRoundedIcon /> },
        { title: "Memo", segment: "/layout/Memo-manager", icon: <NoteAltIcon /> },
        { title: "Notice", segment: "/layout/AddNotice", icon: <StickyNote2Icon /> },
      ],
    },
    {
      title: "Student System",
      segment: "/student",
      // show: systemValues?.studentmanagementsystem,
      icon: <EmojiPeopleRoundedIcon />,
      expandable: true,
      key: "student",
      children: [
        { title: "Student", segment: "/layout/student-manager", icon: <DashboardIcon /> },
        { title: "Fees System", segment: "/layout/fees-manager", icon: <CurrencyRupeeRoundedIcon /> },
      ],
    },
    // { title: "Classroom System", show: systemValues?.classroommanagementsystem, segment: "/layout/classroom", icon: <SchoolRoundedIcon /> },
    { title: "Shipment System", 
      // show: systemValues?.shipmentsystem, 
      segment: "/layout/shipment-manager", icon: <LocalShippingIcon /> },
    { title: "Payroll System", 
      // show: systemValues?.payrollsystem, 
      segment: "/layout/payroll-manager", icon: <AccountBalanceWalletIcon /> },
    { title: "Ebook System", 
      // show: systemValues?.ebooksystem, 
      segment: "/layout/Ebook-manager", icon: <MenuBookIcon /> },
    { title: "Website System", 
      // show: systemValues?.websitesystem, 
      segment: "/layout/website-manager", icon: <LanguageIcon /> },
    { title: "Add Sub-Admin", segment: "/layout/subadmin", icon: <AdminPanelSettingsIcon /> },
    { title: "Help Desk", segment: "/layout/helpDesk", icon: <HelpIcon /> },
    { title: "Billing Section", segment: "/layout/bill", icon: <PriceCheckIcon /> },
    { title: "Settings", segment: "/layout/Settings", icon: <SettingsRoundedIcon /> },
  ];

  return (
    <SidebarContainer
      variant="permanent"
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={handleMouseLeave}
    >
      <List>
      {NAVIGATION.filter(item => item.show !== false).map((item) => (
          <React.Fragment key={item.segment}>
            <SidebarItem
              button
              active={location.pathname === item.segment}
              onClick={item.expandable ? () => toggleExpand(item.key) : () => navigate(item.segment)}
            >
              <ListItemIcon sx={{ color: location.pathname === item.segment ? "black" : "white", minWidth: 40 }}>
                {item.icon}
              </ListItemIcon>
              {open && <ListItemText primary={item.title} />}
              {item.expandable && (expanded[item.key] ? <Remove sx={{ color: "red" }} /> : <Add sx={{ color: "white" }} />)}
            </SidebarItem>

            {item.expandable && (
              <Collapse in={expanded[item.key]} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {item.children.map((child) => (
                    <ChildItem
                      button
                      key={child.segment}
                      active={location.pathname === child.segment}
                      onClick={() => navigate(child.segment)}
                    >
                      <ListItemIcon sx={{ color: location.pathname === child.segment ? "black" : "white", minWidth: 30 }}>
                        {child.icon}
                      </ListItemIcon>
                      {open && <ListItemText primary={child.title} />}
                    </ChildItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </SidebarContainer>
  );
}
