import React, { useEffect, useState } from "react";
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Button, IconButton, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow,
  Paper
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material"; // Material Icons
import axios from "axios";
import Swal from "sweetalert2"; // SweetAlert for better alerts

const TeacherManagement = () => {
  const [teachers, setTeachers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({ teacherName: "", email: "", password: "", cpassword: "" });
  const [editingId, setEditingId] = useState(null);
  const institutecode = sessionStorage.getItem("institutecode"); // Get institute code

  useEffect(() => {
    fetchTeachers();
  }, []);

  const fetchTeachers = async () => {
    try {
      const response = await axios.get(`https://pjsofttech.in:9443/GetAllTeacher?institutecode=${institutecode}`);
      setTeachers(response.data);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (formData.password !== formData.cpassword) {
      Swal.fire("Error", "Passwords do not match", "error");
      return;
    }

    try {
      if (editingId) {
        await axios.put(`https://pjsofttech.in:9443/UpdateTeacher/${editingId}`, formData);
        Swal.fire("Updated", "Teacher updated successfully", "success");
      } else {
        await axios.post(`https://pjsofttech.in:9443/SaveTeacher?institutecode=${institutecode}`, formData);
        Swal.fire("Saved", "Teacher added successfully", "success");
      }
      setOpenDialog(false);
      setFormData({ teacherName: "", email: "", password: "", cpassword: "" });
      setEditingId(null);
      fetchTeachers();
    } catch (error) {
      Swal.fire("Error", "Something went wrong!", "error");
      console.error("Error saving teacher:", error);
    }
  };

  const handleEdit = (teacher) => {
    setFormData(teacher);
    setEditingId(teacher.tid);
    setOpenDialog(true);
  };

//   const handleDelete = async (tid) => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "This action cannot be undone!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#d33",
//       cancelButtonColor: "#3085d6",
//       confirmButtonText: "Yes, delete it!",
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         try {
//           await axios.delete(`https://pjsofttech.in:9443/deleteTeacher/${tid}`);
//           Swal.fire("Deleted!", "Teacher has been deleted.", "success");
//           fetchTeachers();
//         } catch (error) {
//           Swal.fire("Error", "Failed to delete teacher", "error");
//           console.error("Error deleting teacher:", error);
//         }
//       }
//     });
//   };

  return (
    <div style={{ padding: 20 }}>
      <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
        Add Teacher
      </Button>

      {/* Teacher List Table */}
      <TableContainer component={Paper} style={{ marginTop: 20 }} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
                <TableCell>ID</TableCell>
              <TableCell>Teacher Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers.length > 0 ? (
              teachers.map((teacher) => (
                <TableRow key={teacher.tid}>
                    <TableCell>{teacher.tid}</TableCell>
                  <TableCell>{teacher.teacherName}</TableCell>
                  <TableCell>{teacher.email}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleEdit(teacher)}>
                      <Edit />
                    </IconButton>
                    {/* <IconButton color="error" onClick={() => handleDelete(teacher.tid)}>
                      <Delete />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: "center" }}>No Teachers Found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Adding/Editing Teacher */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth className='textField-root'>
        <DialogTitle color={'black'}>{editingId ? "Edit Teacher" : "Add Teacher"}</DialogTitle>
        <DialogContent>
          <TextField fullWidth label="Teacher Name" name="teacherName" value={formData.teacherName} onChange={handleChange} style={{ marginBottom: 16, marginTop: 10 }} />
          <TextField fullWidth label="Email" name="email" value={formData.email} onChange={handleChange} style={{ marginBottom: 16 }} />
          <TextField fullWidth type="password" label="Password" name="password" value={formData.password} onChange={handleChange} style={{ marginBottom: 16 }} />
          <TextField fullWidth type="password" label="Confirm Password" name="cpassword" value={formData.cpassword} onChange={handleChange} style={{ marginBottom: 16 }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{editingId ? "Update" : "Save"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TeacherManagement;
