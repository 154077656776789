import React, { useEffect, useState } from "react";
import { Pie } from "@nivo/pie";
import {
  Box,
  FormControl,
  MenuItem,
  TextField,
  Typography,Button
} from "@mui/material";
import axios from "axios";

const YearsComparison = () => {
  const getInstituteCode = () => sessionStorage.getItem("institutecode");
  const [data, setData] = useState([]);
  const [year1, setYear1] = useState(2023);
  const [year2, setYear2] = useState(2024);
  const [view, setView] = useState("revenue"); // Metric: "revenue" or "count"

  const fetchData = async (y1, y2) => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:17443/yearly-comparison?year1=${y1}&year2=${y2}&institutecode=${getInstituteCode()}`
      );
      const responseData = response.data;

      // Transform API response based on selected metric
      const chartData = responseData.map((item) => ({
        id: `${item.year}`,
        label: `${item.year}`,
        value: item[view],
      }));

      setData(chartData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(year1, year2);
  }, [year1, year2, view]);

  const handleYear1Change = (event) => {
    setYear1(event.target.value);
  };

  const handleYear2Change = (event) => {
    setYear2(event.target.value);
  };

  const handleMetricChange = (event) => {
    setView(event.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%", // Ensures the container spans the entire width
        }}
        className="textField-root"
      >
        <Typography style={{ fontWeight: "bold" }}>
          Yearly Comparison ({view.charAt(0).toUpperCase() + view.slice(1)})
        </Typography>

        <div style={{ display: "flex", gap: "10px", marginLeft: "auto", alignItems:'center' }} className="textField-root" >
          <FormControl style={{ marginBottom: "16px", minWidth: "100px" }}>
            <TextField
              select
              size="small"
              label="Year 1"
              value={year1}
              onChange={handleYear1Change}
            >
              {Array.from({ length: 15 }, (_, i) => 2024 - 7 + i).map(
                (year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                )
              )}
            </TextField>
          </FormControl>

          <FormControl style={{ marginBottom: "16px", minWidth: "100px" }}>
            <TextField
              select
              size="small"
              label="Year 2"
              value={year2}
              onChange={handleYear2Change}
            >
              {Array.from({ length: 15 }, (_, i) => 2024 - 7 + i).map(
                (year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                )
              )}
            </TextField>
          </FormControl>

          {/* <FormControl style={{ marginBottom: "16px", minWidth: "100px" }}>
            <TextField
              select
              size="small"
              label={metric}
              value={metric}
              onChange={handleMetricChange}
            >
              <MenuItem value="revenue">Revenue</MenuItem>
              <MenuItem value="count">Count</MenuItem>
            </TextField>
          </FormControl> */}

          {/* Toggle Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setView(view === "count" ? "revenue" : "count")}
            style={{
              backgroundColor: "#3498DB",
              color: "#fff",
              textTransform: "none",
            }}
          >
            {view === "count" ? "Revenue" : "Count"}
          </Button>


        </div>
      </div>

      <Box style={{  marginTop:'16px'}}>
        <Box height={330}>
        <Pie
        data={data}
        width={300}
        height={300}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={5}
        colors={({ id }) => (id === `${year1}` ? "#3498DB" : "#FF6F61")} // Custom colors for each year
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        radialLabel={(d) => `${d.id}: ${d.value}`}
        sliceLabel={(d) => `${d.value}`}
      />
        </Box>
      </Box>
    </div>
  );
};

export default YearsComparison;
