import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { CssBaseline } from "@mui/material";
import "./App.css"; // Import the global styles
import { UserProvider, UserContext } from "./layouts/UserContext";
import ForgotPassword from "./layouts/ForgotPassword";
import Login from "./layouts/Login";
import Layout from "./layouts/layout";
import AdminProfile from "./layouts/AdminProfile"; // Ensure this path is correct
import Memo from "./layouts/Memo";
import ManageMemo from "./layouts/ManageMemo";
import ManageNotification from "./layouts/ManageNotification";
import CombineDashboard from "./layouts/CombineDashboard";
import FloatingHelpDeskButton from "./layouts/FloatingHelpDeskButton";
import HelpDesk from "./layouts/HelpDesk";
//import bill from "./layouts/bill";
import AddSubAdmin from "./layouts/AddSubAdmin";
import Settings from "./layouts/Settings";
import CreateAccount from "./layouts/CreateAccountPlans/CreateAccount";
import ManageLeave from "./Employee_System/Leave/ManageLeave";
import AddMemo from "./Employee_System/Memo/AddMemo";
import ManageEmpMemo from "./Employee_System/Memo/ManageEmpMemo";
import AddNotice from "./Employee_System/Memo/AddNotice";
import Login1 from "./layouts/Login1";
import Bill from "./layouts/bill";
import EmpDash from "./layouts/EmpDash";
import StudentDashboard from "./Student/StudentDashboard";
import StudentForm from "./Student/StudentForm";
import StudentLists from "./Student/StudentLists";
import StudentReport from "./Student/StudentReport";
import StudentRequest from "./Student/StudentRequest";
import AddField from "./Student/AddField";
import AttenDash from "./layouts/AttenDash";
import FeesDashboard from "./Student/Fees/FeesDashboard";
import FeeInvoice from "./Student/Fees/FeeInvoice";
import InvoiceList from "./Student/Fees/InvoiceList";
import AddFee from "./Student/Fees/AddFee";
import FeeTable from "./Student/Fees/FeeTable";
import AddMedium from "./Student/Fees/AddMedium";
import FeeReport from "./Student/Fees/FeeReport";
import IncomeExpenseManager from "./Income Expense/IncomeExpenseManager";
import AdmissionManager from "./Addmission/AdmissionManager";
import EbookManager from "./E-Book/EbookManager";
import EnquiryManager from "./Enquiry/EnquiryManager";
import ShipmentManager from "./Shipment/ShipmentManager";
import PayrollManager from "./Payroll/PayrollManager";
import PayrollSetting from "./Payroll/PayrollSetting";
import EmployeeManager from "./Employee_System/Employee/EmployeeManager";
import AttendanceManager from "./Employee_System/Attendance/AttendanceManager";
import EmployeeSalaryManager from "./Employee_System/Salary/EmployeeSalaryManager";
import MemoManager from "./Employee_System/Memo/MemoManager";
import AdmissionForm from "./Addmission/AdmissionForm";
import AddmissionSource from "./Addmission/AddmissionSource";
import AddGuide from "./Addmission/AddGuide";
import StudentList from "./Addmission/StudentList";
import AdmissionAddCourse from "./Addmission/AdmissionAddCourse";
import IncomeExpenseDashboard from "./Income Expense/IncomeExpenseDashboard";
import AddIncomeExpense from "./Income Expense/AddIncomeExpense";
import AddCategory from "./Income Expense/AddCategory";
import { Category } from "@mui/icons-material";
import AddUser from "./Income Expense/AddUser";
import DashBoard from "./Enquiry/DashBoard";
import Report from "./Enquiry/Report";
import Exam from "./Enquiry/ExamPages/Exam";
import Source from "./Enquiry/ConductBy/Sources/Source";
import Conduct from "./Enquiry/ConductBy/Conduct";
import AddInquiry from "./Enquiry/pages/AddInquiry";
import BookName from "./Shipment/Bookname";
import VendorName from "./Shipment/VendorName";
import Type from "./Shipment/Type";
import Status from "./Shipment/Status";
import ShipmentDashboard from "./Shipment/ShipmentDashboard";
import ShipmentForm from "./Shipment/Shipmentform";
import EmployeeManagerLayout from "./Employee_System/Employee/EmployeeManagerLayout";
import EmployeeDashboard from "./Employee_System/Employee/EmployeeDashboard";
import EmployeeList from "./Employee_System/Employee/EmployeeList";
import EmpReport from "./Employee_System/Employee/EmpReport";
import ManageHoliday from "./Employee_System/Employee/ManageHoliday";
import AddEmployee from "./Employee_System/Employee/AddEmployee";
import AddEmpcategory from "./Employee_System/Employee/AddEmpcategory";
import AddDepartment from "./Employee_System/Employee/AddDepartment";
import AttendanceManagerLayout from "./Employee_System/Attendance/AttendanceManagerLayout";
import TodaysAttendance from "./Employee_System/Attendance/TodaysAttendance";
import ManageAttendance from "./Employee_System/Attendance/ManageAttendance";
import EmpDetails from "./Employee_System/Salary/EmpDetails";
import SalaryTable from "./Employee_System/Salary/SalaryTable";
import EmployeeSalaryManagerLayout from "./Employee_System/Salary/EmployeeSalaryManagerLayout";
import SalaryDashBoard from "./Employee_System/Salary/SalaryDashboard";
import AddSalary from "./Employee_System/Salary/AddSalary";
import ViewAttendance from "./Employee_System/Attendance/ViewAttendance";
import ShipmentReport from "./Shipment/ShipmentReport";
import TodaysNotification from "./layouts/TodaysNotification";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./layouts/theme"; // Import your custom theme
import StudentManager from "./Student/StudentManager";
import FeesManager from "./Student/Fees/FeesManager";
import Invoice from "./Student/Fees/Invoice";
import PayrollDashboard from "./Payroll/PayrollDashboard";
import MonthlySalary from "./Payroll/MonthlySalary";
import HourlySalary from "./Payroll/HourlySalary";
import ManageTeacher from "./Payroll/ManageTeacher";
import HourlyRate from "./Payroll/HourlyRate";
import AddSubject from "./Payroll/AddSubject";
import TeacherExam from "./Payroll/TeacherExam";
import AddTeacher from "./Payroll/AddTeacher";
import EnquirySetting from "./Enquiry/EnquirySetting";
import AdmissionSetting from "./Addmission/AdmissionSetting";
import IncomeExpenceSetting from "./Income Expense/IncomeExpenceSetting";
import EmployeeSetting from "./Employee_System/Employee/EmployeeSetting";
import ShipmentSetting from "./Shipment/ShipmentSetting";
import AllNotifications from "./layouts/AllNotifications";
import LeaveManager from "./Employee_System/Leave/LeaveManager";
import LeaveReport from "./Employee_System/Leave/LeaveReport";
import LeaveManagerLayout from "./Employee_System/Leave/LeaveManagerLayout";
import ViewLeave from "./Employee_System/Leave/ViewLeave";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddClass from "./Student/AddClass";
import StudentSetting from "./Student/StudentSetting";
import Stream from "./Student/Stream";
import Group from "./Student/Group";
import PlanInfo from "./layouts/CreateAccountPlans/PlanInfo";
import CreateAccountForm from "./layouts/CreateAccountPlans/CreateAccountForm";
import ClassRoom from "./Addmission/ClassRoom";
import Medium from "./Addmission/Medium";
import Teacher from "./Addmission/Teacher";
import Attendance from "./Addmission/Attendance";
import AdmissionQR from "./Addmission/AdmissionQR";
import About_us from "./Website/About_us";
import ContactForm from "./Website/ContactForm";
import Facility from "./Website/Facility";
import Footer from "./Website/Footer";
import Gallery from "./Website/Gallery";
import MenuBar from "./Website/MenuBar";
import Slidebar from "./Website/Slidebar";
import Toppers from "./Website/Toppers";
import WebDashboard from "./Website/WebDashboard";
import WebsiteUrl from "./Website/WebsiteUrl";
import Course from "./Website/Course";
import Testimonials from "./Website/Testimonials";
import WebsiteManager from "./Website/WebsiteManager";
import WebsiteSetting from "./Website/WebsiteSetting";
import SelectPlans from "./layouts/CreatedPlansByTanvi/SelectPlans";
import BuyPlanId from "./layouts/CreatedPlansByTanvi/BuyPlanId";
import NextPage from "./layouts/CreatedPlansByTanvi/NextPage";
import BuyAddOn from "./layouts/CreatedPlansByTanvi/BuyAddOn";
import Add from "./E-Book/Add";
import EbookAddCategory from "./E-Book/EbookAddCategory";
import AddData from "./E-Book/AddData";
import AddMaterial from "./E-Book/AddMaterial";
import EbookCategory from "./E-Book/EbookCategory";
import CategoryList from "./E-Book/CategoryList";
import Purchase from "./E-Book/Purchase";
import EbookReport from "./E-Book/EbookReport";
import SalesList from "./E-Book/SalesList";
import SubCategoryList from "./E-Book/SubcategoryList";
import UpdateMaterial from "./E-Book/UpdateMaterial";
import ViewSubCategory from "./E-Book/ViewSubcategory";
import EbookDashboard from "./E-Book/EbookDashboard";
import Chapters from "./E-Book/Chapters";
import RulesRegulations from "./Addmission/RulesRegulations";
import Installment from "./Addmission/Installment";
import ClassTeacher from "./Student/ClassTeacher";
import StudentAttendance from "./Student/StudentAttendance";
import StudentQR from "./Student/StudentQR";

function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("isAuthenticated") === "true"
  );

  const navigate = useNavigate();

  useEffect(() => {
    const institutecode = sessionStorage.getItem("institutecode");
    if (!institutecode) {
      toast.error("Session expired. Please log in again.");
      navigate("/");
    }
  }, []);

  const { user } = useContext(UserContext);

  useEffect(() => {
    const checkAuth = () => {
      const authStatus = sessionStorage.getItem("isAuthenticated") === "true";
      setIsAuthenticated(authStatus);
    };
    checkAuth();
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem("isAuthenticated", "true");
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<Login onLogin={handleLogin} />} />


        <Route path="/create-account" element={<SelectPlans />} />
        <Route path=":planId" element={<BuyPlanId/>}/>
        <Route path="next-page" element={<NextPage/>}/>
        <Route path="buyAddOn/:addOnId" element={<BuyAddOn/>}/>



        <Route path="/loginPage" element={<Login1 />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}> */}
        <Route path="/plan-details" element={<PlanInfo />} />
        <Route path="/next-Page" element={<CreateAccountForm />} />

        <Route path="/layout/*" element={<Layout />}>
          <Route path="admin-profile" element={<AdminProfile />} />
          <Route path="todaysNotifications" element={<TodaysNotification />} />
          <Route path="allNotifications" element={<AllNotifications />} />
          <Route path="combineDash" element={<CombineDashboard />} />
          <Route path="Memo-manager" element={<MemoManager />} />

          {/* Route for Enquiry Manager */}
          <Route path="enquiry-manager" element={<EnquiryManager />}>
            <Route index element={<DashBoard />} />
            <Route path="add-inquiry" element={<AddInquiry />} />
            <Route path="report" element={<Report />} />
            <Route path="EnquirySetting" element={<EnquirySetting />} />
            <Route path="exam" element={<Exam />} />
            <Route path="source" element={<Source />} />
            <Route path="conduct" element={<Conduct />} />
          </Route>

          {/* AdmissionManager and nested routes */}
          <Route path="Admission-manager" element={<AdmissionManager />}>
            <Route index element={<AdmissionForm />} />
            <Route path="admission-form" element={<AdmissionForm />} />
            <Route path="add-course" element={<AdmissionAddCourse />} />
            <Route path="AdmissionSetting" element={<AdmissionSetting />} />
            <Route path="add-source" element={<AddmissionSource />} />
            <Route path="add-guide" element={<AddGuide />} />
            <Route path="ClassRoom" element={<ClassRoom />} />
            <Route path="Medium" element={<Medium />} />
            <Route path="Teacher" element={<Teacher />} />
            <Route path="Attendance" element={<Attendance />} />
            <Route path="AdmissionQR" element={<AdmissionQR />} />
            <Route path="student-list" element={<StudentList />} />
            <Route path="Installment" element={<Installment />} />
            <Route path="RulesRegulations" element={<RulesRegulations />} />
          </Route>

          {/* Ebook */}
          <Route path="Ebook-manager" element={<EbookManager />}>
            <Route index element={<EbookDashboard />} />
            <Route path="Add" element={<Add />} />
            <Route path="EbookAddCategory" element={<EbookAddCategory />} />
            <Route path="AddData" element={<AddData />} />
            <Route path="AddMaterial" element={<AddMaterial />} />
            <Route path="EbookCategory" element={<EbookCategory />} />
            <Route path="CategoryList/:materialTypeId" element={<CategoryList />} />
            <Route path="Chapters/:category" element={<Chapters />} />
            <Route path="Purchase" element={<Purchase />} />
            <Route path="EbookReport" element={<EbookReport />} />
            <Route path="SalesList" element={<SalesList />} />
            <Route path="SubCategoryList/:id" element={<SubCategoryList />} />
            <Route path="UpdateMaterial" element={<UpdateMaterial />} />
            <Route path="ViewSubCategory" element={<ViewSubCategory />} />
          </Route>

          {/* Income Expense */}
          <Route
            path="income-expense-manager"
            element={<IncomeExpenseManager />}
          >
            <Route index element={<IncomeExpenseDashboard />} />
            <Route path="add-income-expense" element={<AddIncomeExpense />} />
            <Route
              path="IncomeExpenceSetting"
              element={<IncomeExpenceSetting />}
            />
            <Route path="add-category" element={<AddCategory />} />
            <Route path="category" element={<Category />} />
            <Route path="add-user" element={<AddUser />} />
          </Route>

          {/* Employee Manager Route with Nested Routes */}
          <Route path="employee-manager" element={<EmployeeManager />}>
            <Route index element={<EmployeeDashboard />} />
            <Route path="add-employee" element={<AddEmployee />} />
            <Route path="employee-list" element={<EmployeeList />} />
            <Route path="EmployeeSetting" element={<EmployeeSetting />} />
            <Route path="emp-report" element={<EmpReport />} />
            <Route path="add-category" element={<AddEmpcategory />} />
            <Route path="add-department" element={<AddDepartment />} />
            <Route path="manage-holiday" element={<ManageHoliday />} />
          </Route>

          <Route path="EmpDash" element={<EmpDash />} />
          <Route path="AttenDash" element={<AttenDash />} />

          <Route
            path="attendance-manager/*"
            element={<AttendanceManagerLayout />}
          >
            <Route index element={<TodaysAttendance />} />
            <Route path="manage-attendance" element={<ManageAttendance />} />
            <Route path="View-attendance/:empID" element={<ViewAttendance />} />
          </Route>

          {/*shipmenmt routes*/}
          <Route path="shipment-manager" element={<ShipmentManager />}>
            <Route index element={<ShipmentDashboard />} />
            <Route path="shipment-form" element={<ShipmentForm />} />
            <Route path="shipment-report" element={<ShipmentReport />} />
            <Route path="ShipmentSetting" element={<ShipmentSetting />} />
            <Route path="shipment-type" element={<Type />} />
            <Route path="status" element={<Status />} />
            <Route path="bookname" element={<BookName />} />
            <Route path="vendorname" element={<VendorName />} />
          </Route>

          {/* Employee Salary Manager */}
          <Route
            path="employee-salary-manager/*"
            element={<EmployeeSalaryManagerLayout />}
          >
            <Route index element={<SalaryDashBoard />} />
            <Route path="salary-list" element={<SalaryTable />} />
            <Route path="add-salary/:empID" element={<AddSalary />} />
            <Route path="add-detail" element={<EmpDetails />} />
          </Route>

          {/* Employee Leave Manager */}
          <Route path="leave-manager/*" element={<LeaveManagerLayout />}>
            <Route index element={<ManageLeave />} />
            <Route path="LeaveReport" element={<LeaveReport />} />
            <Route path="View-leave/:empID" element={<ViewLeave />} />
          </Route>

          <Route path="helpDesk" element={<HelpDesk />} />
          <Route path="bill" element={<Bill />} />
          <Route path="subadmin" element={<AddSubAdmin />} />
          <Route path="Settings" element={<Settings />} />
          <Route path="addmemo" element={<AddMemo />} />
          <Route path="manageEmpmemo" element={<ManageEmpMemo />} />
          <Route path="addnotice" element={<AddNotice />} />

          {/* Website */}
          <Route path="website-manager" element={<WebsiteManager />}>
          <Route path="WebsiteSetting" element={<WebsiteSetting />} />
          <Route path="About_us" element={<About_us />} />
          <Route path="ContactForm" element={<ContactForm />} />
          <Route path="Facility" element={<Facility />} />
          <Route path="Footer" element={<Footer />} />
          <Route path="Gallery" element={<Gallery />} />
          <Route path="MenuBar" element={<MenuBar />} />
          <Route path="Slidebar" element={<Slidebar />} />
          <Route path="Toppers" element={<Toppers />} />
          <Route path="WebDashboard" element={<WebDashboard />} />
          <Route path="WebsiteUrl" element={<WebsiteUrl />} />
          <Route path="Course" element={<Course />} />
          <Route path="Testimonials" element={<Testimonials />} />
          </Route>

          {/* Student */}
          <Route path="student-manager" element={<StudentManager />}>
            <Route path="StudentDashboard" element={<StudentDashboard />} />
            <Route path="StudentForm" element={<StudentForm />} />
            <Route path="StudentSetting" element={<StudentSetting />} />
            <Route path="StudentAttendance" element={<StudentAttendance />} />
            <Route path="StudentLists" element={<StudentLists />} />
            <Route path="StudentReport" element={<StudentReport />} />
            <Route path="StudentRequest" element={<StudentRequest />} />
            <Route path="AddField" element={<AddField />} />
            <Route path="AddClass" element={<AddClass />} />
            <Route path="ClassTeacher" element={<ClassTeacher />}/>
            <Route path="Stream" element={<Stream />} />
            <Route path="Group" element={<Group />} />
            <Route path="StudentQR" element={<StudentQR />} />
          </Route>

          {/* Teacher Payroll  */}
          <Route path="payroll-manager" element={<PayrollManager />}>
            <Route index element={<PayrollDashboard />} />
            <Route path="monthly-salary" element={<MonthlySalary />} />
            <Route path="hourly-salary" element={<HourlySalary />} />
            <Route path="teachers-list" element={<ManageTeacher />} />
            <Route path="PayrollSetting" element={<PayrollSetting />} />
            <Route path="hourly-rate" element={<HourlyRate />} />
            <Route path="add-subject" element={<AddSubject />} />
            <Route path="add-exam" element={<TeacherExam />} />
            <Route path="add-teacher" element={<AddTeacher />} />
          </Route>

          {/*Fees */}
          <Route path="fees-manager" element={<FeesManager />}>
            <Route path="FeesDashboard" element={<FeesDashboard />} />
            <Route path="Invoice" element={<Invoice />} />
            <Route path="InvoiceList" element={<InvoiceList />} />
            <Route path="AddFee" element={<AddFee />} />
            <Route path="FeeTable" element={<FeeTable />} />
            <Route path="AddMedium" element={<AddMedium />} />
            <Route path="FeeReport" element={<FeeReport />} />
          </Route>
          {/* <Route path="account" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Account /></ProtectedRoute>} /> */}
        </Route>
      </Routes>
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <UserProvider>
          <CssBaseline />
          <AppContent />
        </UserProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
