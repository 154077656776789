import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ButtonGroup,
  Container,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Grid,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export default function AddNotice() {
  const [noticeName, setNoticeName] = useState("");
  const [noticeDescription, setNoticeDescription] = useState("");
  const [email, setEmail] = useState("");
  const [notices, setNotices] = useState([]);
  const [createdAt, setCreatedAt] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [selectedNotice, setSelectedNotice] = useState(null);
  const institutecode = sessionStorage.getItem("institutecode");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [noticeToDelete, setNoticeToDelete] = useState(null);

  useEffect(() => {
    fetchNotices();
  }, []);

  const fetchNotices = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:10443/notices/getallByInstitutecode?institutecode=${institutecode}`
      );
      setNotices(response.data);
    } catch (error) {
      console.error("Error fetching notices:", error);
    }
  };

  // New function to convert notices to CSV and trigger download
  const downloadCSV = () => {
    const csvRows = [];
    const headers = ["Notice ID", "Notice Name", "Email", "Description", "Created At"];
    csvRows.push(headers.join(","));

    // Add the data rows
    notices.forEach((notice) => {
      const values = [
        notice.nid,
        notice.noticeName,
        notice.email,
        notice.noticeDescription,
        notice.createdAt,
      ];
      csvRows.push(values.join(","));
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "notices.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAddNotice = async () => {
    const newNotice = {
      noticeName,
      email,
      noticeDescription,
      createdAt,
      institutecode,
    };
    try {
      await axios.post(
        `https://pjsofttech.in:10443/notices/addnotice?institutecode=${institutecode}`,
        newNotice
      );
      toast.success("Notice sent successfully!");
      fetchNotices(); // Refresh the notice list
      setNoticeName(""); // Clear noticeName field
      setNoticeDescription(""); // Clear noticeDescription field
    } catch (error) {
      console.error("Error creating notice:", error);
      toast.error("Failed to send notice.");
    }
  };
  

  const handleDeleteNotice = async () => {
    if (noticeToDelete) {
      try {
        await axios.delete(
          `https://pjsofttech.in:10443/notices/deleteNotice/${noticeToDelete}`
        );
        toast.success("Notice deleted successfully!");
        fetchNotices(); // Refresh the notice list
        setOpenConfirmDialog(false);
        setNoticeToDelete(null);
      } catch (error) {
        console.error("Error deleting notice:", error);
        toast.error("Failed to delete notice.");
      }
    }
  };

  const handleEditNotice = (notice) => {
    setSelectedNotice(notice);
    setNoticeName(notice.noticeName);
    setEmail(notice.email);
    setNoticeDescription(notice.noticeDescription);
    setCreatedAt(notice.createdAt.split("T")[0]);
    setOpenEditDialog(true);
  };

  const handleUpdateNotice = async () => {
    const updatedNotice = {
      id: selectedNotice.nid,
      noticeName,
      email,
      noticeDescription,
      createdAt,
      institutecode,
    };

    try {
      await axios.put(
        `https://pjsofttech.in:10443/notices/updateNotice/${selectedNotice.nid}`,
        updatedNotice
      );
      toast.success("Notice updated successfully!");
      fetchNotices(); // Refresh the notice list
      setOpenEditDialog(false);
      setSelectedNotice(null);
      setNoticeName("");
      setNoticeDescription("");
    } catch (error) {
      console.error("Error updating notice:", error);
      toast.error("Failed to update notice.");
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedNotice(null);
  };

  const openConfirmDialogBox = (id) => {
    setNoticeToDelete(id);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setNoticeToDelete(null);
  };

  return (
    <div>
      {/* <Grid spacing={1} className="textField-root">
        <Grid xs={12} md={6}>
          <TextField
            label="Notice Name"
            value={noticeName}
            onChange={(e) => setNoticeName(e.target.value)}
            fullWidth
            margin="normal"
            size="small"
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            size="small"
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField
            label="Created At"
            type="date"
            value={createdAt}
            onChange={(e) => setCreatedAt(e.target.value)}
            fullWidth
            margin="normal"
            size="small"
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField
            label="Notice Description"
            value={noticeDescription}
            onChange={(e) => setNoticeDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            margin="normal"
            size="small"
          />
        </Grid>
      </Grid> */}

<Grid container spacing={2} className="textField-root">
  {/* Notice Name and Email on a single line */}
  <Grid item xs={12} md={4}>
    <TextField
      label="Notice Name"
      value={noticeName}
      onChange={(e) => setNoticeName(e.target.value)}
      fullWidth
      margin="normal"
      size="small"
    />
  </Grid>
  
  <Grid item xs={12} md={4}>
    <TextField
      label="Email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      fullWidth
      margin="normal"
      size="small"
    />
  </Grid>

  {/* Created At and Notice Description on the next line */}
  <Grid item xs={12} md={4}>
    <TextField
      label="Created At"
      type="date"
      value={createdAt}
      onChange={(e) => setCreatedAt(e.target.value)}
      fullWidth
      margin="normal"
      size="small"
    />
  </Grid>

  <Grid item xs={12} md={6}>
    <TextField
      label="Notice Description"
      value={noticeDescription}
      onChange={(e) => setNoticeDescription(e.target.value)}
      fullWidth
      multiline
      rows={4}
      margin="normal"
      size="small"
    />
  </Grid>
</Grid>

      <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddNotice}
          sx={{ mr: 2 }}
        >
          Send Notice
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={downloadCSV}
          sx={{ mr: 2 }}
          startIcon={<CloudDownloadIcon />}
        >
          Download CSV
        </Button>
        <Typography>Total Notices: {notices.length}</Typography>
      </Box>

      <TableContainer sx={{ marginTop: "1%" }}>
        <Table className="table-root">
          <TableHead>
            <TableRow>
              <TableCell>Notice ID</TableCell>
              <TableCell>Notice Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notices.map((notice) => (
              <TableRow key={notice.nid}>
                <TableCell>{notice.nid}</TableCell>
                <TableCell>{notice.noticeName}</TableCell>
                <TableCell>{notice.email}</TableCell>
                <TableCell>{notice.noticeDescription}</TableCell>
                <TableCell>{notice.createdAt}</TableCell>
                <TableCell>
                  <ButtonGroup>
                    <IconButton
                      onClick={() => handleEditNotice(notice)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => openConfirmDialogBox(notice.nid)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Notice</DialogTitle>
        <DialogContent className="textField-root">
          <TextField
            label="Notice Name"
            value={noticeName}
            onChange={(e) => setNoticeName(e.target.value)}
            fullWidth
            margin="normal"
          />
              <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Notice Description"
            value={noticeDescription}
            onChange={(e) => setNoticeDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
          <TextField
            label="Created At"
            type="date"
            value={createdAt}
            onChange={(e) => setCreatedAt(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateNotice} color="secondary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this notice?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteNotice} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </div>
  );
}
