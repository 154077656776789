import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Paper,
} from "@mui/material";
import { fetchPendingAmountRevenue } from "./PayrollAPI"; // Import the API function

const apiEndpoints = [
  {
    label: "Today Pending Salary",
    timeframe: "TODAY",
    backgroundColor: "#F9E79F",
  },
  {
    label: "7 Days Pending Salary",
    timeframe: "LAST_7_DAYS",
    backgroundColor: "#FF6F61",
  },
  {
    label: "30 Days Pending Salary",
    timeframe: "LAST_30_DAYS",
    backgroundColor: "#3498DB",
  },
  {
    label: "365 Days Pending Salary",
    timeframe: "LAST_365_DAYS",
    backgroundColor: "#F9E79F",
  },
  {
    label: "Total Pending Salary",
    timeframe: "TOTAL",
    backgroundColor: "#FF6F61",
  },
];

export default function PendingAmountRevenue() {
  const getInstituteCode = () => sessionStorage.getItem("institutecode");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const instituteCode = getInstituteCode();
        const results = await fetchPendingAmountRevenue(
          apiEndpoints,
          instituteCode
        ); // Use the imported API function
        setData(results);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <div>
      <Grid container sx={{ justifyContent: "space-between" }}>
        {apiEndpoints.map((endpoint) => (
          <Grid item xs={12} sm={6} md={2.4} key={endpoint.timeframe}>
            <CardContent>
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  backgroundColor: endpoint.backgroundColor,
                  borderRadius: 2,
                  fontWeight: "bold",
                  boxShadow: 3,
                }}
              >
                <Typography variant="h7" component="div">
                  {endpoint.label}
                </Typography>
                <Typography variant="h7">
                  ₹{data[endpoint.timeframe] || 0}
                </Typography>
              </Paper>
            </CardContent>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
