// Import required dependencies
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import Swal from "sweetalert2"; // Import SweetAlert2 for confirmation popups

const Teacher = () => {
  const [teachers, setTeachers] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentTeacher, setCurrentTeacher] = useState(null);
  const institutecode = sessionStorage.getItem("institutecode");
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({
    teacherName: "",
    email: "",
    password: "",
    cpassword: "",
  });
  const fetchTeachers = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:13443/GetAllTeacher?institutecode=${institutecode}`
      );
      setFilteredTeachers(response.data);
    } catch (error) {
      console.error("Error fetching Teachers:", error);
    }
  };

  useEffect(() => {
    if (institutecode) {
      fetchTeachers();
    }
  }, [institutecode]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value) { 
      const filteredData = teachers.filter((teacher) =>
        teacher.teacherName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredTeachers(filteredData);
    } else {
      setFilteredTeachers(teachers);
    }
  };

  // Open dialog for create or edit
  const handleOpen = (teacher = null) => {
    setCurrentTeacher(teacher);
    setFormData(
      teacher || {
        teacherName: "",
        email: "",
        password: "",
        cpassword: "",
        institutecode: "",
      }
    );
    setOpen(true);
  };

  // Close dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Handle form submission for create or update
  const handleSubmit = async () => {
    try {
      if (currentTeacher) {
        await axios.put(
          `https://pjsofttech.in:13443/UpdateTeacher/${currentTeacher.tid}`,
          formData
        );
        Swal.fire("Updated!", "The Teacher has been updated.", "success");
      } else {
        await axios.post(`https://pjsofttech.in:13443/SaveTeacher`, formData, {
          params: { institutecode },
        });
      }
      Swal.fire("Added!", "The Teacher has been added.", "success");
      fetchTeachers();
      handleClose();
    } catch (error) {
      console.error("Error saving Teacher:", error);
      Swal.fire(
        "Error!",
        "There was an error saving the Teacher. Please try again.",
        "error"
      );
    }
  };

  // Handle delete
  const handleDelete = async (tid) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to delete this Teacher?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      });

      if (result.isConfirmed) {
        await axios.delete(`https://pjsofttech.in:13443/deleteTeacher/${tid}`);
        Swal.fire("Deleted!", "The Teacher has been deleted.", "success");
        fetchTeachers();
      }
    } catch (error) {
      console.error("Error deleting Teacher:", error);
      Swal.fire(
        "Error!",
        "There was an error while deleting the Teacher.",
        "error"
      );
    }
  };

  return (
<Paper elevation={3} sx={{padding: "0 20px 20px 20px", borderRadius: "8px"}}>
      <Grid container spacing={2} className="textField-root">
        <Typography
          variant="h6"
          gutterBottom
          sx={{ marginTop: 3, whiteSpace: "nowrap" }}
        >
          Total Teachers: {teachers.length}
        </Typography>{" "}
        <Grid item xs={12} sm={3}>
          <TextField
            label="Search Teachers"
            name="search"
            value={searchTerm}
            onChange={handleSearch}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen()}
          >
            Add Teacher
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{ marginTop: 3 }}
        className="table-root"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Teacher Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTeachers.map((teacher) => (
              <TableRow key={teacher.tid}>
                <TableCell>{teacher.tid}</TableCell>
                <TableCell>{teacher.teacherName}</TableCell>
                <TableCell>{teacher.email}</TableCell>
                <TableCell>
                  <Button color="primary" onClick={() => handleOpen(teacher)}>
                    <Edit />
                  </Button>
                  <Button
                    color="error"
                    onClick={() => handleDelete(teacher.tid)}
                  >
                    <Delete />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for create/edit */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        className="textField-root"
      >
        <DialogTitle color={"black"}>
          {currentTeacher ? "Edit Teacher" : "Add Teacher"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                label="Teacher Name"
                name="teacherName"
                value={formData.teacherName}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Confirm Password"
                name="cpassword"
                value={formData.cpassword}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Teacher;
