import React, { useEffect, useState } from 'react';
import { 
    Container, Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Paper, Button, TextField, Dialog, 
    DialogActions, DialogContent, DialogTitle, IconButton 
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import axios from 'axios';

const RulesRegulations = () => {
    const [rules, setRules] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ id: '', rulesAndRegulations: ''});
    const institutecode = sessionStorage.getItem("institutecode");
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        fetchRules();
    }, []);

    // Fetch all rules
    const fetchRules = async () => {
        try {
            const response = await axios.get(`https://pjsofttech.in:13443/getAllRulesAndRegulations?institutecode=${institutecode}`);
            setRules(response.data);
        } catch (error) {
            console.error('Error fetching rules:', error);
        }
    };

    // Handle form input change
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Open form dialog for adding new rule
    const handleOpen = () => {
        setFormData({ id: '', rulesAndRegulations: ''});
        setEditMode(false);
        setOpen(true);
    };

    // Open form dialog for editing
    const handleEdit = (rule) => {
        setFormData(rule);
        setEditMode(true);
        setOpen(true);
    };

    // Handle form submission (Add or Update)
    const handleSubmit = async () => {
        if (editMode) {
            await axios.put(`https://pjsofttech.in:13443/updateRulesAndRegulations/${formData.id}`, formData);
        } else {
            await axios.post(`https://pjsofttech.in:13443/createRulesAndRegulations?institutecode=${institutecode}`, formData);
        }
        fetchRules();
        setOpen(false);
    };

    // Handle delete rule
    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this rule?')) {
            await axios.delete(`https://pjsofttech.in:13443/deleteRulesAndRegulations/${id}`);
            fetchRules();
        }
    };

    return (
        <Container>
            <h2>Rules and Regulations</h2>
            <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleOpen}>
                Add Rule
            </Button>
            
            <TableContainer component={Paper} style={{ marginTop: '20px' }} className="table-root">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Rule Name</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rules.map((rule) => (
                            <TableRow key={rule.id}>
                                <TableCell>{rule.id}</TableCell>
                                <TableCell>{rule.rulesAndRegulations}</TableCell>
                                <TableCell>
                                    <IconButton color="primary" onClick={() => handleEdit(rule)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton color="error" onClick={() => handleDelete(rule.id)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog for Adding/Editing */}
            <Dialog open={open} onClose={() => setOpen(false)} className="textField-root">
                <DialogTitle color={'black'}>{editMode ? 'Edit Rule' : 'Add New Rule'}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Rule Name"
                        name="rulesAndRegulations"
                        fullWidth
                        value={formData.rulesAndRegulations}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">Cancel</Button>
                    <Button onClick={handleSubmit} color="primary">{editMode ? 'Update' : 'Add'}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default RulesRegulations;
