import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";

const Group = () => {
  const [groups, setGroups] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({
    grid: 0,
    groupName: "",
  });
  const institutecode = sessionStorage.getItem("institutecode");

  // Fetch all groups
  const fetchGroups = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:9443/getAllGroup?institutecode=${institutecode}`
      );
      setGroups(response.data);
    } catch (error) {
      Swal.fire("Error", "Failed to fetch groups.", "error");
    }
  };

  // Add a new group
  const handleAddGroup = async () => {
    try {
      await axios.post(
        "https://pjsofttech.in:9443/addGroup",
        currentGroup,
        { params: { institutecode } }
      );
      fetchGroups();
      setOpenDialog(false);
      Swal.fire("Success", "Group added successfully.", "success");
    } catch (error) {
      Swal.fire("Error", "Failed to add group.", "error");
    }
  };

  // Update an existing group
  const handleUpdateGroup = async () => {
    try {
      await axios.put(
        `https://pjsofttech.in:9443/updateGroup/${currentGroup.grid}`,
        currentGroup
      );
      fetchGroups();
      setOpenDialog(false);
      Swal.fire("Success", "Group updated successfully.", "success");
    } catch (error) {
      Swal.fire("Error", "Failed to update group.", "error");
    }
  };

  // Delete a group with confirmation
  const handleDeleteGroup = async (grid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://pjsofttech.in:9443/deleteGroup/${grid}`);
          fetchGroups();
          Swal.fire("Deleted!", "Group has been deleted.", "success");
        } catch (error) {
          Swal.fire("Error", "Failed to delete group.", "error");
        }
      }
    });
  };

  const handleDialogOpen = (group = { grid: 0, groupName: "" }) => {
    setCurrentGroup(group);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleDialogOpen()}
        style={{ marginBottom: "20px" }}
      >
        Add Group
      </Button>

      <TableContainer component={Paper} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Group id</TableCell>
              <TableCell>Group Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group) => (
              <TableRow key={group.grid}>
                <TableCell>{group.grid}</TableCell>
                <TableCell>{group.groupName}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleDialogOpen(group)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteGroup(group.grid)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Adding/Editing Group */}
      <Dialog open={openDialog} onClose={handleDialogClose} className="textField-root">
        <DialogTitle color={'black'}>
          {currentGroup.grid ? "Update Group" : "Add Group"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Group Name"
            type="text"
            fullWidth
            value={currentGroup.groupName}
            onChange={(e) =>
              setCurrentGroup({ ...currentGroup, groupName: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={
              currentGroup.grid ? handleUpdateGroup : handleAddGroup
            }
            color="primary"
          >
            {currentGroup.grid ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Group;
