import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Typography,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

const Installment = ({ admissionId, open, onClose }) => {
  const [installments, setInstallments] = useState([]);
  const [studentData, setStudentData] = useState(null);
  const [formOpen, setFormOpen] = useState(false);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [formData, setFormData] = useState({
    invoiceNo: "",
    amount: "",
    dueDate: "",
    installmentDate: "",
    billType: "",
    paidBy: "",
    transactionId: "",
    status: "",
    remark: "",
  });

  useEffect(() => {
    if (admissionId) {
      fetchInstallments();
      fetchStudentData();
    }
  }, [admissionId]);

  // Fetch installments
  const fetchInstallments = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:13443/installments/getByAdmission/${admissionId}`
      );
      setInstallments(response.data);
    } catch (error) {
      console.error("Error fetching installments:", error);
    }
  };

  // Fetch student details
  const fetchStudentData = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:13443/admissions/${admissionId}`
      );
      setStudentData(response.data);
      setPendingAmount(response.data.pendingFees);
    } catch (error) {
      console.error("Error fetching student data:", error);
    }
  };

  // Handle adding installment
  const handleAddInstallment = async () => {
    try {
      const newPendingAmount = pendingAmount - parseFloat(formData.amount);
      await axios.post(
        `https://pjsofttech.in:13443/installments/addToAdmission/${admissionId}`,
        formData
      );
      setPendingAmount(newPendingAmount);
      fetchInstallments();
      setFormOpen(false);
    } catch (error) {
      console.error("Error adding installment:", error);
    }
  };

  // Handle form field changes
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle color={"black"}>
        Installments
        <Button style={{ float: "right" }} variant="contained" onClick={() => setFormOpen(true)}>
          Add Installment
        </Button>
      </DialogTitle>
      <DialogContent>
        {studentData && (
          <div style={{ marginBottom: "20px", display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">
              Student: {studentData.name}
            </Typography>
            <Typography>Total Fees: ₹{studentData.totalFees}</Typography>
            <Typography>Pending Amount: ₹{pendingAmount}</Typography>
          </div>
        )}
        <TableContainer className="table-root">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Invoice No</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Installment Date</TableCell>
                <TableCell>Bill Type</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Payment Mode</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Remark</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {installments.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.invoiceNo}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>{item.dueDate}</TableCell>
                  <TableCell>{item.installmentDate}</TableCell>
                  <TableCell>{item.billType}</TableCell>
                  <TableCell>{item.transactionId}</TableCell>
                  <TableCell>{item.paidBy}</TableCell>
                  <TableCell sx={{fontWeight:"bold", color: item.status === "Pending" ? "red" : item.status === "Completed" ? "green" :"green",}}>{item.status}</TableCell>
                  <TableCell>{item.remark}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>

      {/* Installment Form */}
      <Dialog open={formOpen} onClose={() => setFormOpen(false)} maxWidth="md" className="textField-root">
        <DialogTitle color={"black"}>Add Installment</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={6}>
          <TextField
            label="Amount"
            type="number"
            fullWidth
            margin="dense"
            name="amount"
            value={formData.amount || pendingAmount}
            onChange={handleFormChange}
          />
          </Grid>
            <Grid item xs={6}>
          <TextField
            label="Due Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            margin="dense"
            name="dueDate"
            value={formData.dueDate}
            onChange={handleFormChange}
          />
            </Grid>
            <Grid item xs={6}>
          <TextField
            label="Installment Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            margin="dense"
            name="installmentDate"
            value={formData.installmentDate}
            onChange={handleFormChange}
          />
            </Grid>
            <Grid item xs={6}>
          <TextField
            select
            label="Bill Type"
            fullWidth
            margin="dense"
            name="billType"
            value={formData.billType}
            onChange={handleFormChange}
          >
            <MenuItem value="Invoice">Invoice</MenuItem>
            <MenuItem value="Receipt">Receipt</MenuItem>
          </TextField>
            </Grid>
            <Grid item xs={6}>
          <TextField
            select
            label="Payment Mode"
            fullWidth
            margin="dense"
            name="paidBy"
            value={formData.paidBy}
            onChange={handleFormChange}
          >
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="Cheque">Cheque</MenuItem>
            <MenuItem value="Online">Online</MenuItem>
            <MenuItem value="UPI">UPI</MenuItem>
          </TextField>
            </Grid>
            <Grid item xs={6}>
          <TextField
            label="Transaction ID"
            fullWidth
            margin="dense"
            name="transactionId"
            value={formData.transactionId}
            onChange={handleFormChange}
          />
            </Grid>
            <Grid item xs={6}>
          <TextField
            select
            label="Status"
            fullWidth
            margin="dense"
            name="status"
            value={formData.status}
            onChange={handleFormChange}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </TextField>
            </Grid>
            <Grid item xs={12}>
          <TextField
            label="Remark"
            fullWidth
            margin="dense"
            name="remark"
            value={formData.remark}
            onChange={handleFormChange}
          />
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddInstallment} color="primary">
            Add
          </Button>
          <Button onClick={() => setFormOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default Installment;
