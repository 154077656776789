import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import axios from "axios";
import ShipmentForm from "./Shipmentform";
import Swal from "sweetalert2";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import {
  getTypes,
  getStatuses,
  getBookNames,
  getVendorNames,
  getSentBys,
} from "./API/ShipmentAPI"; // Import your API methods
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const ShipmentReport = () => {
  const [sentByLink, setSentByLink] = useState(""); // New state for the link
  const [shipments, setShipments] = useState([]);
  const [filteredShipments, setFilteredShipments] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentShipment, setCurrentShipment] = useState(null);
  const [types, setTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [bookNames, setBookNames] = useState([]);
  const [vendorNames, setVendorNames] = useState([]);
  const [sentBys, setSentBys] = useState([]);

  // State for filter criteria
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedBookName, setSelectedBookName] = useState("");
  const [selectedVendorName, setSelectedVendorName] = useState("");
  const [selectedSentBy, setSelectedSentBy] = useState("");
  const [selectedDate, setSelectedDate] = useState(""); // New state for selected date

  // New state variables for additional search fields
  const [searchName, setSearchName] = useState("");
  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  const [searchOrderId, setSearchOrderId] = useState("");

  const institutecode = sessionStorage.getItem("institutecode");

  const [viewShipment, setViewShipment] = useState(null); // State for viewing shipment details

  const handleViewShipment = async (shipment) => {
    setViewShipment(shipment);
    setOpenPdfDialog(true);

    // Fetch the link based on sentBy ID
    try {
      const response = await axios.get(
        `https://pjsofttech.in:17443/getSentById/${shipment.sentById}`
      ); // Assume sentById is available
      setSentByLink(response.data.link); // Assuming the API returns an object with a 'link' property
    } catch (error) {
      console.error("Error fetching the sent by link", error);
      setSentByLink(""); // Reset link on error
    }
  };

  const [openPdfDialog, setOpenPdfDialog] = useState(false);

  const handleDownloadPDF = async () => {
    const doc = new jsPDF();
    const content = document.getElementById("shipment-details");

    const canvas = await html2canvas(content);
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = 190; // Width of the image in mm
    const pageHeight = doc.internal.pageSize.height;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;
    doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    doc.save("shipment-details.pdf");
  };

  // Fetch shipments and dropdown data
  const fetchShipments = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:17443/getAllShipmentForms?institutecode=${institutecode}`
      );
      if (response.data) {
        setShipments(response.data);
        setFilteredShipments(response.data);
      } else {
        console.error("No shipment data found.");
      }
    } catch (error) {
      console.error("Error fetching shipment data", error);
    }
  };

  const fetchDropdownData = async () => {
    try {
      const [
        typesData,
        statusesData,
        bookNamesData,
        vendorNamesData,
        sentBysData,
      ] = await Promise.all([
        getTypes(institutecode),
        getStatuses(institutecode),
        getBookNames(),
        getVendorNames(),
        getSentBys(),
      ]);
      setTypes(typesData);
      setStatuses(statusesData);
      setBookNames(bookNamesData);
      setVendorNames(vendorNamesData);
      setSentBys(sentBysData);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  };

  useEffect(() => {
    fetchShipments();
    fetchDropdownData();
  }, [institutecode]);

  useEffect(() => {
    filterShipments();
  }, [
    selectedType,
    selectedStatus,
    selectedBookName,
    selectedVendorName,
    selectedSentBy,
    selectedDate,
    searchName,
    searchPhoneNumber,
    searchOrderId,
    shipments,
  ]);

  const filterShipments = () => {
    let filtered = shipments;

    // Existing filters
    if (selectedType) {
      filtered = filtered.filter((shipment) => shipment.type === selectedType);
    }
    if (selectedStatus) {
      filtered = filtered.filter(
        (shipment) => shipment.status === selectedStatus
      );
    }
    if (selectedBookName) {
      filtered = filtered.filter(
        (shipment) => shipment.bookName === selectedBookName
      );
    }
    if (selectedVendorName) {
      filtered = filtered.filter(
        (shipment) => shipment.vendorName === selectedVendorName
      );
    }
    if (selectedSentBy) {
      filtered = filtered.filter(
        (shipment) => shipment.sentBy === selectedSentBy
      );
    }
    if (selectedDate) {
      filtered = filtered.filter(
        (shipment) => shipment.orderDate.slice(0, 10) === selectedDate // Compare only the date part
      );
    }
    if (searchName) {
      filtered = filtered.filter((shipment) =>
        shipment.customerName.toLowerCase().includes(searchName.toLowerCase())
      );
    }
    if (searchPhoneNumber) {
      filtered = filtered.filter(
        (shipment) =>
          shipment.customerMobile &&
          shipment.customerMobile.includes(searchPhoneNumber)
      );
    }
    if (searchOrderId) {
      filtered = filtered.filter((shipment) =>
        shipment.customerEmail.includes(searchOrderId)
      );
    }

    setFilteredShipments(filtered);
  };

  const handleOpen = (shipment = null) => {
    setCurrentShipment(shipment);
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentShipment(null);
    setOpen(false);
  };

  const refreshShipments = async () => {
    await fetchShipments();
    handleClose();
  };

  const handleDelete = async (shipmentId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete the shipment with ID: ${shipmentId}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(
          `https://pjsofttech.in:17443/deleteShipmentForm/${shipmentId}`
        );
        Swal.fire("Deleted!", "Your shipment has been deleted.", "success");
        refreshShipments();
      } catch (error) {
        Swal.fire(
          "Error!",
          "There was an error deleting the shipment.",
          "error"
        );
      }
    }
  };

  const [employeeDetails, setEmployeeDetails] = useState(null);
  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        if (!institutecode) {
          console.error("No institutecode found in sessionStorage");
          return;
        }

        const response = await axios.get(
          `https://pjsofttech.in:20443/findInstitutesby/Institutecode?institutecode=${institutecode}`
        );
        setEmployeeDetails(response.data);
      } catch (error) {
        console.error("Error fetching employee details:", error);
      }
    };

    fetchEmployeeDetails();
  }, [institutecode]);

  return (
    <div>
      <Grid
        container
        alignItems={"center"}
        spacing={2}
        className="textField-root"
      >
        {/* New date selection field */}
        <Grid item xs={1.5}>
          <TextField
            size="small"
            type="date"
            label="Date"
            variant="outlined"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: "true" }}
          />
        </Grid>

        {/* New search fields */}
        <Grid item xs={1.5}>
          <TextField
            size="small"
            label="Search by Name"
            variant="outlined"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={1.5}>
          <TextField
            size="small"
            label="Search by Phone Number"
            variant="outlined"
            value={searchPhoneNumber}
            onChange={(e) => setSearchPhoneNumber(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={1.5}>
          <TextField
            size="small"
            label="Search by Order ID"
            variant="outlined"
            value={searchOrderId}
            onChange={(e) => setSearchOrderId(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={1.5}>
          <FormControl
            variant="outlined"
            style={{ marginRight: "10px" }}
            fullWidth
          >
            <TextField
              size="small"
              select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              label="Type"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {types.map((type) => (
                <MenuItem key={type.id} value={type.typeName}>
                  {type.typeName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item xs={1.5}>
          <FormControl
            variant="outlined"
            style={{ marginRight: "10px" }}
            fullWidth
          >
            <TextField
              size="small"
              select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {statuses.map((status) => (
                <MenuItem key={status.id} value={status.status}>
                  {status.status}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item xs={1.5}>
          <FormControl
            variant="outlined"
            style={{ marginRight: "10px" }}
            fullWidth
          >
            <TextField
              size="small"
              select
              value={selectedBookName}
              onChange={(e) => setSelectedBookName(e.target.value)}
              label="Product Name"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {bookNames.map((book) => (
                <MenuItem key={book.id} value={book.bookName}>
                  {book.bookName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item xs={1.5}>
          <FormControl
            variant="outlined"
            style={{ marginRight: "10px" }}
            fullWidth
          >
            <TextField
              size="small"
              select
              value={selectedVendorName}
              onChange={(e) => setSelectedVendorName(e.target.value)}
              label="Vendor Name"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {vendorNames.map((vendor) => (
                <MenuItem key={vendor.id} value={vendor.vendorName}>
                  {vendor.vendorName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item xs={1.5}>
          <FormControl
            variant="outlined"
            style={{ marginRight: "10px" }}
            fullWidth
          >
            <TextField
              size="small"
              select
              value={selectedSentBy}
              onChange={(e) => setSelectedSentBy(e.target.value)}
              label="Sent By"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {sentBys.map((sentBy) => (
                <MenuItem key={sentBy.id} value={sentBy.sentBy}>
                  {sentBy.sentBy}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item xs={1.5}>
          <Typography alignItems={"center"} fontWeight={"bold"}>
            Total Orders: {filteredShipments.length}
          </Typography>
        </Grid>

        <Grid item xs={1.5}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen()}
            size="small"
          >
            Add Order
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <TableContainer>
          <Table size="small">
            <TableHead
              style={{ backgroundColor: "#f2f2f2", justifyContent: "center" }}
            >
              <TableRow>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  ID
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Order ID
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Order Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Customer
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Phone No.
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Email
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Type
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Dispatch Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Track No
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Sent By
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Vendor Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                Product Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Quantity
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Price
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Status
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredShipments.length > 0 ? (
                filteredShipments.map((shipment) => (
                  <TableRow key={shipment.id}>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.id}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.orderId}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.orderDate}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.customerName}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.customerMobile}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.customerEmail || "N/A"}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.type}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.dispatchDate}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.trackingNo || "N/A"}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.sentBy}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.vendorName}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {Array.isArray(shipment.bookName)
                        ? shipment.bookName.join(", ")
                        : shipment.bookName}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.quantity}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {shipment.price}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      {shipment.status}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <div style={{ display: "flex" }}>
                        <Button
                          onClick={() => handleOpen(shipment)}
                          color="success"
                        >
                          <Edit />
                        </Button>
                        <Button
                          color="error"
                          onClick={() => handleDelete(shipment.id)}
                        >
                          <Delete />
                        </Button>
                        <Button
                          onClick={() => handleViewShipment(shipment)}
                          color="primary"
                        >
                          <Visibility />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={13} align="center">
                    No shipments available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Dialog for viewing shipment details */}
      <Dialog
        open={openPdfDialog}
        onClose={() => setOpenPdfDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent id="shipment-details" padding={"2%"}>
          {viewShipment && (
            <div style={{ border: "0.5px dotted gray", padding: "2%", display:'flex', justifyContent:'space-between' }}>
              {/* Order and Tracking Information */}
              

              {/* Sender Information */}
              <div
                style={{
                  textAlign: "left",
                  marginBottom: "20px",
                  marginTop: "10px",
                  fontSize: "20px",
                }}
              >
                <Typography>From:</Typography>
                {employeeDetails.institutename && (
                  <Typography>{employeeDetails.institutename}</Typography>
                )}
                {employeeDetails.emailaddress && (
                  <Typography>{employeeDetails.emailaddress}</Typography>
                )}
                {employeeDetails.address && (
                  <Typography>{employeeDetails.address}</Typography>
                )}
                {employeeDetails.phonenumber && (
                  <Typography>{employeeDetails.phonenumber}</Typography>
                )}
                <Typography fontWeight={"bold"}>
                  Order - {viewShipment.orderId}
                </Typography>
                <Typography fontWeight={"bold"}>
                  Tracking ID - {viewShipment.trackingNo}
                </Typography>
              </div>

              {/* Recipient Information */}
              <div
                style={{
                  textAlign: "right",
                  marginBottom: "30px",
                }}
              >
                <Typography>To:</Typography>
                {viewShipment.customerName && (
                  <Typography>{viewShipment.customerName}</Typography>
                )}
                {viewShipment.customerMobile && (
                  <Typography>{viewShipment.customerMobile}</Typography>
                )}
                {viewShipment.customerAddress && (
                  <Typography>
                    {viewShipment.customerAddress
                      .split(" ")
                      .map((word, index, words) =>
                        index % 3 === 0 ? (
                          <span key={index}>
                            {words.slice(index, index + 3).join(" ")} <br />
                          </span>
                        ) : null
                      )}
                  </Typography>
                )}
                {viewShipment.pincode && (
                  <Typography>{viewShipment.pincode}</Typography>
                )}
              </div>

              {/* Thank You Message */}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewShipment(null)} color="primary">
            Close
          </Button>
          <Button onClick={handleDownloadPDF} color="primary">
            Download as PDF
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>
          {currentShipment ? "Edit Shipment Form" : "Add New Shipment Form"}
        </DialogTitle>
        <DialogContent>
          <ShipmentForm
            shipmentData={currentShipment}
            onClose={handleClose}
            refreshShipments={refreshShipments}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShipmentReport;
