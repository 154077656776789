// import React, { useState, useEffect } from "react";
// import {
//   getPresentEmployeeCount,
//   getAbsentEmployeeCount,
//   getTotalEmployeeCount,
// } from "../Attendance/attendanceService";
// import {
//   Box,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TextField,
//   Grid,
//   MenuItem,
//   FormControl,
//   Card,
//   CardContent,
// } from "@mui/material";
// import axios from "axios";
// import { styled } from "@mui/system";

// const Container = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(3),
// }));

// const TodaysAttendance = () => {
//   const [employees, setEmployees] = useState([]);
//   const [presentCount, setPresentCount] = useState(0);
//   const [absentCount, setAbsentCount] = useState(0);
//   const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
//   const [search, setSearch] = useState("");
//   const [statusFilter, setStatusFilter] = useState("All");
//   const [timeFilter, setTimeFilter] = useState("Today"); // New state for filter options
//   const [filteredAttendance, setFilteredAttendance] = useState([]);
//   const [startDate, setStartDate] = useState(""); // State for start date
//   const [endDate, setEndDate] = useState(""); // State for end date

//   const filterOptions = ["Today", "Yesterday", "custom"];

//   useEffect(() => {
//     fetchEmployeeCounts();
//   }, []);

//   useEffect(() => {
//     filterAttendance();
//   }, [search, statusFilter, employees]);

//   // Trigger API call when timeFilter changes
//   useEffect(() => {
//     if (timeFilter !== "custom") {
//       handleFilterChange(timeFilter);
//     }
//   }, [timeFilter]);

//   useEffect(() => {
//     if (timeFilter === "custom" && startDate && endDate) {
//       handleFilterChange("custom");
//     }
//   }, [startDate, endDate]);

//   const handleFilterChange = async (filter) => {
//     try {
//       let response;
//       const instituteCode = sessionStorage.getItem("institutecode");

//       // Updated API call
//       response = await axios.get(
//         `https://pjsofttech.in:10443/presentandabsent?institutecode=${instituteCode}&filter=${filter}${
//           filter === "custom" && startDate && endDate
//             ? `&startDate=${startDate}&endDate=${endDate}`
//             : ""
//         }`
//       );

//       if (response && response.data) {
//         const presentEmployees = response.data.presentEmployees || [];
//         const absentEmployees = response.data.absentEmployees || [];

//         // Merge present and absent employees into one array
//         const combinedEmployees = [
//           ...presentEmployees.map((emp) => ({
//             ...emp,
//             status: emp.status || "On time",
//           })), // Status for present employees
//           ...absentEmployees.map((emp) => ({ ...emp, status: "Absent" })), // Status for absent employees
//         ];

//         setEmployees(combinedEmployees);
//         setPresentCount(presentEmployees.length);
//         setAbsentCount(absentEmployees.length);
//         setTotalEmployeeCount(presentEmployees.length + absentEmployees.length);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const fetchEmployeeCounts = async () => {
//     try {
//       const presentCount = await getPresentEmployeeCount();
//       const absentCount = await getAbsentEmployeeCount();
//       const totalEmployeeCount = await getTotalEmployeeCount();
//       setTotalEmployeeCount(totalEmployeeCount);
//       setPresentCount(presentCount);
//       setAbsentCount(absentCount);
//     } catch (error) {
//       console.error("Error fetching employee counts:", error);
//     }
//   };

//   const mergeAttendanceData = () => {
//     // Ensure employees is an array
//     const mergedData = (employees || []).map((employee) => {
//       return { ...employee };
//     });
//     return mergedData;
//   };

//   const filterAttendance = () => {
//     let mergedData = mergeAttendanceData();

//     // Filter by search term
//     if (search) {
//       mergedData = mergedData.filter(
//         (employee) =>
//           employee.name &&
//           employee.name.toLowerCase().includes(search.toLowerCase())
//       );
//     }

//     // Filter by status
//     // Changed the filtering logic to include both 'On time' and 'Late' for Present and ensure only Absent for Absent
//     if (statusFilter === "Present") {
//       mergedData = mergedData.filter(
//         (employee) =>
//           employee.status === "On time" || employee.status === "Late"
//       );
//     } else if (statusFilter === "Absent") {
//       mergedData = mergedData.filter(
//         (employee) => employee.status === "Absent"
//       ); // Ensure only Absent employees
//     }

//     setFilteredAttendance(mergedData);
//   };

//   return (
//     <>
//       <Container className="textField-root">
//         <Grid container spacing={2} sx={{ marginBottom: 3 }}>
//           <Grid item xs={12} md={3}>
//             <Card
//               sx={{
//                 backgroundColor: "#F9E79F",
//                 borderRadius: "10px",
//                 fontWeight: "bold",
//               }}
//             >
//               <CardContent>
//                 <Typography variant="h7" component="div">
//                   Total Employees
//                 </Typography>
//                 <Typography variant="h5" component="div">
//                   {totalEmployeeCount}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <Card
//               sx={{
//                 backgroundColor: "#FF6F61",
//                 borderRadius: "10px",
//                 fontWeight: "bold",
//               }}
//             >
//               <CardContent>
//                 <Typography variant="h7" component="div">
//                   Present Employees
//                 </Typography>
//                 <Typography variant="h5" component="div">
//                   {presentCount}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <Card
//               sx={{
//                 backgroundColor: "#3498DB",
//                 borderRadius: "10px",
//                 fontWeight: "bold",
//               }}
//             >
//               <CardContent>
//                 <Typography variant="h7" component="div">
//                   Absent Employees
//                 </Typography>
//                 <Typography variant="h5" component="div">
//                   {absentCount}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>


          
//         </Grid>

//         <Grid container spacing={1}>
//         <Grid item xs={3}>
//               <TextField
//                 fullWidth
//                 label="Search by name"
//                 variant="outlined"
//                 value={search}
//                 onChange={(e) => setSearch(e.target.value)}
//                 size="small"
//               />
//             </Grid>

//           <Grid item xs={3} >
            
//             <FormControl fullWidth variant="outlined">
//               <TextField
//                 value={statusFilter}
//                 onChange={(e) => setStatusFilter(e.target.value)}
//                 label="Status"
//                 select
//                 size="small"
//               >
//                 <MenuItem value="All">All</MenuItem>
//                 <MenuItem value="Present">Present</MenuItem>
//                 <MenuItem value="Absent">Absent</MenuItem>
//               </TextField>
//             </FormControl>
//           </Grid>
//           <Grid item xs={3} >
//             <FormControl fullWidth variant="outlined">
//               <TextField
//                 select
//                 value={timeFilter}
//                 onChange={(e) => setTimeFilter(e.target.value)}
//                 label="Filter by"
//                 size="small"
//               >
//                 {filterOptions.map((option) => (
//                   <MenuItem key={option} value={option}>
//                     {option}
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </FormControl>
//           </Grid>
//           {timeFilter === "custom" && (
//             <>
//               <Grid item xs={2} >
//                 <TextField
//                   fullWidth
//                   type="date"
//                   label="Start Date"
//                   variant="outlined"
//                   size="small"
//                   value={startDate}
//                   InputLabelProps={{ shrink: true }}
//                   onChange={(e) => setStartDate(e.target.value)}
//                 />
//               </Grid>
//               <Grid item xs={2}>
//                 <TextField
//                   fullWidth
//                   type="date"
//                   label="End Date"
//                   variant="outlined"
//                   value={endDate}
//                   size="small"
//                   InputLabelProps={{ shrink: true }}
//                   onChange={(e) => setEndDate(e.target.value)}
//                 />
//               </Grid>
//             </>
//           )}


//         </Grid>

//         <Box mt={2}>
//         <TableContainer >
//           <Table className="table-root">
//             <TableHead>
//               <TableRow>
//                 <TableCell>EmpID</TableCell>
//                 <TableCell>Name</TableCell>
//                 <TableCell>Date</TableCell>
//                 <TableCell>Shift</TableCell>
//                 <TableCell>Login</TableCell>
//                 <TableCell>Work Type</TableCell>
//                 <TableCell>Break In</TableCell>
//                 <TableCell>Break Out</TableCell>
//                 <TableCell>Break Minutes</TableCell>
//                 <TableCell>Logout</TableCell>
//                 <TableCell>Total Minutes</TableCell>
//                 <TableCell>Status</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {/* {filteredAttendance.map((employee) => ( */}
//               {filteredAttendance
//                 .sort((a, b) => b.empID - a.empID) // Sort in descending order based on empID
//                 .map((employee) => (
//                   <TableRow key={employee.id}>
//                     <TableCell>{employee.empID}</TableCell>
//                     <TableCell>{employee.fullName || employee.name}</TableCell>
//                     <TableCell>{employee.todaysDate}</TableCell>
//                     <TableCell>{employee.shift}</TableCell>
//                     <TableCell>{employee.loginTime || "N/A"}</TableCell>
//                     <TableCell>{employee.workType}</TableCell>
//                     <TableCell>{employee.breakIn || "N/A"}</TableCell>
//                     <TableCell>{employee.breakOut || "N/A"}</TableCell>
//                     <TableCell sx={{ fontWeight: "bold" }}>
//                       {employee.breakMinutes || "0"}
//                     </TableCell>
//                     <TableCell>{employee.logoutTime || "N/A"}</TableCell>
//                     <TableCell sx={{ fontWeight: "bold" }}>
//                       {employee.minutes || "N/A"}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: "bold",
//                         color:
//                           employee.status === "On time"
//                             ? "green"
//                             : employee.status === "Late"
//                             ? "orange"
//                             : employee.status === "Absent"
//                             ? "red"
//                             : "inherit",
//                       }}
//                     >
//                       {employee.status}
//                     </TableCell>
//                   </TableRow>
//                 ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         </Box>
//       </Container>
//     </>
//   );
// };

// export default TodaysAttendance;





import React, { useState, useEffect } from "react";
import {
  getPresentEmployeeCount,
  getAbsentEmployeeCount,
  getTotalEmployeeCount,
} from "../Attendance/attendanceService";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
  MenuItem,
  FormControl,
  Card,
  CardContent,
} from "@mui/material";
import axios from "axios";
import { styled } from "@mui/system";

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const TodaysAttendance = () => {
  const [employees, setEmployees] = useState([]);
  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [timeFilter, setTimeFilter] = useState("Today");
  const [filteredAttendance, setFilteredAttendance] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [workModeFilter, setWorkModeFilter] = useState("All"); // New state for work mode filter

  const filterOptions = ["Today", "Yesterday", "custom"].sort(); // Sort options
  const workModeOptions = ["All", "Work From Office", "Work From Home"].sort(); // Sort options

  useEffect(() => {
    fetchEmployeeCounts();
  }, []);

  useEffect(() => {
    filterAttendance();
  }, [search, statusFilter, employees, workModeFilter]); // Add workModeFilter to dependencies

  useEffect(() => {
    if (timeFilter !== "custom") {
      handleFilterChange(timeFilter);
    }
  }, [timeFilter]);

  useEffect(() => {
    if (timeFilter === "custom" && startDate && endDate) {
      handleFilterChange("custom");
    }
  }, [startDate, endDate]);

  const handleFilterChange = async (filter) => {
    try {
      let response;
      const instituteCode = sessionStorage.getItem("institutecode");

      response = await axios.get(
        `https://pjsofttech.in:10443/presentandabsent?institutecode=${instituteCode}&filter=${filter}${
          filter === "custom" && startDate && endDate
            ? `&startDate=${startDate}&endDate=${endDate}`
            : ""
        }`
      );

      if (response && response.data) {
        const presentEmployees = response.data.presentEmployees || [];
        const absentEmployees = response.data.absentEmployees || [];

        const combinedEmployees = [
          ...presentEmployees.map((emp) => ({
            ...emp,
            status: emp.status || "On time",
          })),
          ...absentEmployees.map((emp) => ({ ...emp, status: "Absent" })),
        ];

        setEmployees(combinedEmployees);
        setPresentCount(presentEmployees.length);
        setAbsentCount(absentEmployees.length);
        setTotalEmployeeCount(presentEmployees.length + absentEmployees.length);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchEmployeeCounts = async () => {
    try {
      const presentCount = await getPresentEmployeeCount();
      const absentCount = await getAbsentEmployeeCount();
      const totalEmployeeCount = await getTotalEmployeeCount();
      setTotalEmployeeCount(totalEmployeeCount);
      setPresentCount(presentCount);
      setAbsentCount(absentCount);
    } catch (error) {
      console.error("Error fetching employee counts:", error);
    }
  };

  const mergeAttendanceData = () => {
    const mergedData = (employees || []).map((employee) => {
      return { ...employee };
    });
    return mergedData;
  };

  const filterAttendance = () => {
    let mergedData = mergeAttendanceData();
  
    console.log("Initial Data:", mergedData);
  
    // Search filter
    if (search) {
      mergedData = mergedData.filter(
        (employee) =>
          employee.name &&
          employee.name.toLowerCase().includes(search.toLowerCase())
      );
    }
  
    // Status filter
    if (statusFilter === "Present") {
      mergedData = mergedData.filter(
        (employee) => employee.status === "On time" || employee.status === "Late"
      );
    } else if (statusFilter === "Absent") {
      mergedData = mergedData.filter(
        (employee) => employee.status === "Absent"
      );
    }
  
    // Work mode filter
    if (workModeFilter !== "All") {
      console.log("Filtering by work mode:", workModeFilter);
      mergedData = mergedData.filter(
        (employee) => employee.workType && employee.workType === workModeFilter
      );
    }
  
    console.log("Filtered Data:", mergedData);
  
    setFilteredAttendance(mergedData);
  };
  

  return (
    <>
      <Container className="textField-root">
        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
          <Grid item xs={12} md={3}>
            <Card
              sx={{
                backgroundColor: "#F9E79F",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              <CardContent>
                <Typography variant="h7" component="div">
                  Total Employees
                </Typography>
                <Typography variant="h5" component="div">
                  {totalEmployeeCount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card
              sx={{
                backgroundColor: "#FF6F61",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              <CardContent>
                <Typography variant="h7" component="div">
                  Present Employees
                </Typography>
                <Typography variant="h5" component="div">
                  {presentCount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card
              sx={{
                backgroundColor: "#3498DB",
                borderRadius: "10px",
                fontWeight: "bold",
              }}>
              <CardContent>
                <Typography variant="h7" component="div">
                  Absent Employees
                </Typography>
                <Typography variant="h5" component="div">
                  {absentCount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Search by name"
              variant="outlined"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="small"
            />
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                label="Status"
                select
                size="small"
              >
                {["All", "Present", "Absent"].sort().map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                select
                value={timeFilter}
                onChange={(e) => setTimeFilter(e.target.value)}
                label="Filter by"
                size="small"
              >
                {filterOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                select
                value={workModeFilter}
                onChange={(e) => setWorkModeFilter(e.target.value)}
                label="Work Type"
                size="small"
              >
                {workModeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          {timeFilter === "custom" && (
            <>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  type="date"
                  label="Start Date"
                  variant="outlined"
                  size="small"
                  value={startDate}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  type="date"
                  label="End Date"
                  variant="outlined"
                  value={endDate}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Box mt={2}>
          <TableContainer>
            <Table className="table-root">
              <TableHead>
                <TableRow>
                  <TableCell>EmpID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Shift</TableCell>
                  <TableCell>Login</TableCell>
                  <TableCell>Work Type</TableCell>
                  <TableCell>Break In</TableCell>
                  <TableCell>Break Out</TableCell>
                  <TableCell>Break Minutes</TableCell>
                  <TableCell>Logout</TableCell>
                  <TableCell>Total Minutes</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAttendance
                  .sort((a, b) => b.empID - a.empID)
                  .map((employee) => (
                    <TableRow key={employee.id}>
                      <TableCell>{employee.empID}</TableCell>
                      <TableCell>{employee.fullName || employee.name}</TableCell>
                      <TableCell>{employee.todaysDate}</TableCell>
                      <TableCell>{employee.shift}</TableCell>
                      <TableCell>{employee.loginTime || "N/A"}</TableCell>
                      <TableCell>{employee.workType}</TableCell>
                      <TableCell>{employee.breakIn || "N/A"}</TableCell>
                      <TableCell>{employee.breakOut || "N/A"}</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {employee.breakMinutes || "0"}
                      </TableCell>
                      <TableCell>{employee.logoutTime || "N/A"}</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {employee.minutes || "N/A"}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color:
                            employee.status === "On time"
                              ? "green"
                              : employee.status === "Late"
                              ? "orange"
                              : employee.status === "Absent"
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {employee.status}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </>
  );
};

export default TodaysAttendance;
