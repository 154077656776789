// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import {
//   Card,
//   CardContent,
//   Typography,
//   Avatar,
//   Button,
//   Box,
// } from "@mui/material";
// import html2pdf from "html2pdf.js";

// export default function IdCard({ id, onClose }) {
//   const institutecode = sessionStorage.getItem("institutecode");
//   const [admission, setAdmission] = useState({});
//   const [employeeDetails, setEmployeeDetails] = useState("");
//   const [selectedColor, setSelectedColor] = useState("#FF564E"); // Default color

//   useEffect(() => {
//     const fetchEmployeeDetails = async () => {
//       try {
//         if (!institutecode) {
//           console.error("No institutecode found in sessionStorage");
//           return;
//         }

//         const response = await axios.get(
//           `https://pjsofttech.in:20443/findInstitutesby/Institutecode?institutecode=${institutecode}`
//         );
//         setEmployeeDetails(response.data);
//       } catch (error) {
//         console.error("Error fetching employee details:", error);
//       }
//     };

//     fetchEmployeeDetails();
//   }, [institutecode]);

//   useEffect(() => {
//     const loadAdmission = async () => {
//       if (id) {
//         try {
//           const result = await axios.get(
//             `https://pjsofttech.in:13443/admissions/${id}`
//           );
//           setAdmission(result.data);
//         } catch (error) {
//           console.error("Error fetching Admission:", error);
//         }
//       }
//     };
//     loadAdmission();
//   }, [id]);

//   const downloadPdf = () => {
//     const element = document.getElementById("pdf-container");

//     html2pdf()
//       .set({
//         filename: `ID_Card_${admission.name}.pdf`,
//         html2canvas: {
//           scale: 3,
//           useCORS: true,
//         },
//         jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
//       })
//       .from(element)
//       .save();
//   };

//   const colorOptions = [
//     "#FF564E", // Gradient color 1
//     "#3F51B5", // Solid color
//     "#009688", // Solid color
//     "#FF9800", // Gradient color 2
//     "#000080", // Solid color (light pink, replacing brown)
//     "#800080", // Solid color (purple, replacing green)
//     "#2196F3", // Solid color (blue)
//     "#9C27B0", // Solid color (purple)
//     "#A9A9A9", // Solid color (amber)
//     "#E91E63", // Solid color (pink)
// ];

//   return (
//     <div>
//       {/* Color selection buttons */}
//       {/* <Box display="flex" justifyContent="center" mb={2}>
//         {colorOptions.map((color, index) => (
//           <Button
//             key={index}
//             onClick={() => setSelectedColor(color)}
//             style={{
//               backgroundColor: color,
//               width: 30,
//               height: 30,
//               minWidth: 0,
//               borderRadius: "50%",
//               margin: "0 5px",
//               border: color === selectedColor ? "2px solid black" : "none",
//             }}
//           />
//         ))}
//       </Box> */}

//       <Box display="flex" justifyContent="center" flexWrap="wrap" mb={2}>
//         {colorOptions.map((color, index) => (
//           <Button
//             key={index}
//             onClick={() => setSelectedColor(color)}
//             style={{
//               backgroundColor: color,
//               width: 30,
//               height: 30,
//               minWidth: 0,
//               borderRadius: "50%",
//               margin: "5px",
//               border: color === selectedColor ? "2px solid black" : "none",
//             }}
//           />
//         ))}
//       </Box>

//       {/* PDF Container with top padding */}
//       <div id="pdf-container" style={{ paddingTop: "20px" }}>
//         <div id="id-card">
//           <Card
//             sx={{
//               maxWidth: 300,
//               margin: "auto",
//               boxShadow: 3,
//               borderRadius: 5,
//               padding: "20px",
//               backgroundColor: "#fff",
//               height: 530,
//             }}
//           >
//             {/* Institute details and header with dynamic background color */}
//             <Typography
//               style={{
//                 textAlign: "center",
//                 background:
//                   selectedColor === "#FF564E" || selectedColor === "#FF9800"
//                     ? `linear-gradient(to right, #FAD126, ${selectedColor})`
//                     : selectedColor, // Directly apply the solid color for other options
//                 color: "white",
//                 paddingTop: "40px",
//                 paddingBottom: "10px",
//                 marginBottom: "15px",
//                 marginTop: "-50px",
//                 width: "150%",
//                 borderRadius: "50%",
//                 marginLeft: "-60px",
//               }}
//             >
//               <img
//                 src={employeeDetails.instituteimage}
//                 alt="Institute Logo"
//                 style={{
//                   width: "10%",
//                   height: "10%",
//                   marginRight: "10px",
//                   verticalAlign: "middle",
//                   marginBottom: "5px",
//                   borderRadius: "50%",
//                 }}
//               />
//               {employeeDetails.institutename}
//               <br />
//               <Typography style={{ textAlign: "center", fontSize: "12px" }}>
//                 {employeeDetails.emailaddress}
//               </Typography>
//               <Typography style={{ textAlign: "center", fontSize: "12px" }}>
//                 {employeeDetails.phonenumber}
//               </Typography>
//             </Typography>

//             <CardContent>
//               <Avatar
//                 src={admission.studentImage}
//                 alt="Student Image"
//                 sx={{ width: 120, height: 120, margin: "auto" }}
//               />
//               <Typography
//                 variant="h6"
//                 align="center"
//                 gutterBottom
//                 style={{ marginTop: "10px" }}
//               >
//                 <span style={{ fontWeight: "bold" }}>{admission.name}</span>
//                 <Typography style={{ fontSize: "13px", color: "#757575" }}>
//                   {admission.mobile1}
//                 </Typography>
//                 <Typography style={{ fontSize: "13px", color: "#757575" }}>
//                   {admission.email}
//                 </Typography>
//               </Typography>
//               <div style={{ textAlign: "center" }}>
//                 <Typography
//                   variant="body1"
//                   mt={2}
//                   style={{ textAlign: "left" }}
//                 >
//                   <strong>Course: </strong> {admission.courses}
//                 </Typography>
//                 <Typography variant="body1" style={{ textAlign: "left" }}>
//                   <strong>Joining Date: </strong> {admission.date}
//                 </Typography>
//                 <Typography variant="body1" style={{ textAlign: "left" }}>
//                   <strong>Duration: </strong> {admission.duration}
//                 </Typography>
//               </div>
//             </CardContent>
//             <Typography
//               style={{
//                 textAlign: "center",
//                 background:
//                   selectedColor === "#FF564E" || selectedColor === "#FF9800"
//                     ? `linear-gradient(to right, #FAD126, ${selectedColor})`
//                     : selectedColor, // Directly apply the solid color for other options
//                 color: "white",
//                 paddingTop: "40px",
//                 width: "350px",
//                 borderRadius: "70%",
//                 marginLeft: "-50px",
//                 height: 200,
//               }}
//             ></Typography>
//           </Card>
//         </div>
//       </div>

//       {/* Hidden download button */}
//       <Button
//         id="download-button"
//         onClick={downloadPdf}
//         style={{ display: "none" }}
//       >
//         Download as PDF
//       </Button>
//     </div>
//   );
// }

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  Box,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import { SketchPicker } from "react-color";

export default function IdCard({ id, onClose }) {
  const institutecode = sessionStorage.getItem("institutecode");
  const [admission, setAdmission] = useState({});
  const [employeeDetails, setEmployeeDetails] = useState("");
  const [selectedColor, setSelectedColor] = useState("#FF564E"); // Default color
  const [rules, setRules] = useState([]);
  const [showRules, setShowRules] = useState(false);
  const [textColor, setTextColor] = useState("white");
  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        if (!institutecode) {
          console.error("No institutecode found in sessionStorage");
          return;
        }

        const response = await axios.get(
          `https://pjsofttech.in:20443/findInstitutesby/Institutecode?institutecode=${institutecode}`
        );
        setEmployeeDetails(response.data);
      } catch (error) {
        console.error("Error fetching employee details:", error);
      }
    };

    fetchEmployeeDetails();
  }, [institutecode]);

  useEffect(() => {
    const loadAdmission = async () => {
      if (id) {
        try {
          const result = await axios.get(
            `https://pjsofttech.in:13443/admissions/${id}`
          );
          setAdmission(result.data);
        } catch (error) {
          console.error("Error fetching Admission:", error);
        }
      }
    };
    loadAdmission();
  }, [id]);

  const fetchRules = async () => {
    if (showRules) {
      setShowRules(false);
      setRules([]);
    } else {
      try {
        const response = await axios.get(
          `https://pjsofttech.in:13443/getAllRulesAndRegulations?institutecode=${institutecode}`
        );
        setRules(response.data); // Make sure response.data is an array of objects
        setShowRules(true);
      } catch (error) {
        console.error("Error fetching rules and regulations:", error);
      }
    }
  };

  const calculateBrightness = (hex) => {
    const r = parseInt(hex.substr(1, 2), 16);
    const g = parseInt(hex.substr(3, 2), 16);
    const b = parseInt(hex.substr(5, 2), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const handleColorChange = (color) => {
    const brightness = calculateBrightness(color.hex);
    setTextColor(brightness > 200 ? "black" : "white");
    setSelectedColor(color.hex);
  };

  const downloadPdf = () => {
    const element = document.getElementById("pdf-container");

    if (!element) {
      console.error("PDF container not found");
      return;
    }

    const originalStyle = element.style.cssText;
    element.style.cssText += `
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      padding: 20px;
      box-sizing: border-box;
    `;

    html2pdf()
      .set({
        filename: `ID_Card_${admission.name || "student"}.pdf`,
        html2canvas: {
          scale: 3,
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      })
      .from(element)
      .save()
      .then(() => {
        element.style.cssText = originalStyle;
      })
      .catch((error) => {
        console.error("PDF generation error:", error);
        element.style.cssText = originalStyle;
      });
  };

  return (
    <div>
      <Box display="flex" justifyContent="center" flexWrap="wrap" mb={2}>
        <Button variant="contained" color="primary" onClick={fetchRules}>
          {showRules ? "Hide Rules" : "Add Rules and Regulations"}
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        flexWrap="nowrap"
        gap={3}
      >
          <SketchPicker color={selectedColor} onChangeComplete={handleColorChange} />

        {/* PDF Container with top padding */}
        <div id="pdf-container">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            flexWrap="nowrap"
            gap={3}
          >
            <Card
              sx={{
                maxWidth: 300,
                boxShadow: 3,
                borderRadius: 5,
                padding: "20px",
                backgroundColor: "#fff",
                height: 530,
              }}
            >
              {/* Institute details and header with dynamic background color */}
              <Typography
                style={{
                  textAlign: "center",
                  background:
                    selectedColor === "#FF564E" || selectedColor === "#FF9800"
                      ? `linear-gradient(to right, #FAD126, ${selectedColor})`
                      : selectedColor, // Directly apply the solid color for other options
                  // color: "white",
                  paddingTop: "40px",
                  paddingBottom: "10px",
                  marginBottom: "15px",
                  marginTop: "-50px",
                  width: "150%",
                  borderRadius: "50%",
                  marginLeft: "-60px",
                  color: textColor
                }}
              >
                <img
                  src={employeeDetails.instituteimage}
                  alt="Institute Logo"
                  style={{
                    width: "10%",
                    height: "10%",
                    marginRight: "10px",
                    marginBottom: "5px",
                    borderRadius: "50%",
                    marginLeft: "-60px",
                  }}
                />
                {employeeDetails.institutename}
                <br />
                <Typography style={{ fontSize: "12px", color: textColor }}>
                {employeeDetails.address}
              </Typography>
              <Typography style={{ fontSize: "12px", color: textColor }}>
                <strong>Contact:</strong> {employeeDetails.phonenumber}
              </Typography>
            </Typography>
            <CardContent>
              <Avatar
                src={admission.studentImage}
                alt="Student Image"
                sx={{ width: 120, height: 120, margin: "auto" }}
              />
              <Typography variant="h6" align="center" gutterBottom>
                {admission.name}
              </Typography>
                <div style={{ textAlign: "center" }}>
                <Typography                  
                    mt={2}
                    style={{fontSize:"14px", textAlign: "left" }}
                  >
                    <strong>DOB: </strong> {admission.dob}
                  </Typography>
                  <Typography
                    style={{fontSize:"14px", textAlign: "left" }}
                  >
                    <strong>Gender: </strong> {admission.gender}
                  </Typography>
                  <Typography
                    style={{fontSize:"14px", textAlign: "left" }}
                  >
                    <strong>Course: </strong> {admission.courses}
                  </Typography>
                  <Typography style={{fontSize:"14px", textAlign: "left" }}>
                    <strong>Roll No: </strong> {admission.rollNo}
                  </Typography>
                  <Typography style={{fontSize:"14px", textAlign: "left" }}>
                    <strong>Batch: </strong> {admission.classRoom?.batchName}
                  </Typography>
                  <Typography style={{fontSize:"14px", textAlign: "left" }}>
                    <strong>Joining Date: </strong> {admission.date}
                  </Typography>
                  <Typography style={{fontSize:"14px", textAlign: "left" }}>
                    <strong>Duration: </strong> {admission.duration}
                  </Typography>
                </div>
              </CardContent>
              <Typography
                style={{
                  textAlign: "center",
                  background:
                    selectedColor === "#FF564E" || selectedColor === "#FF9800"
                      ? `linear-gradient(to right, #FAD126, ${selectedColor})`
                      : selectedColor, // Directly apply the solid color for other options
                  color: "white",
                  paddingTop: "40px",
                  width: "350px",
                  borderRadius: "70%",
                  marginLeft: "-50px",
                  height: 200,
                }}
              ></Typography>
            </Card>
            <Card
              sx={{
                maxWidth: 300,
                boxShadow: 3,
                borderRadius: 5,
                padding: "20px",
                backgroundColor: "#fff",
                height: 530,
              }}
            >
              {/* Institute details and header with dynamic background color */}
              <Typography
                style={{
                  textAlign: "center",
                  background:
                    selectedColor === "#FF564E" || selectedColor === "#FF9800"
                      ? `linear-gradient(to right, #FAD126, ${selectedColor})`
                      : selectedColor, // Directly apply the solid color for other options
                  color: "white",
                  paddingTop: "40px",
                  paddingBottom: "10px",
                  marginTop: "-40px",
                  width: "150%",
                  borderRadius: "50%",
                  marginLeft: "-60px",
                  height: 100,
                }}
              ></Typography>

              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    mt={2}
                    style={{ textAlign: "left" }}
                  >
                    <strong>Current Address: </strong>{" "}
                    {admission.currentAddress}
                  </Typography>
                  <Typography variant="body2" style={{ textAlign: "left" }} mt={2}>
                    <strong>Permanent Address: </strong>{" "}
                    {admission.permanentAddress}
                  </Typography>
                  {/* <Typography variant="body2" style={{ textAlign: "left" }}>
                  <strong>Email:</strong> {admission.email}
                </Typography> */}
                 <Typography variant="body2" style={{textAlign: "left" }} mt={2}>
                    <strong>Contact No: </strong> {admission.mobile1}
                  </Typography>
                  <Typography variant="body2" style={{ textAlign: "left" }} mt={2}>
                    <strong>Parent Mobile: </strong> {admission.mobile2}
                  </Typography>
                  {showRules && rules.length > 0 && (
                    <div>
                      <Typography style={{fontSize:"14px", textAlign:"left"}} mt={1}>
                        <strong>Rules and Regulations:</strong>
                      </Typography>
                      <ol style={{fontSize:"12px", marginLeft: "-10px"}}>
                        {rules.map((rule, index) => (
                          <li key={rule.id}>
                            <Typography style={{fontSize:"12px", textAlign: "left" }}>
                              {rule.rulesAndRegulations}
                            </Typography>
                          </li>
                        ))}
                      </ol>
                    </div>
                  )}
                </div>
              </CardContent>
              <Typography
                style={{
                  textAlign: "center",
                  background:
                    selectedColor === "#FF564E" || selectedColor === "#FF9800"
                      ? `linear-gradient(to right, #FAD126, ${selectedColor})`
                      : selectedColor, // Directly apply the solid color for other options
                  color: "white",
                  paddingTop: "40px",
                  width: "350px",
                  borderRadius: "70%",
                  marginLeft: "-50px",
                  marginTop: "60px",
                  height: 300,
                }}
              ></Typography>
            </Card>
          </Box>
        </div>
      </Box>
      {/* Hidden download button */}
      <Button
        id="download-button"
        onClick={downloadPdf}
        style={{ display: "none" }}
      >
        Download as PDF
      </Button>
    </div>
  );
}
