import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  MenuItem,
  IconButton,
  DialogContentText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Swal from "sweetalert2";


import {
  getAllMaterials,
  getAllMaterialTypes,
  getAllCategories,
  getSubcategoriesByCategory,
  deleteMaterialById,
  updateMaterialById,
} from "./API/AllApi"; // Import the API function
import UpdateMaterial from "./UpdateMaterial";

import { Worker, Viewer } from "@react-pdf-viewer/core"; // Import PDF Viewer components
import { zoomPlugin } from "@react-pdf-viewer/zoom"; // Import zoom plugin

// Import required styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
const EbookReport = () => {
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(""); // State for PDF URL
  const institutecode = () => sessionStorage.getItem("institutecode");
  const [openPdfDialog, setOpenPdfDialog] = useState(false); // State for PDF Dialog
  const API_URL = "https://pjsofttech.in:21443";
  const [filters, setFilters] = useState({
    materialTypes: "",
    chapterName: "",
    categoryName: "",
    subcategoryName: "",
  });

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const data = await getAllMaterials();
        setMaterials(data);
        setFilteredMaterials(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchMaterials();
  }, []);

  useEffect(() => {
    const fetchMaterialTypes = async () => {
      try {
        const data = await getAllMaterialTypes();
        setMaterialTypes(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchMaterialTypes();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getAllCategories();
        setCategories(
          data.map((cat) => ({ id: cat.id, name: cat.categoryName }))
        );
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const fetchSubcategories = async (selectedCategory) => {
    if (!selectedCategory) {
      setSubcategories([]);
      return;
    }
    try {
      const data = await getSubcategoriesByCategory(selectedCategory);
      setSubcategories(
        data.map((subCat) => ({ id: subCat.id, name: subCat.subcategoryName }))
      );
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };
  const fetchCategoriesByMaterialType = async (materialTypeName) => {
    try {
      const response = await axios.get(`${API_URL}/bymaterialtype?institutecode=${institutecode()}`, {
        params: { materialTypeName },
      });
      setCategories(response.data.map((cat) => ({ id: cat.id, name: cat.categoryName })));
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));

    if (name === "materialTypes") {
      fetchCategoriesByMaterialType(value); // Fetch categories based on material type
      setFilters((prevFilters) => ({ ...prevFilters, categoryName: "", subcategoryName: "" }));
      setSubcategories([]);
    } else if (name === "categoryName") {
      fetchSubcategories(value); // Fetch subcategories based on category
    }

    filterMaterials({ ...filters, [name]: value });
  };
  const filterMaterials = (currentFilters) => {
    const { materialTypes, chapterName, categoryName, subcategoryName } =
      currentFilters;

    const filtered = materials.filter((material) => {
      return (
        (!materialTypes || material.materialtype === materialTypes) &&
        (!chapterName ||
          material.chapterName
            .toLowerCase()
            .includes(chapterName.toLowerCase())) &&
        (!categoryName || material.categoryName === categoryName) &&
        (!subcategoryName || material.subcategoryName === subcategoryName)
      );
    });

    setFilteredMaterials(filtered);
  };

  const handleDeleteOpen = (id) => {
    setDeleteId(id);
  };

  const handleDeleteConfirm = async (id) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
  
    if (result.isConfirmed) {
      try {
        // Proceed with deletion if confirmed
        await deleteMaterialById(id);
  
        // Update materials and filteredMaterials lists after deletion
        setMaterials((prev) => prev.filter((mat) => mat.id !== id));
        setFilteredMaterials((prev) => prev.filter((mat) => mat.id !== id));
  
        // Show success notification
        Swal.fire("Deleted!", "The material has been deleted.", "success");
      } catch (error) {
        // Show error notification if deletion fails
        Swal.fire("Error!", "There was an error deleting the material.", "error");
        console.error("Error deleting material:", error);
      }
    }
  };
  

  const handleEditOpen = (material) => {
    setSelectedMaterial(material);
    setEditMode(true);
    setOpenDialog(true);
  };

  const handleUpdateMaterial = (updatedMaterial) => {
    // Update the main materials list
    setMaterials((prevMaterials) =>
      prevMaterials.map((material) =>
        material.id === updatedMaterial.id ? updatedMaterial : material
      )
    );

    // Update the filtered materials list (if applicable)
    setFilteredMaterials((prevMaterials) =>
      prevMaterials.map((material) =>
        material.id === updatedMaterial.id ? updatedMaterial : material
      )
    );
  };

  const handlePdfOpen = (url) => {
    console.log("Opening PDF URL:", url); // Check the URL in the console
    setPdfUrl(url);
    setOpenPdfDialog(true);
  };
  // Initialize zoom plugin
  const zoomPluginInstance = zoomPlugin();
  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid container spacing={2} style={{ marginBottom: "20px" }} className="textField-root">
          <Grid item xs={3}>
              {materialTypes && (
                <Autocomplete
                  options={materialTypes}
                  getOptionLabel={(option) => option.materialtype || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Material Type"
                      name="materialTypes"
                      onChange={handleFilterChange}
                    />
                  )}
                  onChange={(event, newValue) => {
                    handleFilterChange({
                      target: { name: "materialTypes", value: newValue?.materialtype || "" },
                    });
                  }}
                />
              )}
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Category"
                select
                fullWidth
                name="categoryName"
                onChange={handleFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.name}>
                    {category.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Subcategory"
                select
                fullWidth
                name="subcategoryName"
                onChange={handleFilterChange}
                disabled={!filters.categoryName}
              >
                <MenuItem value="">All</MenuItem>
                {subcategories &&
                  subcategories.map((subcategory) => (
                    <MenuItem key={subcategory.id} value={subcategory.name}>
                      {subcategory.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Subject Name"
                name="chapterName"
                onChange={handleFilterChange}
                fullWidth
              />
            </Grid>
          </Grid>

          <TableContainer>
            <Table className="table-root">
              <TableHead>
                <TableRow>
                  <TableCell>Cover</TableCell>
                  <TableCell>Material</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Subcategory</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>MRP</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Validity(M)</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>PDF</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMaterials.map((material) => (
                  <TableRow key={material.id}>
                    <TableCell>
                      <img
                        src={material.thumbnailFile}
                        alt={material.materialtype}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </TableCell>
                    <TableCell>{material.materialtype}</TableCell>
                    <TableCell>{material.categoryName}</TableCell>
                    <TableCell>{material.subcategoryName}</TableCell>
                    <TableCell>{material.chapterName}</TableCell>
                    <TableCell>₹{material.mrp}</TableCell>
                    <TableCell>₹{material.price}</TableCell>
                    <TableCell>{material.validity}</TableCell>
                    <TableCell>{material.discription}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handlePdfOpen(material.pdfFile)} // Open PDF dialog
                      >
                        View PDF
                      </Button>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditOpen(material)}
                        title="Edit"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
  sx={{ color: "red" }}
  onClick={() => handleDeleteConfirm(material.id)}
  title="Delete"
>
  <DeleteIcon />
</IconButton>

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <Dialog open={Boolean(deleteId)} onClose={() => setDeleteId(null)}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this material?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteId(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <UpdateMaterial
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        selectedMaterial={selectedMaterial}
        onUpdate={handleUpdateMaterial}
        materialTypes={materialTypes}
      />

      {/* PDF Preview Dialog */}
      <Dialog
        open={openPdfDialog}
        onClose={() => setOpenPdfDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>PDF Preview</DialogTitle>
        <DialogContent dividers>
          {pdfUrl && (
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={pdfUrl}
                plugins={[zoomPluginInstance]} // Add zoom plugin here
                onLoadError={(error) =>
                  console.error("Error loading PDF", error)
                }
              />
            </Worker>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPdfDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EbookReport;
