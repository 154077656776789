// src/api.js
import axios from "axios";

const API_URL = "https://pjsofttech.in:21443";
const institutecode = () => sessionStorage.getItem("institutecode");

export const getAllMaterials = async () => {
  try {
    const response = await axios.get(`${API_URL}/getallMaterial?institutecode=${institutecode()}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching materials:", error);
    throw error;
  }
};

export const getAllMaterialTypes = async () => {
  try {
    const response = await axios.get(`${API_URL}/getalltype?institutecode=${institutecode()}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching materials:", error);
    throw error;
  }
};

// Fetch all categories
export const getAllCategories = async () => {
  const response = await axios.get(`https://pjsofttech.in:21443/getallCategory?institutecode=${institutecode()}`);
  return response.data;
};

// Fetch subcategories by category name
export const getSubcategoriesByCategory = async (categoryName) => {
  const response = await axios.get(
    `https://pjsofttech.in:21443/subcategoryByCategory?categoryName=${categoryName}&institutecode=${institutecode()}`
  );
  return response.data;
};

// Add a new material
export const addMaterial = async (formData) => {
  const response = await axios.post(
    `https://pjsofttech.in:21443/addMaterial?institutecode=${institutecode()}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

// Delete a material by ID
export const deleteMaterialById = async (id) => {
  const response = await axios.delete(`${API_URL}/deleteByMaterial/${id}`);
  return response.data;
};

// Update material by ID
export const updateMaterialById = async (id, updatedData) => {
  try {
    const formData = new FormData();
    formData.append("materialtype", updatedData.materialtype);
    formData.append("saveToDevice", updatedData.saveToDevice);
    formData.append("status", updatedData.status);
    formData.append("mrp", updatedData.mrp);
    formData.append("price", updatedData.price);
    formData.append("validity", updatedData.validity);
    formData.append("chapterName", updatedData.chapterName);
    formData.append("discription", updatedData.discription);
    formData.append("subcategoryName", updatedData.subcategoryName);

    if (updatedData.demoPdf) {
      formData.append("demoPdf", updatedData.demoPdf);
    }
    if (updatedData.pdfFile) {
      formData.append("pdfFile", updatedData.pdfFile);
    }
    if (updatedData.thumbnailFile) {
      formData.append("thumbnailFile", updatedData.thumbnailFile);
    }

    const response = await axios.put(`${API_URL}/updateMaterial/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Failed to update material");
  }
};

// Fetch material by ID (optional, for pre-filling edit form)
export const getMaterialById = async (id) => {
  const response = await axios.get(`${API_URL}/getMaterial/${id}`);
  return response.data;
};



// Add a new material type
export const addMaterialType = async (materialtype) => {
    try {
      const response = await axios.post(`${API_URL}/addMaterialType?institutecode=${institutecode()}`, {
        materialtype,
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to add material');
    }
  };

  // Update material type by ID
export const updateMaterialTypeById = async (id, materialtype) => {
    try {
      const response = await axios.put(`${API_URL}/updateMaterial/${id}`, {
        materialtype,
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to update material');
    }
  };

  export const addCategory = async (categoryData) => {
    try {
      const response = await axios.post(`${API_URL}/addCategory?institutecode=${institutecode()}`, categoryData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure multipart/form-data is used for form data
        },
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to add category');
    }
  };

//get all user detail who purchased material
  export const getAllUserMaterial = async () => {
    try {
      const response = await axios.get(`${API_URL}/getalluserMaterial?institutecode=${institutecode()}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching user materials:", error);
      throw error;
    }
  };

  export const deleteMaterialType = async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/deletematerial/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting material type:", error);
      throw error;
    }
  };


  export const updateMaterialType = async (id, materialtype) => {
    try {
      const response = await axios.put(`${API_URL}/updatematerialbyid/${id}`, { materialtype });
      return response.data;
    } catch (error) {
      console.error("Error updating material type:", error);
      throw error;
    }
  };

  export const fetchSubcategories = (categoryId, categoryName) => {
    return axios.get(`${API_URL}/subcategoryByCategory?institutecode=${institutecode()}`, {
      params: { categoryId, categoryName },
    });
  };
  
  export const addSubCategory = (subcategoryName, categoryId) => {
    return axios.post(`${API_URL}/addsubcategory?institutecode=${institutecode()}`, {
      subcategoryName,
      categoryId,
    });
  };
  
  export const updateSubCategory = (subcategoryId, updatedName, categoryId) => {
    return axios.put(`${API_URL}/updateSubCategory/${subcategoryId}`, {
      subcategoryName: updatedName,
      categoryId,
    });
  };
  
  export const deleteSubCategory = (subcategoryId) => {
    return axios.delete(`${API_URL}/deleteSubcategory/${subcategoryId}`);
  };


// AllApi.js
export async function getAllCategoriesByMaterialType(materialTypeName) {
  const response = await fetch(`${API_URL}/bymaterialtype?materialTypeName=${materialTypeName}&institutecode=${institutecode()}`);
  
  if (!response.ok) {
    throw new Error("Failed to fetch categories");
  }
  
  const data = await response.json();
  return data;
}

