// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Box, Typography, CircularProgress, Grid, Button } from "@mui/material";
// import { jsPDF } from "jspdf";
// import FileDownloadIcon from '@mui/icons-material/FileDownload';

// export default function QrCode() {
//   const [qrcode, setQrcode] = useState(""); // Store the QR code data
//   const [loading, setLoading] = useState(true); // State for loading indicator
//   const [employeeDetails, setEmployeeDetails] = useState(""); // Store employee details

//   const getInstituteCode = () => sessionStorage.getItem("institutecode");

//   useEffect(() => {
//     const fetchEmployeeDetails = async () => {
//       try {
//         if (!getInstituteCode()) {
//           console.error("No institutecode found in sessionStorage");
//           return;
//         }

//         const response = await axios.get(
//           `https://pjsofttech.in:20443/findInstitutesby/Institutecode?institutecode=${getInstituteCode()}`
//         );
//         setEmployeeDetails(response.data);
//       } catch (error) {
//         console.error("Error fetching employee details:", error);
//       }
//     };

//     fetchEmployeeDetails();
//   }, [getInstituteCode()]);

//   const fetchQrCode = async () => {
//     try {
//       const instituteCode = getInstituteCode();
//       if (!instituteCode) {
//         window.location.href = "/";
//         return;
//       }

//       // Change: Ensure the institute code is embedded in the QR code data
//       const response = await axios.get(
//         `https://pjsofttech.in:9443/generateQRcode?institutecode=${instituteCode}`, // Added encodeURIComponent
//         { responseType: "blob" }
//       );

//       const imageUrl = URL.createObjectURL(response.data);
//       setQrcode(imageUrl);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching QR code:", error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchQrCode();
//   }, []);

//   const downloadPdf = () => {
//     const doc = new jsPDF();
//     const pageWidth = doc.internal.pageSize.getWidth();
//     const pageHeight = doc.internal.pageSize.getHeight();
//     const borderPadding = 10;

//     doc.setLineWidth(0.2);
//     doc.rect(borderPadding, borderPadding, pageWidth - 2 * borderPadding, pageHeight - 2 * borderPadding);

//     if (employeeDetails?.institutename) {
//       const subTitle = employeeDetails.institutename;
//       doc.setFontSize(22);
//       const subTitleWidth = doc.getTextWidth(subTitle);
//       doc.text(subTitle, (pageWidth - subTitleWidth) / 2, 20);
//     }

//     const title = "Scan here to fill the Student form";
//     doc.setFontSize(18);
//     doc.text(title, (pageWidth - doc.getTextWidth(title)) / 2, 40);

//     const qrCodeSize = 100;
//     if (qrcode) {
//       doc.addImage(qrcode, "PNG", (pageWidth - qrCodeSize) / 2, 60, qrCodeSize, qrCodeSize);
//     }

//     const footer = "Made By - PJSOFTTECH PVT. LTD.";
//     doc.setFontSize(10);
//     doc.text(footer, (pageWidth - doc.getTextWidth(footer)) / 2, 180);

//     doc.save("QRCode.pdf");
//   };

//   return (
//     <div>
//       <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100vh", textAlign: "center" }}>
//         {loading ? (
//           <CircularProgress />
//         ) : qrcode ? (
//           <>
//             <Grid container alignItems="center" justifyContent="center" sx={{ backgroundColor: "#f0f4f8", padding: "16px", borderRadius: "10px", border: "0.5px solid lightgray", marginBottom: "16px" }}>
//               <Typography variant="h5" sx={{ fontWeight: "bold", color: "#333" }}>
//                 Organization - {employeeDetails.institutename}
//               </Typography>
//             </Grid>

//             <Box component="img" src={qrcode} alt="QR Code" sx={{ width: "350px", height: "350px", borderRadius: "8px", border: "2px solid #ddd", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", marginBottom: "16px" }} />

//             <Button variant="contained" color="primary" onClick={downloadPdf} sx={{ marginTop: "16px" }}>
//               <FileDownloadIcon /> Download PDF
//             </Button>
//           </>
//         ) : (
//           <Typography variant="h6" color="error">
//             Failed to load QR Code.
//           </Typography>
//         )}
//       </Box>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography, CircularProgress, Grid, Button } from "@mui/material";
import { jsPDF } from "jspdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function QrCode() {
  const [qrcode, setQrcode] = useState(""); // Store the QR code data
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [employeeDetails, setEmployeeDetails] = useState(""); // Store employee details

  const getInstituteCode = () => sessionStorage.getItem("institutecode");

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const instituteCode = getInstituteCode();
        if (!instituteCode) {
          console.error("No institutecode found in sessionStorage");
          return;
        }

        const response = await axios.get(
          `https://pjsofttech.in:20443/findInstitutesby/Institutecode?institutecode=${instituteCode}`
        );
        setEmployeeDetails(response.data);
      } catch (error) {
        console.error("Error fetching employee details:", error);
      }
    };

    fetchEmployeeDetails();
  }, []);

  const fetchQrCode = async () => {
    try {
      const instituteCode = getInstituteCode();
      if (!instituteCode) {
        console.error("No institutecode found. Redirecting...");
        window.location.href = "/";
        return;
      }

      // ✅ Encode institute code properly to prevent issues in mobile scanning
      const encodedInstituteCode = encodeURIComponent(instituteCode);
      const qrUrl = `https://pjsofttech.in:9443/generateQRcode?institutecode=${encodedInstituteCode}`;

      const response = await axios.get(qrUrl, { responseType: "blob" });

      const imageUrl = URL.createObjectURL(response.data);
      setQrcode(imageUrl);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching QR code:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQrCode();
  }, []);

  const downloadPdf = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const borderPadding = 10;

    // Draw border
    doc.setLineWidth(0.2);
    doc.rect(
      borderPadding,
      borderPadding,
      pageWidth - 2 * borderPadding,
      pageHeight - 2 * borderPadding
    );

    if (employeeDetails?.institutename) {
      const subTitle = employeeDetails.institutename;
      doc.setFontSize(22);
      const subTitleWidth = doc.getTextWidth(subTitle);
      doc.text(subTitle, (pageWidth - subTitleWidth) / 2, 20);
    }

    const title = "Scan here to fill the Student form";
    doc.setFontSize(18);
    doc.text(title, (pageWidth - doc.getTextWidth(title)) / 2, 40);

    const qrCodeSize = 100;
    if (qrcode) {
      doc.addImage(qrcode, "PNG", (pageWidth - qrCodeSize) / 2, 60, qrCodeSize, qrCodeSize);
    }

    const footer = "Made By - PJSOFTTECH PVT. LTD.";
    doc.setFontSize(10);
    doc.text(footer, (pageWidth - doc.getTextWidth(footer)) / 2, 180);

    doc.save("QRCode.pdf");
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : qrcode ? (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: "#f0f4f8",
                padding: "16px",
                borderRadius: "10px",
                border: "0.5px solid lightgray",
                marginBottom: "16px",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold", color: "#333" }}>
                Organization - {employeeDetails.institutename}
              </Typography>
            </Grid>

            <Box
              component="img"
              src={qrcode}
              alt="QR Code"
              sx={{
                width: "350px",
                height: "350px",
                borderRadius: "8px",
                border: "2px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                marginBottom: "16px",
              }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={downloadPdf}
              sx={{ marginTop: "16px" }}
            >
              <FileDownloadIcon /> Download PDF
            </Button>
          </>
        ) : (
          <Typography variant="h6" color="error">
            Failed to load QR Code.
          </Typography>
        )}
      </Box>
    </div>
  );
}
