import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid
} from '@mui/material';
import Swal from 'sweetalert2';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ViewSubCategories = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { categoryId, categoryName } = location.state || { categoryId: null, categoryName: '' };
  const [subCategories, setSubCategories] = useState([]);
  const [newSubCategoryName, setNewSubCategoryName] = useState('');
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDeleteSubcategoryDialog, setOpenDeleteSubcategoryDialog] = useState(false);
  const institutecode = () => sessionStorage.getItem("institutecode");
  const [subcategoryToDelete, setSubcategoryToDelete] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [subcategoryToEdit, setSubcategoryToEdit] = useState(null);
  const handleBackToCategories = () => {
    navigate('/Layout/category'); // Navigates back to the categories page
  };
  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(`https://pjsofttech.in:21443/getallSubcategory?institutecode=${institutecode()}`);
      const filteredSubCategories = response.data.filter(
        (subCategory) => subCategory.categoryId === categoryId
      );
      setSubCategories(filteredSubCategories);
    } catch (err) {
      Swal.fire('Error', 'Error fetching subcategories.', 'error');
      console.error('Fetch subcategories error:', err);
    }
  };

  // Fetch subcategories based on category ID when the component mounts
  useEffect(() => {
    if (categoryId) {
      fetchSubCategories();
    }
  }, [categoryId]);

  const handleEdit = (subCategory) => {
    setSubcategoryToEdit(subCategory);
    setNewSubCategoryName(subCategory.subcategoryName);
    setOpenEditDialog(true);
  };

  const handleUpdateSubCategory = async () => {
    if (!newSubCategoryName || !subcategoryToEdit || !categoryId) {
      Swal.fire('Error', 'Subcategory name or category ID is missing.', 'error');
      return;
    }

    try {
      await axios.put(
        `https://pjsofttech.in:21443/updateSubCategory/${subcategoryToEdit.id}`,
        {
          subcategoryName: newSubCategoryName,
          categoryId,
        }
      );
      setOpenEditDialog(false);
      setNewSubCategoryName('');

      Swal.fire('Success', 'Sub-category updated successfully.', 'success');
      fetchSubCategories(); // Refetch the updated list
    } catch (error) {
      Swal.fire('Error', 'Error updating subcategory.', 'error');
      console.error('Error updating subcategory:', error);
    }
  };

  const handleDeleteSubCategory = (subCategory) => {
    if (!subCategory || !subCategory.id) {
      Swal.fire('Error', 'Subcategory not found or already deleted.', 'error');
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to delete the sub-category "${subCategory.subcategoryName}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://pjsofttech.in:21443/deleteSubcategory/${subCategory.id}`);
          Swal.fire('Deleted!', 'Subcategory deleted successfully.', 'success');
          fetchSubCategories(); // Refetch the updated list
        } catch (error) {
          Swal.fire('Error', 'Error deleting subcategory. Please try again.', 'error');
          console.error('Delete subcategory error:', error);
        }
      }
    });
  };

  const handleAddSubCategory = async () => {
    if (!newSubCategoryName) {
      Swal.fire('Error', 'Sub-category name is required.', 'error');
      return;
    }

    try {
      await axios.post('https://pjsofttech.in:21443/addsubcategory', {
        subcategoryName: newSubCategoryName,
        categoryId,
      });

      setOpenAddDialog(false);
      setNewSubCategoryName('');

      Swal.fire('Success', 'Sub-category added successfully.', 'success');
      fetchSubCategories(); // Refetch the updated list
    } catch (err) {
      Swal.fire('Error', 'Error adding subcategory.', 'error');
      console.error('Add subcategory error:', err);
    }
  };

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Sub-Categories of {categoryName}
      </Typography>

      {/* Button to navigate back to categories */}
      <Button variant="contained" color="secondary" onClick={handleBackToCategories} style={{ marginBottom: '20px' }}>
        Back to Categories
      </Button>

      {/* Button to add a new sub-category */}
      <Button variant="contained" color="primary" onClick={() => setOpenAddDialog(true)} style={{ marginBottom: '20px' }}>
        Add Sub-Category
      </Button>

      <TableContainer style={{ marginTop: '20px' }}>
        <Table className='table-root'>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Sub-Category Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subCategories.length > 0 ? (
              subCategories.map((subCategory) => (
                <TableRow key={subCategory.id}>
                  <TableCell>{subCategory.id}</TableCell>
                  <TableCell>{subCategory.subcategoryName}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleEdit(subCategory)}>
                      Edit
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => handleDeleteSubCategory(subCategory)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography variant="h6">No subcategories available.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add Sub-Category Dialog */}
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogContent>
          <TextField
            label="Sub-Category Name"
            variant="outlined"
            fullWidth
            value={newSubCategoryName}
            onChange={(e) => setNewSubCategoryName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddSubCategory} color="primary">Add</Button>
          <Button onClick={() => setOpenAddDialog(false)} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Sub-Category Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Sub-Category</DialogTitle>
        <DialogContent>
          <TextField
            label="Sub-Category Name"
            variant="outlined"
            fullWidth
            value={newSubCategoryName}
            onChange={(e) => setNewSubCategoryName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateSubCategory} color="primary">Update</Button>
          <Button onClick={() => setOpenEditDialog(false)} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewSubCategories;
