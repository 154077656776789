// Import required dependencies
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2 for confirmation popups

const Medium = () => {
  const [mediums, setMediums] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentMedium, setCurrentMedium] = useState(null);
  const institutecode = sessionStorage.getItem('institutecode');
  const [filteredMediums, setFilteredMediums] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [formData, setFormData] = useState({
    mediumName: '',
  });

//   // Fetch all mediums
//   const fetchMediums = async (mid) => {
//     try {
//       const response = await axios.get(`https://pjsofttech.in:13443/MediumById/${mid}`); // Adjust the endpoint if needed
//       setMediums(response.data);
//     } catch (error) {
//       console.error('Error fetching mediums:', error);
//     }
//   };

//   useEffect(() => {
//     fetchMediums();
//   }, []);

  // Fetch all mediums based on institutecode
  const fetchMediums = async () => {
    try {
      // Fetch mediums by institutecode from the API
      const response = await axios.get(`https://pjsofttech.in:13443/MediumByInstitutecode?institutecode=${institutecode}`);
      setFilteredMediums(response.data); // Set fetched mediums to state
    } catch (error) {
      console.error('Error fetching mediums:', error);
    }
  };

  useEffect(() => {
    if (institutecode) {
      fetchMediums();
    }
  }, [institutecode]);

   // Handle search input change
   const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = mediums.filter((medium) =>
      medium.mediumName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredMediums(filtered);
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Open dialog for create or edit
  const handleOpen = (medium = null) => {
    setCurrentMedium(medium);
    setFormData(medium || { mediumName: '', institutecode: '' });
    setOpen(true);
  };

  // Close dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Handle form submission for create or update
  const handleSubmit = async () => {
    try {
      if (currentMedium) {
        // Update existing medium
        await axios.put(`https://pjsofttech.in:13443/updateMedium/${currentMedium.mid}`, formData);
        Swal.fire('Updated!', 'The medium has been updated.', 'success');
      } else {
        // Create new medium
        await axios.post(`https://pjsofttech.in:13443/SaveMedium`, formData, {
          params: { institutecode},
        });
      }
      Swal.fire('Added!', 'The medium has been added.', 'success');
      fetchMediums();
      handleClose();
    } catch (error) {
      console.error('Error saving medium:', error);
      Swal.fire('Error!', 'There was an error saving the medium. Please try again.', 'error');
    }
  };

  // Handle delete
  const handleDelete = async (mid) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this medium?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      });

      if (result.isConfirmed) {
        await axios.delete(`https://pjsofttech.in:13443/deleteMedium/${mid}`);
        Swal.fire('Deleted!', 'The medium has been deleted.', 'success');
        fetchMediums();
      }
    } catch (error) {
      console.error('Error deleting medium:', error);
      Swal.fire('Error!', 'There was an error while deleting the medium.', 'error');
    }
  };

  return (
<Paper elevation={3} sx={{padding: "0 20px 20px 20px", borderRadius: "8px"}}>
       <Grid container spacing={2} className="textField-root">
        <Typography
          variant="h6"
          gutterBottom
          sx={{ marginTop: 3, whiteSpace: "nowrap" }}
        >
              Total Medium: {mediums.length}
              </Typography>{" "}
              <Grid item xs={12} sm={3}>
          <TextField
            label="Search Medium"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearch}
          />
        </Grid>
              <Grid item xs={12} sm={3}>
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>
        Add Medium
      </Button>
      </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ marginTop: 3 }} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>No</TableCell>
              <TableCell>Medium Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMediums.map((medium) => (
              <TableRow key={medium.mid}>
                <TableCell>{medium.mid}</TableCell>
                <TableCell>{medium.mediumName}</TableCell>
                <TableCell>
                  <Button color="primary" onClick={() => handleOpen(medium)}>
                    <Edit />
                  </Button>
                  <Button color="error" onClick={() => handleDelete(medium.mid)}>
                    <Delete />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for create/edit */}
      <Dialog open={open} onClose={handleClose}  maxWidth="sm" className="textField-root">
        <DialogTitle color={'black'}>{currentMedium ? 'Edit Medium' : 'Add Medium'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Medium Name"
            name="mediumName"
            value={formData.mediumName}
            onChange={handleChange}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Medium;
