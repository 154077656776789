import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

const infoFields = {
  id: "ID",
  dateOfRegistration: "Date of Registration",
  standard: "Standard",
  mediumname: "Medium",
  year:"Year",
  streamName:"Stream",
  groupName:"Group",
  firstName: "First Name",
  middleName: "Middle Name",
  surname: "Last Name",
  full_name: "Full Name",
  gender: "Gender",
  bloodGroup: "Blood Group",
  motherTongue: "Mother Tongue",
  maritalStatus: "Marital Status",
  emailAddress: "Email Address",
  religion: "Religion",
  minority: "Minority",
  minorityType: "Minority Type",
  castCategory: "Caste Category",
  casteCertificateNumber: "Caste Certificate Number",
  casteValidation: "Caste Validity",
  casteValidationNumber: "Caste Validity Number",
  subCaste: "Sub Caste",
  dateOfBirth: "Date of Birth",
  age: "Age",
  birthPlace: "Birth Place",
  birthTaluka: "Birth Taluka",
  birthDistrict: "Birth District",
  birthState: "Birth State",
  birthCountry: "Birth Country",
  fathersName: "Father's Name",
  motherName: "Mother Name",
  fatherProfession: "Father's Profession",
  fathersContact: "Father's Contact",
  phoneNumber: "Phone Number",
  whatsappNumber: "WhatsApp Number",
  panNumber: "PAN Number",
  aadharNumber: "Aadhar Number",
  udiseNo: "UDISE Number",
  saralNo: "SARAL Number",
  incomeRanges: "Income",
  nationality: "Nationality",
  othernationality: "Other Nationality",
  sportYesNo: "Sports Participation",
  sportsName: "Sports Name",
  role: "Role",
  levelOfParticipation: "Level of Participation",
  internationaldetail: "International Details",
  noOfYearsPlayed: "Number of Years Played",
  achievement: "Achievement",
  sportsInjuries: "Sports Injuries",
  height: "Height",
  weight: "Weight",
  handicap: "Handicap",
  disabilityType: "Disability Type",
  specialPercentage: "Disability Percentage",
  domicilebool: "Domicile",
  domicileNumber: "Domicile Number",
  earthquake: "Earthquake Affected",
  earthquakeNumber: "Earthquake Affected Certificate Number",
  projectDifferentiated: "Project Affected",
  projectDifferentiatedNumber: "Project Affected Certificate Number",
  scholarship: "Scholarship",
  scholarshipName: "Scholarship Name",
  address: "Address",
  landmark: "Landmark",
  city: "City",
  taluka: "Taluka",
  district: "District",
  state: "State",
  country: "Country",
  pincode: "Pincode",
  permanentAddress: "Permanent Address",
  plandmark: "Landmark (Permanent)",
  pcity: "City (Permanent)",
  ptaluka: "Taluka (Permanent)",
  pdistrict: "District (Permanent)",
  pstate: "State (Permanent)",
  pcountry: "Country (Permanent)",
  ppincode: "Pincode (Permanent)",
};

const StudentInfo = ({ student, onClose }) => {
  return (
    <Dialog open={Boolean(student)} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle color={"black"}>Student Information</DialogTitle>
      <DialogContent>
        {/* Student photo section */}
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img
            src={student?.studentphoto}
            alt="Student Photo"
            style={{
              width: "150px",
              height: "150px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        </div>

        {/* Displaying student information fields */}
        {student && (
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {Object.keys(infoFields).map((field) => (
              <div
                key={field}
                style={{
                  flex: "0 0 50%", // Each field takes half of the width
                  boxSizing: "border-box",
                  padding: "4px",
                }}
              >
                <p>
                  <strong>{infoFields[field]}:</strong> {student[field] || "N/A"}
                </p>
              </div>
            ))}

            {/* Displaying exam fields */}
            <div
              style={{
                flex: "0 0 100%", // Full width for exam fields
                boxSizing: "border-box",
                padding: "4px",
              }}
            >
              <h3>Exam Details</h3>
              {student.exams && student.exams.length > 0 ? (
                student.exams.map((exam, index) => (
                  <div key={index} style={{ marginBottom: "10px" }}>
                    {Object.keys(exam).map((field) => (
                      <p key={field}>
                        <strong>
                          {field
                            .replace(/([A-Z])/g, " $1") // Convert camelCase to words
                            .replace(/^./, (str) => str.toUpperCase())}
                          :
                        </strong>{" "}
                        {String(exam[field])}
                      </p>
                    ))}
                  </div>
                ))
              ) : (
                <p>No Exam Details Available</p>
              )}
            </div>
          </div>
        )}

        {/* Student signature section */}
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <img
            src={student?.studentSign}
            alt="Student Signature"
            style={{ width: "150px", height: "50px" }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StudentInfo;
