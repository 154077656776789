import React, { useEffect, useState } from "react";
import { Pie } from "@nivo/pie";
import {
  Box,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import axios from "axios";

const TwoYearComparison = () => {
  const getInstituteCode = () => sessionStorage.getItem("institutecode");
  const [data, setData] = useState([]);
  const [year1, setYear1] = useState(2023);
  const [year2, setYear2] = useState(2024);
  const [metric, setMetric] = useState("count"); // Metric: "count" or "totalPayingAmount"

  const fetchData = async (y1, y2) => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:19443/revenueandcount/twoyears?year1=${y1}&year2=${y2}&institutecode=${getInstituteCode()}`
      );
      const responseData = response.data;

      // Transform API response based on selected metric
      const chartData = [
        {
          id: `${y1}`,
          label: `${y1}`,
          value: responseData[y1][metric],
        },
        {
          id: `${y2}`,
          label: `${y2}`,
          value: responseData[y2][metric],
        },
      ];

      setData(chartData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(year1, year2);
  }, [year1, year2, metric]);

  const handleYear1Change = (event) => {
    setYear1(event.target.value);
  };

  const handleYear2Change = (event) => {
    setYear2(event.target.value);
  };

  const toggleMetric = () => {
    setMetric((prevMetric) => (prevMetric === "count" ? "totalPayingAmount" : "count"));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
        className="textField-root"
      >
        {/* Title on the left */}
        <Typography style={{ fontWeight: "bold" }}>
          Yearly Comparison ({metric === "count" ? "Count" : "Revenue"})
        </Typography>

        {/* Dropdowns and Toggle Button on the right */}
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <FormControl style={{ minWidth: "100px" }}>
            <TextField
              select
              size="small"
              label="Year 1"
              value={year1}
              onChange={handleYear1Change}
            >
              {Array.from({ length: 15 }, (_, i) => 2024 - 7 + i).map(
                (year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                )
              )}
            </TextField>
          </FormControl>

          <FormControl style={{ minWidth: "100px" }}>
            <TextField
              select
              size="small"
              label="Year 2"
              value={year2}
              onChange={handleYear2Change}
            >
              {Array.from({ length: 15 }, (_, i) => 2024 - 7 + i).map(
                (year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                )
              )}
            </TextField>
          </FormControl>

          {/* Toggle Metric Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={toggleMetric}
            style={{
              backgroundColor: "#3498DB",
              color: "#fff",
              textTransform: "none",
            }}
          >
            {metric === "count" ? "Revenue" : "Count"}
          </Button>
        </div>
      </div>

      <Box style={{ marginTop: "16px" }}>
        <Box height={330}>
          <Pie
            data={data}
            width={300}
            height={300}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={5}
            colors={({ id }) => (id === `${year1}` ? "#3498DB" : "#FF6F61")}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            radialLabel={(d) => `${d.id}: ${d.value}`}
            sliceLabel={(d) => `${d.value}`}
          />
        </Box>
      </Box>
    </div>
  );
};

export default TwoYearComparison;
