import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Snackbar,
  Typography,
  MenuItem,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { SketchPicker } from "react-color";
import Swal from "sweetalert2";

const apiUrl = "https://pjsofttech.in:28443"; // Base URL for your backend API
const institutecode = () => sessionStorage.getItem("institutecode");

const Toppers = () => {
  const [toppers, setToppers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [topperId, setTopperId] = useState(null);
  const [name, setName] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [post, setPost] = useState("");
  const [rank, setRank] = useState("");
  const [year, setYear] = useState("");
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 6 }, (_, i) => currentYear + i);
  const [topperColor, setTopperColor] = useState("#000000");
  const [topperImage, setTopperImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");

  useEffect(() => {
    fetchToppers();
  }, []); // Fetch toppers on component mount

  const fetchToppers = () => {
    axios
      .get(`${apiUrl}/getAllToppers?institutecode=${institutecode()}`)
      .then((response) => {
        setToppers(response.data);
      })
      .catch((error) => console.error("Error fetching toppers:", error));
  };

  const handleDialogOpen = (id = null) => {
    if (id) {
      axios
        .get(`${apiUrl}/getTopperById/${id}`)
        .then((response) => {
          const { name, totalMarks, post, rank, year, topperImage, topperColor } = response.data;
          setTopperId(id);
          setName(name);
          setTopperColor(topperColor);
          setTotalMarks(totalMarks);
          setPost(post);
          setRank(rank);
          setYear(year);
          setImagePreview(topperImage); // Set image preview for editing
        })
        .catch((error) => console.error("Error fetching topper:", error));
    } else {
      setTopperId(null);
      setName("");
      setTopperColor("");
      setTotalMarks("");
      setPost("");
      setRank("");
      setYear("");
      setImagePreview(null);
    }
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setImagePreview(null); // Reset image preview when dialog closes
  };

  const handleImageChange = (event) => {
    setTopperImage(event.target.files[0]);
    setImagePreview(URL.createObjectURL(event.target.files[0])); // Show the selected image preview
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("topperColor", topperColor);
    formData.append("totalMarks", totalMarks);
    formData.append("post", post);
    formData.append("rank", rank);
    formData.append("year", year);
    if (topperImage) {
      formData.append("topperImage", topperImage);
    }

    if (topperId) {
      axios
        .put(`${apiUrl}/updateTopper/${topperId}`, formData)
        .then(() => {
          fetchToppers();
          Swal.fire("Success", "Topper updated successfully!", "success");
          handleDialogClose();
        })
        .catch((error) => {
          console.error("Error updating topper:", error);
          Swal.fire("Error", "Failed to Update Topper!", "error");
        });
    } else {
      axios
        .post(`${apiUrl}/createTopper?institutecode=${institutecode()}`, formData)
        .then(() => {
          fetchToppers();
          Swal.fire("Success", "Topper Created successfully!", "success");
          handleDialogClose();
        })
        .catch((error) => {
          console.error("Error creating topper:", error);
          Swal.fire("Error", "Failed to Save Topper!", "error");
        });
    }
  };

  const handleColorChange = (color) => {
    setTopperColor(color.hex);
  };

  const handleDeleteConfirmation = (id) => {
    setTopperId(id);
  
    // Show SweetAlert2 confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this topper!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        // If user confirms, proceed with deletion
        handleDelete(id);
      }
    });
  };
  
  const handleDelete = () => {
    axios
      .delete(`${apiUrl}/deleteTopper/${topperId}`)
      .then(() => {
        fetchToppers();
        setOpenDeleteDialog(false);
  
        // Show SweetAlert2 success pop-up
        Swal.fire(
          'Deleted!',
          'The topper has been deleted.',
          'success'
        );
      })
      .catch((error) => {
        console.error('Error deleting topper:', error);
  
        // Show SweetAlert2 error pop-up
        Swal.fire(
          'Error!',
          'Failed to delete the topper. Please try again later.',
          'error'
        );
      });
  };

  const handleImageClick = (imageName) => {
    const imageUrl = imageName.startsWith("http")
      ? imageName
      : `https://pjsofttech.in:28443/uploads/${imageName}`;
    setModalImageSrc(imageUrl);
    setOpenImageModal(true);
  };

  const handleModalClose = () => {
    setOpenImageModal(false);
    setModalImageSrc("");
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleDialogOpen()}
      >
        Create Topper
      </Button>

      <TableContainer component={Paper} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Total Marks</TableCell>
              <TableCell>Post</TableCell>
              <TableCell>Rank</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toppers.map((topper) => (
              <TableRow key={topper.topperId}>
                <TableCell>{topper.topperId}</TableCell>
                <TableCell>{topper.name}</TableCell>
                <TableCell>{topper.totalMarks}</TableCell>
                <TableCell>{topper.post}</TableCell>
                <TableCell>{topper.rank}</TableCell>
                <TableCell>{topper.year}</TableCell>
                <TableCell> <div
                    style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: topper.topperColor,
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  />{topper.topperColor}</TableCell>
                <TableCell>
                  {topper.topperImage && (
                    <img
                      src={
                        topper.topperImage.startsWith("http")
                          ? topper.topperImage
                          : `https://pjsofttech.in:28443/uploads/${topper.topperImage}`
                      }
                      alt="Topper"
                      style={{ width: 50, height: 50, cursor: "pointer" }}
                      onClick={() => handleImageClick(topper.topperImage)}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleDialogOpen(topper.topperId)}
                    style={{ color: "blue" }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteConfirmation(topper.topperId)}
                    style={{ color: "red" }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose} className="textField-root">
        <DialogTitle color={'black'}>{topperId ? "Edit Topper" : "Create Topper"}</DialogTitle>
        <DialogContent>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      {/* Sketch Picker for color selection */}
                      <SketchPicker color={topperColor} onChangeComplete={handleColorChange} />
                    
                      {/* Box to preview selected color and label below it */}
                      <div style={{ textAlign: 'center' }}>
                        <div
                          style={{
                            width: '150px',
                            height: '150px',
                            backgroundColor: topperColor,
                            border: '1px solid #ccc',
                            borderRadius: '50%',
                            marginBottom: '10px', // Adds space between the circle and the label
                          }}
                        />
                        Selected Color
                      </div>
                    </div>
          <TextField
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Total Marks"
            fullWidth
            value={totalMarks}
            onChange={(e) => setTotalMarks(e.target.value)}
            margin="normal"
            type="number"
          />
          <TextField
            label="Post"
            fullWidth
            value={post}
            onChange={(e) => setPost(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Rank"
            fullWidth
            value={rank}
            onChange={(e) => setRank(e.target.value)}
            margin="normal"
            type="number"
          />
          <TextField
          select
            label="Year"
            fullWidth
            value={year}
            onChange={(e) => setYear(e.target.value)}
            margin="normal"
            type="number"
          >{yearOptions.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}</TextField>
          {imagePreview && (
            <div style={{ marginBottom: "10px" }}>
              <Typography>Current Image:</Typography>
              <img
                src={imagePreview}
                alt="Preview"
                style={{ width: 100, height: 100, objectFit: "cover" }}
              />
            </div>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ margin: "20px 0" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {topperId ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>

      <Modal open={openImageModal} onClose={handleModalClose}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: 20,
            boxShadow: 24,
          }}
        >
          <img
            src={modalImageSrc}
            alt="Full Size Preview"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default Toppers;
