import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Typography,
  Box,
  CardContent,
  Button,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./BuyPlanId.css";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import Swal from "sweetalert2";
import {
  fetchAddOns,
  fetchPlanDetails,
  fetchValidities,
  fetchDiscount,
} from "./CreateINSTApi";
import { Card } from "react-bootstrap";

export default function BuyPlanId() {
  const [isNavigating, setIsNavigating] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();
  const { planId } = useParams();
  const [planDetails, setPlanDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [validities, setValidities] = useState([]);
  const [addOnSystems, setAddOnSystems] = useState([]);
  const [gstAmount, setGstAmount] = useState(0);
  const [basePrice, setBasePrice] = useState(0);
  const [addonsTotalPrice, setAddonsTotalPrice] = useState(0);
  const [formData, setFormData] = useState({
    emailaddress: "",
    phonenumber: "",
    password: "",
    confirmpassword: "",
    institutename: "",
    mobilenumber: "",
    websitename: "",
    address: "",
    landmark: "",
    city: "",
    state: "",
    registrationnumber: "",
    aadhar: "",
    pancard: "",
    loa: "",
    status: "Active",
    mou: "",
    instituteimage: "",
    country: "India",
    employeemanagementsystem: false,
    studentmanagementsystem: false,
    feesmanagementsystem: false,
    incomeandexpense: false,
    enquirymanagementsystem: false,
    admissionmanagementsystem: false,
    classroommanagementsystem: false,
    shipmentsystem: false,
    district: "",
    subscriptstartDate: "",
    subscriptendDate: "",
    gstNo: "",
    ownerName: "",
    pincode: "",
    transactionId: "",
    amount: 0,
    paymentMode: "",
    gstpersent: 0,
    totalAmount: 0,
    plan: "",
    section: "",
    validity: "",
    discountper: 0,
    studentRange: "",
    shipmentsystem: false,
    ebookSystem: false,
    projctManangementSystem: false,
    payrollSystem: false,
    addOnSystems: [],
  });

  useEffect(() => {
    const fetchAddOnSystems = async () => {
      try {
        const response = await fetchAddOns();
        setAddOnSystems(response);
      } catch (err) {
        setError("Failed to fetch add-on systems");
      }
    };

    fetchAddOnSystems();
  }, []);

  const studentRangeMapping = {
    "Upto 25": 25,
    "Upto 50": 50,
    "Upto 150": 150,
    "Upto 250": 250,
    "Upto 500": 500,
    "Upto 800": 800,
    "Upto 1000": 1000,
    "Upto 2000": 2000,
    "Upto 3000": 3000,
    "Upto 4000": 4000,
  };

  useEffect(() => {
    if (planId) {
      sessionStorage.setItem("planId", planId);
    }

    if (location.state?.formData) {
      setFormData(location.state.formData);
    }
  }, [planId, location.state]);

  useEffect(() => {
    const fetchPlanDetailsAndValidities = async () => {
      try {
        const planResponse = await fetchPlanDetails(planId);
        setPlanDetails(planResponse);

        const validitiesResponse = await fetchValidities();
        setValidities(validitiesResponse);

        const pricePerEntry = planResponse.perEntry;
        const priceMRP = planResponse.mrp;
        const selectedCount = studentRangeMapping[formData.studentRange] || 1;

        const initialTotalAmount =
          planResponse.section.sectionName.includes("Institute") ||
          planResponse.section.sectionName.includes("Academy")
            ? priceMRP * selectedCount
            : pricePerEntry * selectedCount;

        setFormData((prevData) => ({
          ...prevData,
          totalAmount: initialTotalAmount,
        }));
      } catch (err) {
        setError("Failed to fetch plan details or validities");
      } finally {
        setLoading(false);
      }
    };

    fetchPlanDetailsAndValidities();
  }, [planId]);

  const handleValidityChange = async (event) => {
    const selectedValidity = event.target.value;

    setFormData((prevData) => {
      const selectedCount = studentRangeMapping[prevData.studentRange] || 1;
      const baseAmount = planDetails.mrp || planDetails.perEntry;

      const newTotalAmount =
        planDetails.section.sectionName.includes("Institute") ||
        planDetails.section.sectionName.includes("Academy")
          ? baseAmount * selectedValidity
          : baseAmount * selectedCount * selectedValidity;
      setBasePrice(baseAmount * selectedValidity);

      const updatedTotalAmount = newTotalAmount + addonsTotalPrice;
      return {
        ...prevData,
        validity: selectedValidity,
        totalAmount: updatedTotalAmount,
      };
    });

    try {
      const discount = await fetchDiscount(
        planDetails.planName,
        planDetails.section.sectionName,
        selectedValidity
      );

      if (discount > 0.0) {
        setFormData((prevData) => {
          const discountedAmount =
            prevData.totalAmount - (prevData.totalAmount * discount) / 100;
          return {
            ...prevData,
            discountper: discount,
            totalAmount: discountedAmount + addonsTotalPrice,
          };
        });
        Swal.fire({
          title: "Discount Applied!",
          text: `You received a discount of ${discount}%`,
          icon: "success",
        });
      } else {
        Swal.fire({
          title: "No Discount Available",
          text: "Sorry, no discount has been allotted for selected options.",
          icon: "info",
        });
        setFormData((prevData) => ({
          ...prevData,
          discountper: 0,
          totalAmount: prevData.totalAmount + addonsTotalPrice,
        }));
      }
    } catch (err) {
      Swal.fire({
        title: "Error!",
        text: "Failed to fetch discount.",
        icon: "error",
      });
    }
  };

  const calculateTotalAmount = () => {
    if (!planDetails) return 0;
    const selectedCount = studentRangeMapping[formData.studentRange] || 1;
    const validity = formData.validity || 1;

    // Determine the base amount according to the section type
    const baseAmount =
      planDetails.section.sectionName.includes("Institute") ||
      planDetails.section.sectionName.includes("Academy")
        ? planDetails.mrp * validity // Use MRP multiplied by validity if applicable
        : planDetails.perEntry * selectedCount * validity; // Use perEntry for other sections

    // Calculate the total amount including add-ons
    const totalAmount = baseAmount + addonsTotalPrice * validity;

    // Apply discounts based on validity
    const discountPercentage = getDiscountPercentage(validity);
    const discountAmount = (totalAmount * discountPercentage) / 100;

    return totalAmount - discountAmount; // Return the total amount after discount
  };

  const getDiscountPercentage = (validity) => {
    switch (validity) {
      case 6:
        return 10;
      case 12:
        return 20;
      case 24:
        return 30;
      case 48:
        return 40;
      default:
        return 0;
    }
  };

  const handleValiditySelect = (validity) => {
    setFormData((prevData) => {
      const updatedValidity = validity;

      const newAddonsTotalPrice = addonsTotalPrice; // Keep the current total price of add-ons
      const updatedTotalAmount = calculateTotalAmount(
        {
          ...prevData,
          validity: updatedValidity,
        },
        planDetails,
        newAddonsTotalPrice
      );

      return {
        ...prevData,
        validity: updatedValidity,
        totalAmount: updatedTotalAmount,
      };
    });
  };

  useEffect(() => {
    if (planDetails) {
      const newTotalAmount = calculateTotalAmount();
      setFormData((prevData) => ({
        ...prevData,
        totalAmount: newTotalAmount,
      }));
    }
  }, [formData.validity, formData.studentRange, planDetails, addonsTotalPrice]);

  const handleAddOnChange = (event, addOn) => {
    const { checked } = event.target;

    setFormData((prevData) => {
      const updatedAddOns = checked
        ? [...prevData.addOnSystems, addOn.id]
        : prevData.addOnSystems.filter((id) => id !== addOn.id);

      // Calculate new add-ons total price
      const newAddonsTotalPrice = updatedAddOns.reduce((total, id) => {
        const selectedAddOn = addOnSystems.find((addOn) => addOn.id === id);
        return total + (selectedAddOn ? selectedAddOn.addOnSystemPrice : 0);
      }, 0);

      // Calculate new total amount based on the updated add-ons total price
      const updatedTotalAmount = calculateTotalAmount(
        updatedAddOns,
        newAddonsTotalPrice
      );

      // Update state
      return {
        ...prevData,
        addOnSystems: updatedAddOns,
        totalAmount: updatedTotalAmount,
      };
    });

    const addonPriceChange = checked
      ? addOn.addOnSystemPrice
      : -addOn.addOnSystemPrice;

    // Update the total addons price in the state
    setAddonsTotalPrice((prevTotal) => prevTotal + addonPriceChange);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const newData = { ...prevData, [name]: value };

      if (name === "studentRange") {
        const selectedCount = studentRangeMapping[value] || 1;

        // Calculate new total amount here including addons
        const newTotalAmount = calculateTotalAmount(); // Ensure to calculate total amount again

        newData.totalAmount = newTotalAmount; // Update total amount
        setGstAmount(calculateGst(newTotalAmount)); // Update GST amount
      }

      return newData;
    });
  };

  const calculateGst = (amount) => {
    return amount * 0.18;
  };

  const handleContinueToNextStep = () => {
    setIsNavigating(true);
    const { planName, section } = planDetails;
    const studentRange = formData.studentRange || "";
    const selectedCount = studentRangeMapping[studentRange] || 0;
    const totalAmountExcludingGST = formData.totalAmount;
    const totalPayableAmount =
      totalAmountExcludingGST + calculateGst(totalAmountExcludingGST);
    const instituteData = {
      ...formData,
      plan: planName,
      section: section.sectionName,
      studentRange: selectedCount,
      amount: totalAmountExcludingGST,
      totalAmount: totalPayableAmount,
      gstpersent: 18,
      gstAmount: calculateGst(totalAmountExcludingGST),
      ebookSystem: formData.ebookSystem,
      shipmentsystem: formData.shipmentsystem,
      projectManagementSystem: formData.projectManagementSystem,
      payrollSystem: formData.payrollSystem,
      discountper: formData.discountper,
      selectedAddOnSystems: formData.addOnSystems,
      validity: formData.validity,
      perEntry: planDetails.perEntry,
    };
    console.log("Navigating with data: ", instituteData);
    navigate("/next-page", {
      state: {
        instituteData,
      },
    });
    setIsNavigating(false);

    if (isNavigating) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  const handleBackToPlans = () => {
    navigate("/create-account");
  };

  return (
    <div className="plan-info-container">
      {planDetails && (
        <Grid container spacing={5}>
          <Grid item xs={5}>
            <Box
              style={{
                borderRadius: "5px",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                background: "linear-gradient(135deg, #e8d67d, #c93)",
              }}
            >
              <CardContent>
                <div className="heading">
                  <Typography variant="h4" padding={"10px"} color="#334450">
                    {planDetails.planName}
                  </Typography>

                  <Typography
                    color="#334450"
                    variant="h6"
                    sx={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    {planDetails.section.sectionName.includes("Institute") ||
                    planDetails.section.sectionName.includes("Academy") ? (
                      <>₹{planDetails.mrp} / Month</>
                    ) : (
                      <>
                        ₹{planDetails.perEntry} /{" "}
                        {planDetails.section.sectionName.includes("School") ||
                        planDetails.section.sectionName.includes("College")
                          ? "Student"
                          : planDetails.section.sectionName.includes(
                              "Organization"
                            ) ||
                            planDetails.section.sectionName.includes("Company")
                          ? "Employee"
                          : "Entry"}{" "}
                        / Month
                      </>
                    )}
                  </Typography>
                </div>

                <Box alignItems={"center"} textAlign={"center"} mt={3}>
                  <button
                    className="details-button"
                    variant="contained"
                    size="small"
                    onClick={handleBackToPlans}
                  >
                    Back to Plans
                  </button>
                </Box>

                {planDetails.features
                  .sort((a, b) => a.featureName.localeCompare(b.featureName)) // Sort features alphabetically
                  .sort((a, b) =>
                    a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1
                  ) // Maintain enabled/disabled sorting
                  .reduce(
                    (acc, feature) => {
                      if (feature.subFeatures.length > 0) {
                        acc[0].push(feature); // Features with subfeatures
                      } else {
                        acc[1].push(feature); // Features without subfeatures
                      }
                      return acc;
                    },
                    [[], []]
                  ) // Split features into two arrays
                  .flat() // Flatten the array
                  .map((feature) => (
                    <div key={feature.id}>
                      <Box className="feature-name">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center", // Align items vertically
                            gap: "1rem", // Optional: Add spacing between elements
                          }}
                        >
                          <Typography
                            variant="h7"
                            fontWeight="bold"
                            color={feature.enabled ? "darkblue" : "red"} // Conditionally set color
                          >
                            {feature.enabled ? (
                              <DoneIcon style={{ color: "#334450" }} />
                            ) : (
                              <ClearIcon style={{ color: "red" }} />
                            )}
                          </Typography>
                          <Typography
                            align="left"
                            fontSize={"18px"}
                            fontWeight={"bold"}
                            style={{
                              marginLeft: "-3%",
                              color: feature.enabled ? "#334450" : "red",
                            }} // Conditional color }} // Inline styling for margin
                          >
                            {feature.featureName}
                          </Typography>
                        </div>
                      </Box>
                    </div>
                  ))}
              </CardContent>
            </Box>

            <Grid item xs={12} mt={1}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label={
                      planDetails.section.sectionName.includes("School") ||
                      planDetails.section.sectionName.includes("College") ||
                      planDetails.section.sectionName.includes("Institute") ||
                      planDetails.section.sectionName.includes("Academy")
                        ? "Student Range"
                        : "Employee Range"
                    }
                    name="studentRange"
                    value={formData.studentRange}
                    onChange={handleInputChange}
                    fullWidth
                    size="small"
                    required={
                      planDetails.section.sectionName.includes("School") ||
                      planDetails.section.sectionName.includes("College") ||
                      planDetails.section.sectionName.includes("Company") ||
                      planDetails.section.sectionName.includes("Organization")
                    }
                    sx={{
                      backgroundColor: "#f0f0f0", // Light gray background color
                      borderRadius: "4px", // Optional: Add rounded corners
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc", // Optional: Customize border color
                        },
                        "&:hover fieldset": {
                          borderColor: "#999", // Optional: Customize border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#3f51b5", // Optional: Customize border color when focused
                        },
                      },
                    }}
                  >
                    <MenuItem value="Upto 25">Upto 25</MenuItem>
                    <MenuItem value="Upto 50">Upto 50</MenuItem>
                    <MenuItem value="Upto 150">Upto 150</MenuItem>
                    <MenuItem value="Upto 250">Upto 250</MenuItem>
                    <MenuItem value="Upto 500">Upto 500</MenuItem>
                    <MenuItem value="Upto 800">Upto 800</MenuItem>
                    <MenuItem value="Upto 1000">Upto 1000</MenuItem>
                    <MenuItem value="Upto 2000">Upto 2000</MenuItem>
                    <MenuItem value="Upto 3000">Upto 3000</MenuItem>
                    <MenuItem value="Upto 4000">Upto 4000</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>

              <Grid container spacing={1} mt={1}>
                {[6, 12, 24, 48].map((months) => (
                  <Grid item xs={6} key={months}>
                    <Box
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        padding: "10px",
                        textAlign: "center",
                        cursor: "pointer",
                        background:
                          formData.validity === months
                            ? "#00649F" // Solid color for selected card
                            : "linear-gradient(135deg, rgb(114, 185, 240), #FFFFFF)", // Light blue-white gradient for unselected cards
                        color:
                          formData.validity === months ? "#FFFFFF" : "#000000", // White text for selected card, black for unselected
                        "&:hover": {
                          background:
                            formData.validity !== months
                              ? "linear-gradient(135deg,rgb(114, 185, 240),rgb(114, 185, 240))" // Slightly darker gradient on hover for unselected cards
                              : "#00649F", // No change for selected card
                        },
                      }}
                      onClick={() => handleValiditySelect(months)}
                    >
                      <Typography variant="h6" fontWeight={"bold"}>
                        {months} Months
                      </Typography>
                      <Typography variant="h7" fontWeight={"bold"}>
                        ₹
                        {(() => {
                          const isInstituteOrAcademy =
                            planDetails.section.sectionName.includes(
                              "Institute"
                            ) ||
                            planDetails.section.sectionName.includes("Academy");
                          const isOrganizationOrCompany =
                            planDetails.section.sectionName.includes(
                              "Organization"
                            ) ||
                            planDetails.section.sectionName.includes("Company");

                          let price;
                          if (isInstituteOrAcademy) {
                            // Display MRP with discount applied
                            price =
                              planDetails.mrp *
                              (1 - getDiscountPercentage(months) / 100);
                          } else if (isOrganizationOrCompany) {
                            // Display perEntry price for employees
                            price =
                              planDetails.perEntry *
                              (1 - getDiscountPercentage(months) / 100);
                          } else {
                            // Default case for students
                            price =
                              planDetails.perEntry *
                              (1 - getDiscountPercentage(months) / 100);
                          }

                          return price.toFixed(2);
                        })()}{" "}
                        {(() => {
                          if (
                            planDetails.section.sectionName.includes(
                              "Institute"
                            ) ||
                            planDetails.section.sectionName.includes("Academy")
                          ) {
                            return "/ Month"; // No label for Institute or Academy
                          } else if (
                            planDetails.section.sectionName.includes(
                              "Organization"
                            ) ||
                            planDetails.section.sectionName.includes("Company")
                          ) {
                            return "/ Employee / Month"; // Employee label for Organization or Company
                          } else {
                            return "/ Student / Month"; // Default label for students
                          }
                        })()}
                      </Typography>
                      <Typography variant="body1" fontWeight="bold">
                        ( {getDiscountPercentage(months)}% OFF )
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {/* Institute Creation Form */}
          <Grid item xs={7} className="textField-root" mt={1}>
            <Paper
              elevation={1}
              sx={{
                padding: "2%",
                backgroundColor: "white",
                boxShadow: "0px 4px 10px rgba(105, 104, 104, 0.6)",
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  padding={"10px"}
                  fontWeight={"bold"}
                  textAlign={"center"}
                >
                  Price Breakdown
                </Typography>
                <Grid container spacing={2}>
                  {/* Subscription Year Field */}

                  <Grid item xs={12}>
                    <Box
                      style={{
                        backgroundColor: "#F2F2F2", // Lightest blue
                        padding: "20px",
                        borderRadius: "8px",
                        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                      }}
                    >
                      <Typography fontWeight="bold" textAlign={"center"}>
                        Optional Add-Ons
                      </Typography>
                      <Grid container spacing={2} xs={12}>
                        {addOnSystems.map((addOn) => (
                          <Grid item xs={6} key={addOn.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formData.addOnSystems
                                      ? formData.addOnSystems.includes(addOn.id)
                                      : false
                                  } // Safeguard against undefined
                                  onChange={(event) =>
                                    handleAddOnChange(event, addOn)
                                  } // Handle change
                                  name={addOn.addOnSystemName} // Use the name for identification
                                />
                              }
                              label={`${addOn.addOnSystemName} (₹${addOn.addOnSystemPrice}  / Month)`} // Display name and price
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                  


                  {/* <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    mb={-1}
                  >
                    <Typography>Base Price</Typography>
                    <Typography fontWeight={"bold"}>
                      {planDetails.mrp
                        ? `₹${planDetails.mrp} / Month`
                        : `₹${planDetails.perEntry} / Month`}
                    </Typography>
                  </Grid> */}

                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    mb={-1}
                  >
                    <Typography>Range Count</Typography>
                    <Typography fontWeight={"bold"}>
                      {formData.studentRange}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    mb={-1}
                  >
                    <Typography>Add-Ons Total:</Typography>
                    <Typography fontWeight={"bold"}>
                      ₹
                      {(addonsTotalPrice * (formData.validity || 1)).toFixed(2)}{" "}
                      {/* Calculate based on validity */}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    mb={-1}
                  >
                    <Typography>Total Months</Typography>
                    <Typography fontWeight={"bold"}>
                      {formData.validity} Month(s)
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  mb={-1}
                  >
                    <Typography>
                      Discount Applied (
                      {(() => {
                        let discountPercentage = 0;

                        // Determine the discount percentage based on the selected validity
                        switch (formData.validity) {
                          case 6:
                            discountPercentage = 10;
                            break;
                          case 12:
                            discountPercentage = 20;
                            break;
                          case 24:
                            discountPercentage = 30;
                            break;
                          case 48:
                            discountPercentage = 40;
                            break;
                          default:
                            discountPercentage = 0;
                        }

                        return discountPercentage; // Return the discount percentage to display
                      })()}
                      %):
                    </Typography>
                    <Typography fontWeight={"bold"}>
                      ₹
                      {(() => {
                        let discountPercentage = 0;

                        // Determine the discount percentage based on the selected validity
                        switch (formData.validity) {
                          case 6:
                            discountPercentage = 10;
                            break;
                          case 12:
                            discountPercentage = 20;
                            break;
                          case 24:
                            discountPercentage = 30;
                            break;
                          case 48:
                            discountPercentage = 40;
                            break;
                          default:
                            discountPercentage = 0;
                        }

                        // Use a default value for student count if not selected
                        const selectedCount =
                          studentRangeMapping[formData.studentRange] || 1;

                        const baseAmount =
                          planDetails.section.sectionName.includes(
                            "Institute"
                          ) ||
                          planDetails.section.sectionName.includes("Academy")
                            ? planDetails.mrp * (formData.validity || 1) // Ensure validity defaults to 1
                            : planDetails.perEntry *
                              selectedCount *
                              (formData.validity || 1); // Use default count for perEntry

                        const discountAmount =
                          baseAmount * (discountPercentage / 100);

                        return discountAmount.toFixed(2);
                      })()}
                    </Typography>
                  </Grid>

                  

             
                 

                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    mb={-1}
                  >
                    <Typography>Total Price (Excluding GST)</Typography>
                    <Typography fontWeight={"bold"}>
                      ₹{formData.totalAmount.toFixed(2)}{" "}
                      {/* Ensure to format it */}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    mb={-1}
                  >
                    <Typography>GST (18%):</Typography>
                    <Typography fontWeight={"bold"}>
                      ₹{calculateGst(formData.totalAmount).toFixed(2)}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    fullWidth
                    style={{
                      borderBottom: "3px solid #BFBFBF",
                      textAlign: "center",
                      marginLeft: "1.4%",
                    }}
                  ></Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    mb={-1}
                  >
                    <Typography>
                      Total Payable Amount (Including GST)
                    </Typography>
                    <Typography fontWeight={"bold"}>
                      ₹
                      {(
                        formData.totalAmount +
                        calculateGst(formData.totalAmount)
                      ).toFixed(2)}
                    </Typography>
                  </Grid>

                  {/* Add remaining fields for the formData here */}
                </Grid>
              </CardContent>
            </Paper>
            <Box alignItems={"center"} textAlign={"center"} mt={3}>
              {/* // Button for navigating to next step */}
              <Box alignItems={"center"} textAlign={"center"} mt={3}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleContinueToNextStep} // Use the new function
                >
                  Continue To Next Step
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
