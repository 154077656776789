import React, { useState, useEffect, useCallback } from "react";
// import { StudentTable, UpdateStudent, StudentInfo } from "../Student"; // import the components
import StudentInfo from "./StudentInfo";
import StudentTable from "./StudentTable";
import UpdateStudent from "./UpdateStudent";
import BonafideCertificate from "./BonafideCertificate";
import { toast } from "react-toastify";
import axios from "axios";

const StudentList = () => {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [updatingStudent, setUpdatingStudent] = useState(null);
  const [bonafideStudentId, setBonafideStudentId] = useState(null);
  const institutecode = () => sessionStorage.getItem("institutecode");

  const fetchAllStudents = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:9443/getAllStudent?institutecode=${institutecode()}`
      );
      setStudents(response.data || []);
    } catch (error) {
      console.error("Error fetching students:", error);
      setStudents([]);
    }
  }, []);

  useEffect(() => {
    fetchAllStudents();
  }, [fetchAllStudents]);

  const handleUpdateStudent = (student) => setUpdatingStudent(student);
  const handleStudentInfo = (student) => setSelectedStudent(student);
  const handleBonafideCertificate = (studentId) => setBonafideStudentId(studentId);

  return (
    <>
      <StudentTable 
        students={students} 
        setStudents={setStudents} // Pass setStudents to StudentTable
        onUpdate={handleUpdateStudent} 
        fetchAllStudents={fetchAllStudents}
        onInfo={handleStudentInfo} 
        onBonafide={handleBonafideCertificate}
      />
      {updatingStudent && (
        <UpdateStudent
          student={updatingStudent}
          onClose={() => setUpdatingStudent(null)}
          onUpdateComplete={fetchAllStudents}
        />
      )}
      {selectedStudent && (
        <StudentInfo
          student={selectedStudent}
          onClose={() => setSelectedStudent(null)}
        />
      )}
     {bonafideStudentId && (
        <BonafideCertificate 
          open={Boolean(bonafideStudentId)}
          studentId={bonafideStudentId} // ✅ Pass student ID
          onClose={() => setBonafideStudentId(null)}
        />
      )}
    </>
  );
};

export default StudentList;
