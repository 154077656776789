import React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import "./WhatsNew.css";

export default function WhatsNew() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const institutecode = sessionStorage.getItem("institutecode") || "";

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `https://pjsofttech.in:20443/getnotificationByInstitutecode`,
          {
            params: { institutecode },
          }
        );

        // Filter notifications within the last 2 days
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

        const recentNotifications = response.data.filter((notification) => {
          const notificationDate = new Date(notification.date);
          return notificationDate >= twoDaysAgo;
        });

        setNotifications(recentNotifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [institutecode]);
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `https://pjsofttech.in:20443/getnotificationByInstitutecode`,
          {
            params: { institutecode },
          }
        );

        // Filter notifications within the last 2 days
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

        const recentNotifications = response.data.filter((notification) => {
          const notificationDate = new Date(notification.date);
          return notificationDate >= twoDaysAgo;
        });

        setNotifications(recentNotifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [institutecode]);

  return (
    <div className="whatsnew">
      <Typography variant="h6" fontWeight="bold" >
        What's New
      </Typography>
      <Paper
        sx={{
          padding: 1,
          border: "1px solid lightgray",
          borderRadius: 2,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
          maxHeight: "130px", // Limit height
          overflowY: "auto", // Enable scrolling for content
        }}

        // sx={{
        //     borderRadius: "20px",
        //     color: "#000",
        //     boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
        //     position: "relative",
        //     maxHeight: "250px",
        //     overflowY: "auto",

        //   }}
      >
        <Box>
          <hr />
          {loading ? (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          ) : notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <Box key={index} mb={2}>
                <Typography variant="h6">{notification.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {new Date(notification.date).toLocaleString()}
                </Typography>
                <Typography variant="body1">{notification.message}</Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body1" textAlign="center">
              No recent notifications.
            </Typography>
          )}
        </Box>
      </Paper>
    </div>
  );
}
