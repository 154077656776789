// import React, { useState, useEffect } from 'react';
// import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Typography } from '@mui/material';
// import { Edit, Delete } from '@mui/icons-material';
// import axios from 'axios';
// import { MenuItem } from '@mui/material';

// const ContactForm = () => {
//   const [open, setOpen] = useState(false);
//   const institutecode = () => sessionStorage.getItem("institutecode");
//   const [contactForm, setContactForm] = useState({
//     name: '',
//     mobileNo: '',
//     course: '',
//     description: '',
//   });
//   const [contactId, setContactId] = useState(null);
//   const [contactList, setContactList] = useState([]);
//   const [courseOptions, setCourseOptions] = useState([]);

//   const apiUrl = 'https://pjsofttech.in:28443';

//   // Fetch all contact forms
//   const fetchContactForms = () => {
//     axios
//       .get(`${apiUrl}/getAllContactForms?institutecode=${institutecode}`)
//       .then((response) => {
//         setContactList(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching contact forms:', error);
//       });
//   };

//   // Fetch courses
//   const fetchCourses = () => {
//     axios
//       .get(`${apiUrl}/getAllCourses?institutecode=${institutecode()}`)
//       .then((response) => {
//         setCourseOptions(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching courses:', error);
//       });
//   };

//   // Handle input change
//   const handleChange = (e) => {
//     setContactForm({ ...contactForm, [e.target.name]: e.target.value });
//   };

//   // Submit new contact form
//   const handleSubmit = () => {
//     axios
//       .post(`${apiUrl}/createContactForm?institutecode=${institutecode()}`)
//       .then(() => {
//         alert('Form submitted successfully!');
//         setOpen(false);
//         resetForm();
//         fetchContactForms();
//       })
//       .catch((error) => {
//         console.error('Error submitting the form:', error);
//       });
//   };

//   // Update contact form
//   const handleUpdate = () => {
//     if (contactId) {
//       axios
//         .put(`${apiUrl}/updateContactForm/${contactId}`, contactForm)
//         .then(() => {
//           alert('Form updated successfully!');
//           setOpen(false);
//           resetForm();
//           fetchContactForms();
//         })
//         .catch((error) => {
//           console.error('Error updating the form:', error);
//         });
//     }
//   };

//   // Delete contact form
//   const handleDelete = (id) => {
//     axios
//       .delete(`${apiUrl}/deleteContactForm/${id}`)
//       .then(() => {
//         alert('Form deleted successfully!');
//         fetchContactForms();
//       })
//       .catch((error) => {
//         console.error('Error deleting the form:', error);
//       });
//   };

//   // Fetch a single contact form for editing
//   const fetchContactForm = (id) => {
//     axios
//       .get(`${apiUrl}/getContactFormById/${id}`)
//       .then((response) => {
//         setContactForm(response.data);
//         setContactId(id);
//         setOpen(true);
//       })
//       .catch((error) => {
//         console.error('Error fetching the contact form:', error);
//       });
//   };

//   // Reset form state
//   const resetForm = () => {
//     setContactForm({
//       name: '',
//       mobileNo: '',
//       course: '',
//       description: '',
//     });
//     setContactId(null);
//   };

//   useEffect(() => {
//     fetchContactForms();
//     fetchCourses();
//   }, []);

//   return (
//     <div>
//       <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
//         Create Contact Form
//       </Button>

//       <Dialog open={open} onClose={() => setOpen(false)} className="textField-root">
//         <DialogTitle color={'black'}>{contactId ? 'Edit Contact Form' : 'Create Contact Form'}</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="Name"
//             fullWidth
//             variant="outlined"
//             name="name"
//             value={contactForm.name}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             label="Mobile Number"
//             fullWidth
//             variant="outlined"
//             name="mobileNo"
//             value={contactForm.mobileNo}
//             onChange={handleChange}
//           />
//           <TextField
//             select
//             margin="dense"
//             label="Course"
//             fullWidth
//             variant="outlined"
//             name="course"
//             value={contactForm.course}
//             onChange={handleChange}
//           >
//             {courseOptions.map((course) => (
//               <MenuItem key={course.courseId} value={course.courseTitle}>
//                 {course.courseTitle}
//               </MenuItem>
//             ))}
//           </TextField>
//           <TextField
//             margin="dense"
//             label="Description"
//             fullWidth
//             variant="outlined"
//             name="description"
//             value={contactForm.description}
//             onChange={handleChange}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpen(false)} color="secondary">
//             Cancel
//           </Button>
//           {contactId ? (
//             <Button onClick={handleUpdate} color="primary">
//               Update
//             </Button>
//           ) : (
//             <Button onClick={handleSubmit} color="primary">
//               Submit
//             </Button>
//           )}
//         </DialogActions>
//       </Dialog>

//       <TableContainer component={Paper} className="table-root">
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>ID</TableCell>
//               <TableCell>Name</TableCell>
//               <TableCell>Mobile No</TableCell>
//               <TableCell>Course</TableCell>
//               <TableCell>Description</TableCell>
//               <TableCell>Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {contactList.length > 0 ? (
//               contactList.map((form) => (
//                 <TableRow key={form.contactId}>
//                   <TableCell>{form.contactId}</TableCell>
//                   <TableCell>{form.name}</TableCell>
//                   <TableCell>{form.mobileNo}</TableCell>
//                   <TableCell>{form.course}</TableCell>
//                   <TableCell>{form.description}</TableCell>
//                   <TableCell>
//                     <IconButton onClick={() => fetchContactForm(form.contactId)} color="primary">
//                       <Edit />
//                     </IconButton>
//                     <IconButton onClick={() => handleDelete(form.contactId)} color="error">
//                       <Delete />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan={6} align="center">
//                   No contact forms available.
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </div>
//   );
// };

// export default ContactForm;


import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Typography, MenuItem } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';
import Swal from 'sweetalert2';

const ContactForm = () => {
  const [open, setOpen] = useState(false);
  const institutecode = () => sessionStorage.getItem("institutecode");
  const [contactForm, setContactForm] = useState({
    name: '',
    mobileNo: '',
    course: '',
    description: '',
    email: '',
    academicYear: '',
  });
  const [contactId, setContactId] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);

  const apiUrl = 'https://pjsofttech.in:28443';

  const fetchContactForms = () => {
    axios.get(`${apiUrl}/getAllContactForms?institutecode=${institutecode()}`)
      .then(response => setContactList(response.data))
      .catch(error => console.error('Error fetching contact forms:', error));
  };

  const fetchCourses = () => {
    axios.get(`${apiUrl}/getAllCourses?institutecode=${institutecode()}`)
      .then(response => setCourseOptions(response.data))
      .catch(error => console.error('Error fetching courses:', error));
  };

   // Generate academic year range (5 years back & forward)
   const generateAcademicYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = -5; i <= 5; i++) {
      const startYear = currentYear + i;
      const endYear = startYear + 1;
      years.push(`${startYear}-${endYear}`);
    }
    setAcademicYears(years);
  };

  const handleChange = (e) => {
    setContactForm({ ...contactForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    const formData = new FormData();
    Object.entries(contactForm).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("institutecode", institutecode());

    axios.post(`${apiUrl}/createContactForm`, formData)
      .then(() => {
        Swal.fire('Success', 'Form submitted successfully!', 'success');
        setOpen(false);
        resetForm();
        fetchContactForms();
      })
      .catch(() => Swal.fire('Error', 'Error submitting the form.', 'error'));
  };

  const handleUpdate = () => {
    if (contactId) {
      const formData = new FormData();
      Object.entries(contactForm).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("institutecode", institutecode());

      axios.put(`${apiUrl}/updateContactForm/${contactId}`, formData)
        .then(() => {
          Swal.fire('Success', 'Form updated successfully!', 'success');
          setOpen(false);
          resetForm();
          fetchContactForms();
        })
        .catch(() => Swal.fire('Error', 'Error updating the form.', 'error'));
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${apiUrl}/deleteContactForm/${id}`)
          .then(() => {
            Swal.fire('Deleted!', 'Form has been deleted.', 'success');
            fetchContactForms();
          })
          .catch(() => Swal.fire('Error', 'Error deleting the form.', 'error'));
      }
    });
  };

  const fetchContactForm = (id) => {
    axios.get(`${apiUrl}/getContactFormById/${id}`)
      .then(response => {
        setContactForm(response.data);
        setContactId(id);
        setOpen(true);
      })
      .catch(error => console.error('Error fetching the contact form:', error));
  };

  const resetForm = () => {
    setContactForm({ name: '', mobileNo: '', course: '', description: '', email: '', academicYear: ''});
    setContactId(null);
  };

  useEffect(() => {
    fetchContactForms();
    fetchCourses();
    generateAcademicYears();
  }, []);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>Create Contact Form</Button>
      <Dialog open={open} onClose={() => setOpen(false)} className="textField-root">
        <DialogTitle color={'black'}>{contactId ? 'Edit Contact Form' : 'Create Contact Form'}</DialogTitle>
        <DialogContent>
          <TextField margin="dense" label="Name" fullWidth name="name" value={contactForm.name} onChange={handleChange} />
          <TextField margin="dense" label="Mobile Number" fullWidth name="mobileNo" value={contactForm.mobileNo} onChange={handleChange} />
          <TextField select margin="dense" label="Course" fullWidth name="course" value={contactForm.course} onChange={handleChange}>
            {courseOptions.map(course => <MenuItem key={course.courseId} value={course.courseTitle}>{course.courseTitle}</MenuItem>)}
          </TextField>
          <TextField margin="dense" label="Email" fullWidth name="email" value={contactForm.email} onChange={handleChange} />
          {/* Academic Year Dropdown */}
          <TextField select margin="dense" label="Academic Year" fullWidth name="academicYear" value={contactForm.academicYear} onChange={handleChange}>
            {academicYears.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
          </TextField>
          {/* <TextField margin="dense" label="Academic Year" fullWidth name="academicYear" value={contactForm.academicYear} onChange={handleChange} /> */}
          <TextField margin="dense" label="Description" fullWidth name="description" value={contactForm.description} onChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">Cancel</Button>
          {contactId ? (
            <Button onClick={handleUpdate} color="primary">Update</Button>
          ) : (
            <Button onClick={handleSubmit} color="primary">Submit</Button>
          )}
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper} style={{ marginTop: 20 }} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Mobile No</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Academic Year</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contactList.map((form) => (
              <TableRow key={form.contactId}>
                <TableCell>{form.contactId}</TableCell>
                <TableCell>{form.name}</TableCell>
                <TableCell>{form.mobileNo}</TableCell>
                <TableCell>{form.course}</TableCell>
                <TableCell>{form.email}</TableCell>
                <TableCell>{form.academicYear}</TableCell>
                <TableCell>{form.description}</TableCell>
                <TableCell>
                  <IconButton onClick={() => fetchContactForm(form.contactId)} color="primary"><Edit /></IconButton>
                  <IconButton onClick={() => handleDelete(form.contactId)} color="error"><Delete /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ContactForm;
