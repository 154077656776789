import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Add, Edit, Delete, Close } from "@mui/icons-material";
import Swal from "sweetalert2";
import { SketchPicker } from "react-color";

const apiUrl = "https://pjsofttech.in:28443"; // Base URL without trailing slash

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [testimonialId, setTestimonialId] = useState(null);
  const [testimonialName, setTestimonialName] = useState("");
  const [description, setDescription] = useState("");
  const [testimonialColor, setTestimonialColor] = useState("#000000");
  const [exam, setExam] = useState("");
  const [post, setPost] = useState("");
  const institutecode = () => sessionStorage.getItem("institutecode");
  const [testimonialImage, setTestimonialImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = () => {
    axios
      .get(`${apiUrl}/getAllTestimonials?institutecode=${institutecode()}`) // Correct URL format
      .then((response) => {
        setTestimonials(response.data);
      })
      .catch((error) => console.error("Error fetching testimonials:", error));
  };

  const handleDialogOpen = (id = null) => {
    if (id) {
      axios
        .get(`${apiUrl}/getTestimonialById/${id}`)
        .then((response) => {
          const { testimonialName, exam, post, testimonialImage, description, testimonialColor } = response.data;
          setTestimonialId(id);
          setTestimonialColor(testimonialColor);
          setTestimonialName(testimonialName);
          setDescription(description);
          setExam(exam);
          setPost(post);
          setImagePreview(testimonialImage); // Set image preview for editing
        })
        .catch((error) => console.error("Error fetching testimonial:", error));
    } else {
      setTestimonialId(null);
      setTestimonialName("");
      setDescription("");
      setTestimonialColor("");
      setExam("");
      setPost("");
      setImagePreview(null);
    }
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setOpenDeleteDialog(false);
    setImagePreview(null); // Reset image preview when dialog closes
  };

  const handleImageChange = (event) => {
    setTestimonialImage(event.target.files[0]);
    setImagePreview(URL.createObjectURL(event.target.files[0])); // Show the selected image preview
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("testimonialName", testimonialName);
    formData.append("description", description);
    formData.append("testimonialColor", testimonialColor);
    formData.append("exam", exam);
    formData.append("post", post);
    if (testimonialImage) {
      formData.append("testimonialImage", testimonialImage);
    }
  
    if (testimonialId) {
      // PUT request to update testimonial
      axios
        .put(`${apiUrl}/updateTestimonial/${testimonialId}?institutecode=${institutecode()}`, formData)
        .then(() => {
          fetchTestimonials();
          Swal.fire("Success", "Testimonial updated successfully!", "success");
          handleDialogClose();
        })
        .catch((error) => console.error("Error updating testimonial:", error));
        Swal.fire("Error", "Failed to Update Testimonial!", "error");
    } else {
      // POST request to create new testimonial
      axios
        .post(`${apiUrl}/createTestimonial?institutecode=${institutecode()}`, formData)
        .then(() => {
          fetchTestimonials();
          Swal.fire("Success", "Testimonial Created successfully!", "success");
          handleDialogClose();
        })
        .catch((error) => {
          console.error("Error creating testimonial:", error);
          Swal.fire("Error", "Failed to Create Testimonial!", "error");
        });
    }
  };
  

  const handleDeleteConfirmation = (id) => {
    setTestimonialId(id);
     // Show SweetAlert2 confirmation dialog
        Swal.fire({
          title: 'Are you sure?',
          text: 'Once deleted, you will not be able to recover this topper!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
          if (result.isConfirmed) {
            // If user confirms, proceed with deletion
            handleDelete(id);
          }
        });
  };

  const handleDelete = () => {
    axios
      .delete(`${apiUrl}/deleteTestimonial/${testimonialId}`)
      .then(() => {
        fetchTestimonials();
        Swal.fire(
                 'Deleted!',
                 'The testimonial has been deleted.',
                 'success'
               );
      })
      .catch((error) => {
        console.error("Error deleting testimonial:", error);
        // Show SweetAlert2 error pop-up
               Swal.fire(
                 'Error!',
                 'Failed to delete the testimonial. Please try again later.',
                 'error'
               );
      });
  };

  const handleColorChange = (color) => {
    setTestimonialColor(color.hex);
  };

  const handleImageClick = (imageName) => {
    const imageUrl = imageName.startsWith("http")
      ? imageName
      : `${apiUrl}/uploads/${imageName}`;
    setModalImage(imageUrl);
    setOpenImageModal(true);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleDialogOpen()}
      >
        Create Testimonial
      </Button>

      <TableContainer component={Paper} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Exam</TableCell>
              <TableCell>Post</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testimonials.map((testimonial) => (
              <TableRow key={testimonial.testimonialId}>
                <TableCell>{testimonial.testimonialId}</TableCell>
                <TableCell>{testimonial.testimonialName}</TableCell>
                <TableCell>{testimonial.exam}</TableCell>
                <TableCell>{testimonial.post}</TableCell>
                <TableCell>
                  {testimonial.testimonialImage && (
                    <img
                      src={
                        testimonial.testimonialImage.startsWith("http")
                          ? testimonial.testimonialImage
                          : `${apiUrl}/uploads/${testimonial.testimonialImage}`
                      }
                      alt="Testimonial"
                      style={{ width: 50, height: 50, cursor: "pointer" }}
                      onClick={() => handleImageClick(testimonial.testimonialImage)}
                    />
                  )}
                </TableCell>
                <TableCell><div
                    style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: testimonial.testimonialColor,
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  />{testimonial.testimonialColor}</TableCell>
                <TableCell>{testimonial.description}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleDialogOpen(testimonial.testimonialId)}
                    style={{ color: "blue" }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteConfirmation(testimonial.testimonialId)}
                    style={{ color: "red" }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose} className="textField-root">
        <DialogTitle color={'black'}>{testimonialId ? "Edit Testimonial" : "Create Testimonial"}</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                {/* Sketch Picker for color selection */}
                                <SketchPicker color={testimonialColor} onChangeComplete={handleColorChange} />
                              
                                {/* Box to preview selected color and label below it */}
                                <div style={{ textAlign: 'center' }}>
                                  <div
                                    style={{
                                      width: '150px',
                                      height: '150px',
                                      backgroundColor: testimonialColor,
                                      border: '1px solid #ccc',
                                      borderRadius: '50%',
                                      marginBottom: '10px', // Adds space between the circle and the label
                                    }}
                                  />
                                  Selected Color
                                </div>
                              </div>
          <TextField
            label="Testimonial Name"
            fullWidth
            value={testimonialName}
            onChange={(e) => setTestimonialName(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Exam"
            fullWidth
            value={exam}
            onChange={(e) => setExam(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Post"
            fullWidth
            value={post}
            onChange={(e) => setPost(e.target.value)}
            margin="normal"
          />
          {imagePreview && (
            <div style={{ marginBottom: "10px" }}>
              <Typography>Current Image:</Typography>
              <img
                src={imagePreview}
                alt="Preview"
                style={{ width: 100, height: 100, objectFit: "cover" }}
              />
            </div>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ margin: "20px 0" }}
          />
          <TextField
            label="Description"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {testimonialId ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openImageModal} onClose={() => setOpenImageModal(false)}>
        <DialogContent>
          <img
            src={modalImage}
            alt="Full Size"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
        <DialogActions>
          <IconButton onClick={() => setOpenImageModal(false)}>
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Testimonials;
