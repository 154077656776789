import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";

const Stream = () => {
  const [streams, setStreams] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentStream, setCurrentStream] = useState({
    streamid: null,
    streamName: "",
  });
  const institutecode = sessionStorage.getItem("institutecode"); 

  // Fetch all streams
  const fetchStreams = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:9443/getallStream?institutecode=${institutecode}`
      );
      setStreams(response.data);
    } catch (error) {
        Swal.fire("Error", "Failed to fetch streams.", "error");
    }
  };

  // Add a new stream
  const handleAddStream = async () => {
    try {
      await axios.post(
        "https://pjsofttech.in:9443/addStream",
        currentStream,
        { params: { institutecode } }
      );
      fetchStreams();
      setOpenDialog(false);
      Swal.fire("Success", "Stream added successfully.", "success");
    } catch (error) {
        Swal.fire("Error", "Failed to add stream.", "error");
    }
  };

  // Update an existing stream
  const handleUpdateStream = async () => {
    try {
      await axios.put(
        `https://pjsofttech.in:9443/updateStream/${currentStream.streamid}`,
        currentStream,
        { params: { institutecode } }
      );
      fetchStreams();
      setOpenDialog(false);
      Swal.fire("Success", "Stream updated successfully.", "success");
    } catch (error) {
        Swal.fire("Error", "Failed to update stream.", "error");
    }
  };

   // Delete a stream with confirmation
   const handleDeleteStream = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://pjsofttech.in:9443/deleteStream/${id}`);
          fetchStreams();
          Swal.fire("Deleted!", "Stream has been deleted.", "success");
        } catch (error) {
          Swal.fire("Error", "Failed to delete stream.", "error");
        }
      }
    });
  };


  const handleDialogOpen = (stream = { streamid: null, streamName: "" }) => {
    setCurrentStream(stream);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchStreams();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleDialogOpen()}
        style={{ marginBottom: "20px" }}
      >
        Add Stream
      </Button>
      <TableContainer component={Paper} className="table-root">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Stream ID</TableCell>
              <TableCell>Stream Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {streams.map((stream) => (
              <TableRow key={stream.streamid}>
                <TableCell>{stream.streamid}</TableCell>
                <TableCell>{stream.streamName}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleDialogOpen(stream)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteStream(stream.streamid)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Adding/Editing Stream */}
      <Dialog open={openDialog} onClose={handleDialogClose} className="textField-root">
        <DialogTitle color={'black'}>
          {currentStream.streamid ? "Update Stream" : "Add Stream"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Stream Name"
            type="text"
            fullWidth
            value={currentStream.streamName}
            onChange={(e) =>
              setCurrentStream({ ...currentStream, streamName: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={
              currentStream.streamid ? handleUpdateStream : handleAddStream
            }
            color="primary"
          >
            {currentStream.streamid ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Stream;
