// import React, { useEffect, useState } from "react";
// import {
//   Select,
//   MenuItem,
//   FormControl,
//   TextField,
//   Typography,
//   Grid,
//   Box,
// } from "@mui/material";
// import { ResponsiveBar } from "@nivo/bar";
// import axios from "axios";

// export default function MonthlyFormsByYear() {
//   const today = new Date(); // Get today's date
//   const [year, setYear] = useState(today.getFullYear()); // Current year
//   const [data, setData] = useState([]);
//   const [view, setView] = useState("count"); // Track whether to show 'count' or 'revenue'

//   const institutecode = sessionStorage.getItem("institutecode");

//   // Generate years: past 7 and future 7 years
//   const currentYear = today.getFullYear();
//   const years = Array.from({ length: 15 }, (v, k) => currentYear - 7 + k);

//   // Fetch data from API
//   const fetchData = async () => {
//     const response = await axios.get(
//       `https://pjsofttech.in:17443/monthly-stats?year=${year}&institutecode=${institutecode}`
//     );
//     setData(response.data);
//   };

//   useEffect(() => {
//     fetchData();
//   }, [year]); // Re-fetch data when year changes

//   // Prepare data for Nivo Bar charts
//   const months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   const chartData = months.map((month) => {
//     const entry = data.find((d) => d.month === month);
//     return {
//       month: month.substring(0, 3), // Use only the first 3 letters of the month
//       count: entry ? entry.count : 0,
//       revenue: entry ? entry.revenue : 0,
//     };
//   });
  

//   return (
//     <div>
//       {/* Dropdowns in a Horizontal Layout */}
//       <div style={{ display: "flex", justifyContent: "space-between" }}>
//         {/* Title */}
//         <div>
//           <Typography variant="h7" fontWeight={"bold"}>
//             {view === "count" ? "Monthly Counts" : "Monthly Revenue"}
//           </Typography>
//         </div>

//         <div style={{ marginBottom: "16px", display: "flex" }} size="small">
//           {/* Year Selector */}
//           <FormControl
//             variant="outlined"
//             style={{ marginRight: "16px" }}
//             size="small"
//           >
//             <TextField 
//             className="textField-root"
//             size="small"
//               select
//               label="Year"
//               value={year}
//               onChange={(e) => setYear(e.target.value)}
//               style={{width:"100px"}}
//             >
//               {years.map((y) => (
//                 <MenuItem key={y} value={y}>
//                   {y}
//                 </MenuItem>
//               ))}
//             </TextField>
//           </FormControl>

//           {/* View Selector (Count or Revenue) */}
//           <FormControl variant="outlined" size="small">
//             <TextField 
//             className="textField-root"
//             size="small"
//               select
//               label={view}
//               value={view}
//               onChange={(e) => setView(e.target.value)}
//             >
//               <MenuItem value="count">Count</MenuItem>
//               <MenuItem value="revenue">Revenue</MenuItem>
//             </TextField>
//           </FormControl>
//         </div>
//       </div>

//       {/* Bar Chart */}
//       <div style={{ width: "90%", marginTop: "16px" }}>
//         <Box height={330}>
//         <ResponsiveBar
//             data={chartData}
//             keys={[view]} // Dynamically choose either 'count' or 'revenue'
//             indexBy="month"
//             margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
//             padding={0.2}
//             colors={view === "count" ? ["#3498DB"] : ["#FF6F61"]}
//             borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
//             axisBottom={{
//                 tickSize: 5,
//                 tickPadding: 5,
//                 tickRotation: 0,
//                 legend: "Month",
//                 legendPosition: "middle",
//                 legendOffset: 32,
//                 format: (d) => d.substring(0, 3), // Display the first 3 letters
//               }}
              
//             axisLeft={{
//               tickSize: 5,
//               tickPadding: 5,
//               tickRotation: 0,
//               legend: view === "count" ? "Count" : "Revenue",
//               legendPosition: "middle",
//               legendOffset: -40,
//             }}
//             labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
//             role="application"
//             ariaLabel={`Monthly ${view} bar chart`}
//             isInteractive={true}
//           />
//         </Box>
//       </div>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import {
  FormControl,
  TextField,
  Typography,
  MenuItem,
  Box,
  Button,
} from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import axios from "axios";

export default function MonthlyFormsByYear() {
  const today = new Date(); // Get today's date
  const [year, setYear] = useState(today.getFullYear()); // Current year
  const [data, setData] = useState([]);
  const [view, setView] = useState("count"); // Track whether to show 'count' or 'revenue'

  const institutecode = sessionStorage.getItem("institutecode");

  // Generate years: past 7 and future 7 years
  const currentYear = today.getFullYear();
  const years = Array.from({ length: 15 }, (v, k) => currentYear - 7 + k);

  // Fetch data from API
  const fetchData = async () => {
    const response = await axios.get(
      `https://pjsofttech.in:17443/monthly-stats?year=${year}&institutecode=${institutecode}`
    );
    setData(response.data);
  };

  useEffect(() => {
    fetchData();
  }, [year]); // Re-fetch data when year changes

  // Prepare data for Nivo Bar charts
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const chartData = months.map((month) => {
    const entry = data.find((d) => d.month === month);
    return {
      month: month.substring(0, 3), // Use only the first 3 letters of the month
      count: entry ? entry.count : 0,
      revenue: entry ? entry.revenue : 0,
    };
  });

  return (
    <div>
      {/* Dropdowns in a Horizontal Layout */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {/* Title */}
        <div>
          <Typography variant="h7" fontWeight={"bold"}>
            {view === "count" ? "Monthly Counts" : "Monthly Revenue"}
          </Typography>
        </div>

        <div style={{ marginBottom: "16px", display: "flex" }}>
          {/* Year Selector */}
          <FormControl
            variant="outlined"
            style={{ marginRight: "16px" }}
            size="small"
          >
            <TextField
              className="textField-root"
              size="small"
              select
              label="Year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              style={{ width: "100px" }}
            >
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          {/* Toggle Buttons */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setView(view === "count" ? "revenue" : "count")}
            style={{
              backgroundColor: "#3498DB",
              color: "#fff",
              textTransform: "none",
            }}
          >
            {view === "count" ? "Revenue" : "Count"}
          </Button>
        </div>
      </div>

      {/* Bar Chart */}
      <div style={{ width: "90%", marginTop: "16px" }}>
        <Box height={330}>
          <ResponsiveBar
            data={chartData}
            keys={[view]} // Dynamically choose either 'count' or 'revenue'
            indexBy="month"
            margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
            padding={0.2}
            colors={view === "count" ? ["#3498DB"] : ["#FF6F61"]}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Month",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: view === "count" ? "Count" : "Revenue",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            role="application"
            ariaLabel={`Monthly ${view} bar chart`}
            isInteractive={true}
          />
        </Box>
      </div>
    </div>
  );
}
