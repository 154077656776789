// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import "./NextPage.css";
// import {
//   Typography,
//   Grid,
//   TextField,
//   Button,
//   FormControlLabel,
//   Checkbox,
//   CircularProgress,
//   Box,
//   Paper,
//   MenuItem,
// } from "@mui/material";
// import Swal from "sweetalert2";
// import indianStatesAndDistricts from "../../Components/indianStatesAndDistricts";

// const NextPage = () => {
//   const ACTIVATION_FEE = 3000;
//   const planId = sessionStorage.getItem("planId");
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { instituteData } = location.state;

//   const [formData, setFormData] = useState({});
//   const [errors, setErrors] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [addonDetails, setAddonDetails] = useState([]);

//   const [selectedState, setSelectedState] = useState("");
//   const [selectedDistrict, setSelectedDistrict] = useState("");
//   const [districts, setDistricts] = useState([]);

//   useEffect(() => {
//     setFormData(instituteData);
//     fetchAddonDetails(instituteData.selectedAddOnSystems || []);
//   }, [instituteData]);

//   const handleStateChange = (event) => {
//     const state = event.target.value;
//     setSelectedState(state);
//     setDistricts(indianStatesAndDistricts[state] || []);
//     setSelectedDistrict(""); // Reset district when state changes
//     setFormData((prev) => ({ ...prev, state, district: "" }));
//   };

//   const handleDistrictChange = (event) => {
//     const district = event.target.value;
//     setSelectedDistrict(district);
//     setFormData((prev) => ({ ...prev, district }));
//   };

//   const fetchAddonDetails = async (selectedAddons) => {
//     const details = await Promise.all(
//       selectedAddons.map(async (addonId) => {
//         const response = await fetch(
//           `https://pjsofttech.in:20443/AddOnSystemById/${addonId}`
//         );
//         if (!response.ok) throw new Error("Network response was not ok");
//         return await response.json();
//       })
//     );
//     setAddonDetails(details);
//   };

//   const handleChange = (key) => (event) => {
//     setFormData((prev) => ({ ...prev, [key]: event.target.value }));
//     setErrors((prev) => ({ ...prev, [key]: null }));
//   };

//   const handleBooleanChange = (key) => (event) => {
//     setFormData((prev) => ({ ...prev, [key]: event.target.checked }));
//   };

//   const validateFields = () => {
//     const requiredFields = [
//       "emailaddress",
//       "phonenumber",
//       "address",
//       "landmark",
//       "city",
//       "state",
//       "country",
//       "district",
//       "pincode",
//       "password",
//       "confirmpassword",
//       "aadhar",
//       "pancard",
//       "institutename",
//       "ownerName",
//     ];

//     const newErrors = requiredFields.reduce((acc, field) => {
//       if (!formData[field]) {
//         acc[field] = "This field is required.";
//       }
//       return acc;
//     }, {});

//     // Check if passwords match
//     if (formData.password !== formData.confirmpassword) {
//       newErrors.confirmpassword = "Passwords do not match.";
//     }

//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = async () => {
//     if (!validateFields()) return;

//     const dataToSend = {
//       ...formData,
//       planId,
//       addOnSystems: addonDetails.map((addon) => ({
//         id: addon.id, // Replace 'id' with the actual identifier you are using for your add-on systems
//       })),
//       totalAmount: shouldApplyActivationFee()
//         ? calculateFinalAmount()
//         : formData.totalAmount, // Set totalAmount based on condition
//     };

//     setLoading(true);

//     try {
//       const response = await fetch("https://pjsofttech.in:20443/saveinstitude", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(dataToSend),
//       });

//       if (!response.ok) throw new Error("Network response was not ok");

//       await response.json();
//       Swal.fire({
//         title: "Success!",
//         text: "Data saved successfully!",
//         icon: "success",
//         confirmButtonText: "OK",
//       });
//     } catch (error) {
//       console.error("Error:", error);
//       const errorMessage = error.message;

//       // Check if the error message contains "Duplicate entry"
//       if (errorMessage.includes("Duplicate entry")) {
//         await Swal.fire({
//           title: "Error!",
//           text: "Institute with this email ID is already present. Use a different Email ID.",
//           icon: "error",
//           confirmButtonText: "OK",
//         });
//       } else {
//         await Swal.fire({
//           title: "Error!",
//           text: "Failed to save your institute. Please try again.",
//           icon: "error",
//           confirmButtonText: "OK",
//         });
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleBack = () => {
//     navigate(`/superLayout/${planId}`, { state: { formData } });
//   };

//   const renderTextField = (field, disabled = false) => (
//     <Grid item xs={6} sm={3} key={field}>
//       <TextField
//         fullWidth
//         size="small"
//         label={field.replace(/([A-Z])/g, " $1").trim()}
//         value={formData[field] || ""}
//         onChange={handleChange(field)}
//         error={!!errors[field]}
//         helperText={errors[field]}
//         disabled={disabled}
//         type={field.includes("password") ? "password" : "text"}
//         InputProps={{
//           sx: {
//             backgroundColor: disabled ? "rgba(224, 224, 224, 0.6)" : "white",
//           },
//         }}
//       />
//     </Grid>
//   );

//   const renderSelectedAddons = () => {
//     return (
//       <Grid container spacing={1}>
//         {addonDetails.map(({ addOnSystemName, addOnSystemPrice }) => (
//           <Grid item xs={6} key={addOnSystemName}>
//             <Typography variant="body1">
//               {addOnSystemName} (₹{addOnSystemPrice})
//             </Typography>
//           </Grid>
//         ))}
//       </Grid>
//     );
//   };

//   // Function to determine if activation fee should be applied
//   const shouldApplyActivationFee = () => {
//     const sectionIncludesInstituteOrAcademy = /Institute|Academy/i.test(
//       formData.section || ""
//     );
//     const isStudentRangeBelow200 = parseInt(formData.studentRange) < 200;
//     return sectionIncludesInstituteOrAcademy && isStudentRangeBelow200;
//   };

//   // Calculate total amount including activation fee if applicable
//   const calculateFinalAmount = () => {
//     const totalAmount = parseFloat(formData.totalAmount) || 0;
//     return shouldApplyActivationFee()
//       ? totalAmount + ACTIVATION_FEE
//       : totalAmount;
//   };

//   return (
//     <div>
//       <Paper
//         mt={2}
//         elevation={1}
//         sx={{
//           padding: "2%",
//           backgroundColor: "rgba(224, 224, 224, 0.6)",
//           boxShadow: "0px 4px 10px rgba(105, 104, 104, 0.6)",
//         }}
//       >
//         <Grid
//           container
//           spacing={1}
//           textAlign={"center"}
//           className="selected-section-plan-details"
//         >
//           <Grid item xs={12}>
//             <Paper
//               elevation={1}
//               sx={{
//                 padding: "2%",
//                 backgroundColor: "rgba(224, 224, 224, 0.6)",
//                 boxShadow: "0px 4px 10px rgba(105, 104, 104, 0.6)",
//                 marginTop: "-2%",
//               }}
//             >
//               <Typography
//             variant="h6"
//             padding={"10px"}
//             color="#00649F"
//             fontWeight={"bold"}
//             textAlign={'center'}
//           >
//             STEP 1] - Payment Details
//           </Typography>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <Typography>Section</Typography>
//                 <Typography>{formData.section} </Typography>
//               </div>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <Typography>Plan</Typography>
//                 <Typography>{formData.plan}</Typography>
//               </div>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <Typography>Entry Range</Typography>
//                 <Typography>{formData.studentRange}</Typography>
//               </div>

//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <Typography>Validity (In Months)</Typography>
//                 <Typography>{formData.validity}</Typography>
//               </div>
//               {/* Activation Fee Message */}
//               {shouldApplyActivationFee() && (
//                 <Typography color="error" textAlign="center">
//                   (Activation fees of ₹{ACTIVATION_FEE} is applied)
//                 </Typography>
//               )}
//               <div className="add-on-system-box">
//                 <Typography
//                   variant="h7"
//                   mb={1}
//                   color="#00649F"
//                   textAlign={"center"}
//                   fontWeight={"bold"}
//                 >
//                   Selected Add Ons Systems
//                 </Typography>
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "flex-start",
//                   }}
//                 >
//                   {addonDetails.length > 0 ? (
//                     addonDetails.map(
//                       ({ addOnSystemName, addOnSystemPrice }) => (
//                         <div
//                           key={addOnSystemName}
//                           style={{
//                             display: "flex",
//                             justifyContent: "space-between",
//                             width: "100%",
//                           }}
//                         >
//                           <Typography>{addOnSystemName}</Typography>
//                           <Typography>₹{addOnSystemPrice} / Month</Typography>
//                         </div>
//                       )
//                     )
//                   ) : (
//                     <Typography style={{ textAlign: "center" }}>
//                       No Optional Addons have been selected
//                     </Typography>
//                   )}
//                 </div>
//               </div>
//               <Typography
//                 variant="h7"
//                 mb={1}
//                 color="#00649F"
//                 textAlign={"center"}
//                 fontWeight={"bold"}
//               >
//                 Price Including GST (18%)
//               </Typography>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <Typography>Total Price Excluding GST</Typography>
//                 <Typography>₹{formData.amount}</Typography>
//               </div>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <Typography>GST (18%)</Typography>
//                 <Typography>₹{formData.gstAmount}</Typography>
//               </div>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <Typography>ToTal Payable Amount Including GST</Typography>
//                 <Typography>{formData.totalAmount}</Typography>
//               </div>

//               {shouldApplyActivationFee() && (
//                 <div
//                   style={{ display: "flex", justifyContent: "space-between" }}
//                 >
//                   <Typography>
//                     Total Payable Amount Including GST & Activation Fees
//                   </Typography>
//                   <Typography>₹{calculateFinalAmount()} </Typography>
//                 </div>
//               )}
//             </Paper>
//           </Grid>
//         </Grid>

//         <Grid item>
//           <Paper
//             elevation={1}
//             sx={{
//               padding: "2%",
//               backgroundColor: "rgba(224, 224, 224, 0.6)",
//               boxShadow: "0px 4px 10px rgba(105, 104, 104, 0.6)",
//               marginTop: "2.5%",
//             }}
//           >
//             <Typography variant="h6" color="#00649F" mb={2} fontWeight={"bold"} textAlign={'center'}>
//               STEP 2] - Personal Details
//             </Typography>
//             <Grid container spacing={2} className="textField-root">
//               {[
//                 "emailaddress",
//                 "phonenumber",
//                 "mobilenumber",
//                 "password",
//                 "confirmpassword",
//                 "address",
//                 "landmark",
//                 "city",
//                 "pincode",
//                 "country",
//               ].map((field) => renderTextField(field))}

//               <Grid item xs={6} sm={3}>
//                 <TextField
//                   fullWidth
//                   select
//                   size="small"
//                   label="State"
//                   value={selectedState}
//                   onChange={handleStateChange}
//                   error={!!errors.state}
//                   helperText={errors.state}
//                 >
//                   {Object.keys(indianStatesAndDistricts).map((state) => (
//                     <MenuItem key={state} value={state}>
//                       {state}
//                     </MenuItem>
//                   ))}
//                 </TextField>
//               </Grid>

//               {/* District Dropdown */}
//               <Grid item xs={6} sm={3}>
//                 <TextField
//                   fullWidth
//                   select
//                   size="small"
//                   label="District"
//                   value={selectedDistrict}
//                   onChange={handleDistrictChange}
//                   disabled={!selectedState} // Disable if no state is selected
//                   error={!!errors.district}
//                   helperText={errors.district}
//                 >
//                   {districts.map((district) => (
//                     <MenuItem key={district} value={district}>
//                       {district}
//                     </MenuItem>
//                   ))}
//                 </TextField>
//               </Grid>
//             </Grid>
//             {/* State Dropdown */}
//           </Paper>
//         </Grid>

//         <Grid item mt={1}>
//           <Typography
//             variant="h6"
//             padding={"10px"}
//             color="#00649F"
//             fontWeight={"bold"}
//             textAlign={'center'}
//           >
//             STEP 3] - Necessary Professional Details
//           </Typography>
//           <Grid container spacing={1} className="textField-root">
//             {[
//               "institutename",
//               "ownerName",
//               "websitename",
//               "registrationnumber",
//               "aadhar",
//               "pancard",
//               "loa",
//               "mou",
//               "publicid",
//               "gstNo",
//               "paymentMode",
//               "transactionId",
//             ].map((field) => renderTextField(field))}
//           </Grid>
//         </Grid>

//         <div style={{ textAlign: "center" }}>
//           <button
//             className="nextPage-button"
//             variant="outlined"
//             color="secondary"
//             style={{ marginTop: "20px" }}
//             onClick={handleBack}
//           >
//             Back
//           </button>
//           <button
//             className="nextPage-button"
//             variant="contained"
//             color="primary"
//             onClick={handleSubmit}
//             disabled={loading}
//             style={{ marginLeft: "10px" }}
//           >
//             {loading ? <CircularProgress size={24} /> : "Submit"}
//           </button>
//         </div>
//       </Paper>
//     </div>
//   );
// };

// export default NextPage;
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./NextPage.css";
import {
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Box,
  Paper,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import indianStatesAndDistricts from "../indianStatesAndDistricts";
import {
  fetchAddOns,
  fetchAddOnDetails,
  // other functions can be imported as needed
} from "./CreateINSTApi"; // Adjust the path as necessary

const NextPage = () => {
  const ACTIVATION_FEE = 3000;
  const planId = sessionStorage.getItem("planId");
  const location = useLocation();
  const navigate = useNavigate();
  const { instituteData } = location.state;

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [addonDetails, setAddonDetails] = useState([]);

  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    setFormData(instituteData);
    fetchAddonDetails(instituteData.selectedAddOnSystems || []);
  }, [instituteData]);

  const handleStateChange = (event) => {
    const state = event.target.value;
    setSelectedState(state);
    setDistricts(indianStatesAndDistricts[state] || []);
    setSelectedDistrict(""); // Reset district when state changes
    setFormData((prev) => ({ ...prev, state, district: "" }));
  };

  const handleDistrictChange = (event) => {
    const district = event.target.value;
    setSelectedDistrict(district);
    setFormData((prev) => ({ ...prev, district }));
  };

  const fetchAddonDetails = async (selectedAddons) => {
    try {
      const details = await Promise.all(
        selectedAddons.map(async (addonId) => {
          const response = await fetchAddOnDetails(addonId);
          return response; // Use the API service function
        })
      );
      setAddonDetails(details);
    } catch (error) {
      console.error("Error fetching add-on details:", error);
    }
  };

  const handleChange = (key) => (event) => {
    setFormData((prev) => ({ ...prev, [key]: event.target.value }));
    setErrors((prev) => ({ ...prev, [key]: null }));
  };

  const handleBooleanChange = (key) => (event) => {
    setFormData((prev) => ({ ...prev, [key]: event.target.checked }));
  };

  const validateFields = () => {
    const requiredFields = [
      "emailaddress",
      "phonenumber",
      "address",
      "landmark",
      "city",
      "state",
      "country",
      "district",
      "pincode",
      "password",
      "confirmpassword",
      "aadhar",
      "pancard",
      "institutename",
      "ownerName",
    ];

    const newErrors = requiredFields.reduce((acc, field) => {
      if (!formData[field]) {
        acc[field] = "This field is required.";
      }
      return acc;
    }, {});

    if (formData.password !== formData.confirmpassword) {
      newErrors.confirmpassword = "Passwords do not match.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields) return;

    const dataToSend = {
      ...formData,
      planId,
      addOnSystems: addonDetails.map((addon) => ({
        id: addon.id,
      })),
      totalAmount: shouldApplyActivationFee()
        ? calculateFinalAmount()
        : formData.totalAmount,
    };

    setLoading(true);

    try {
      const response = await fetch("https://pjsofttech.in:20443/saveinstitude", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) throw new Error("Network response was not ok");

      await response.json();
      Swal.fire({
        title: "Success!",
        text: "Institute Create Successfully!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = error.message;

      if (errorMessage.includes("Duplicate entry")) {
        await Swal.fire({
          title: "Error!",
          text: "Institute with this email ID is already present. Use a different Email ID.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        await Swal.fire({
          title: "Error!",
          text: "Failed to save your institute. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(`/${planId}`, { state: { formData } });
  };

  const renderTextField = (field, disabled = false) => (
    <Grid item xs={6} sm={3} key={field}>
      <TextField
        fullWidth
        size="small"
        label={field.replace(/([A-Z])/g, " $1").trim()}
        value={formData[field] || ""}
        onChange={handleChange(field)}
        error={!!errors[field]}
        helperText={errors[field]}
        disabled={disabled}
        type={field.includes("password") ? "password" : "text"}
      />
    </Grid>
  );

  const renderSelectedAddons = () => {
    return (
      <Grid container spacing={1}>
        {addonDetails.map(({ addOnSystemName, addOnSystemPrice }) => (
          <Grid item xs={6} key={addOnSystemName}>
            <Typography variant="body1">
              {addOnSystemName} (₹{addOnSystemPrice})
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  const shouldApplyActivationFee = () => {
    const sectionIncludesInstituteOrAcademy = /Institute|Academy/i.test(
      formData.section || ""
    );
    const isStudentRangeBelow200 = parseInt(formData.studentRange) < 200;
    return sectionIncludesInstituteOrAcademy && isStudentRangeBelow200;
  };

  const calculateFinalAmount = () => {
    const totalAmount = parseFloat(formData.totalAmount) || 0;
    return shouldApplyActivationFee()
      ? totalAmount + ACTIVATION_FEE
      : totalAmount;
  };

  return (
    <div style={{ padding: "2%" }}>
      <Grid
        container
        spacing={1}
        textAlign={"center"}
        className="selected-section-plan-details"
        mt={2}
      >
        <Grid item xs={12}>
          <Paper
            elevation={1}
            sx={{
              padding: "2%",
              backgroundColor: "rgba(224, 224, 224, 0.6)",
              boxShadow: "0px 4px 10px rgba(105, 104, 104, 0.6)",
              marginTop: "-2%",
            }}
          >
            <Typography
              variant="h6"
              padding={"10px"}
              color="#00649F"
              fontWeight={"bold"}
              textAlign={"center"}
            >
              STEP 1] - Payment Details
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Section</Typography>
              <Typography>{formData.section} </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Plan</Typography>
              <Typography>{formData.plan}</Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Entry Range</Typography>
              <Typography>{formData.studentRange}</Typography>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Validity (In Months)</Typography>
              <Typography>{formData.validity}</Typography>
            </div>
            {shouldApplyActivationFee() && (
              <Typography color="error" textAlign="center">
                (Activation fees of ₹{ACTIVATION_FEE} is applied)
              </Typography>
            )}
            <div className="add-on-system-box">
              <Typography
                variant="h7"
                mb={1}
                color="#00649F"
                textAlign={"center"}
                fontWeight={"bold"}
              >
                Selected Add Ons Systems
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {addonDetails.length > 0 ? (
                  addonDetails.map(({ addOnSystemName, addOnSystemPrice }) => (
                    <div
                      key={addOnSystemName}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography>{addOnSystemName}</Typography>
                      <Typography>₹{addOnSystemPrice} / Month</Typography>
                    </div>
                  ))
                ) : (
                  <Typography style={{ textAlign: "center" }}>
                    No Optional Addons have been selected
                  </Typography>
                )}
              </div>
            </div>
            <Typography
              variant="h7"
              mb={1}
              color="#00649F"
              textAlign={"center"}
              fontWeight={"bold"}
            >
              Price Including GST (18%)
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Total Price Excluding GST</Typography>
              <Typography>₹{formData.amount}</Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>GST (18%)</Typography>
              <Typography>₹{formData.gstAmount}</Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>ToTal Payable Amount Including GST</Typography>
              <Typography>{formData.totalAmount}</Typography>
            </div>

            {shouldApplyActivationFee() && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>
                  Total Payable Amount Including GST & Activation Fees
                </Typography>
                <Typography>₹{calculateFinalAmount()} </Typography>
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Grid item>
        <Paper
          elevation={1}
          sx={{
            padding: "2%",
            backgroundColor: "rgba(224, 224, 224, 0.6)",
            boxShadow: "0px 4px 10px rgba(105, 104, 104, 0.6)",
            marginTop: "2.5%",
          }}
        >
          <Typography
            variant="h6"
            color="#00649F"
            mb={2}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            STEP 2] - Personal Details
          </Typography>
          <Grid container spacing={2} className="textField-root">
            {[
              "emailaddress",
              "phonenumber",
              "password",
              "confirmpassword",
              "address",
              "landmark",
              "city",
              "pincode",
              "country",
              "institutename",
              "ownerName",
            ].map((field) => renderTextField(field))}

            <Grid item xs={6} sm={3}>
              <TextField
                fullWidth
                select
                size="small"
                label="State"
                value={selectedState}
                onChange={handleStateChange}
                error={!!errors.state}
                helperText={errors.state}
              >
                {Object.keys(indianStatesAndDistricts).map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6} sm={3}>
              <TextField
                fullWidth
                select
                size="small"
                label="District"
                value={selectedDistrict}
                onChange={handleDistrictChange}
                disabled={!selectedState}
                error={!!errors.district}
                helperText={errors.district}
              >
                {districts.map((district) => (
                  <MenuItem key={district} value={district}>
                    {district}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <div style={{ textAlign: "center" }}>
        <button
          className="nextPage-button"
          variant="outlined"
          color="secondary"
          style={{ marginTop: "20px" }}
          onClick={handleBack}
        >
          Back
        </button>
        <Button
          // className="nextPage-button"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          style={{ marginLeft: "10px" }}
        >
          {loading ? <CircularProgress size={24} /> : "Buy Plan"}
        </Button>
      </div>
    </div>
  );
};

export default NextPage;
